const DEBUG = false;
//const GAME_SERVER = "http://localhost:8080";
// const GAME_SERVER = "http://101.111.222.178:8080/";
const GAME_SERVER = DEBUG ? "http://localhost:8080"
                          : "https://boardgamejs-snjbaxnk2a-dt.a.run.app";
//const LOBBY_SERVER = "http://localhost:8080";
// const LOBBY_SERVER = "http://101.111.222.178:8080/";
const LOBBY_SERVER = DEBUG  ? "http://localhost:8080"
                            : "https://boardgamejs-snjbaxnk2a-dt.a.run.app";
const APP_SERVER = DEBUG ? "http://localhost:3000"
                         : "https://gxgame.sirkus.co.jp";

export { DEBUG, GAME_SERVER, LOBBY_SERVER, APP_SERVER };