import * as React from 'react';
import { Button, Drawer } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Financial } from '../data/Financial';
import { GXDrawer } from './GXDrawer';
/**
 * P/Lを表示する
 * @param {} param0 
 * @returns 
 */
function PL({...props}) {
    const { G, playerID, ctx, moves, matchData, playerName } = props;
    const player = G.players[playerID];
    // 財務データを取得
    var financials = player.financial.map((f) => {
        return new Financial(f);
    });
    // 配列の最後は現在進行形のターンなので削除する
    financials.pop();
    
    const gyousx = {

    }
    const cellsx = {
        textAlign:"right",
    }

    
    return (
    <div>
    {player.hasan !=0 && (
        <div class="hanko-style">破 産</div>
    )}
    <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
              <TableCell align="right"></TableCell>

            {/*各ターン数表示(配列のインデックス)*/
            financials.map((f,index) => (
                <TableCell align="right">{index+1}期</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow
                className="gyou">
                <TableCell component="th" scope="row">販売価格</TableCell>
                {financials.map((f,index) => (
                    <TableCell sx={cellsx}>{f.price}</TableCell>
                ))}
            </TableRow>
            <TableRow
                className="gyou">
                <TableCell component="th" scope="row">販売個数</TableCell>
                {financials.map((f,index) => (
                    <TableCell sx={cellsx}>{f.sell}</TableCell>
                ))}
            </TableRow>
            <TableRow
                className="gyou">
                <TableCell component="th" scope="row">値引き販売価格</TableCell>
                {financials.map((f,index) => (
                    <TableCell sx={cellsx}>{f.nebiki_price}</TableCell>
                ))}
            </TableRow>
            <TableRow
                className="gyou">
                <TableCell component="th" scope="row">値引き販売個数</TableCell>
                {financials.map((f,index) => (
                    <TableCell sx={cellsx}>{f.nebiki_num}</TableCell>
                ))}
            </TableRow>
            <TableRow
                className="gyou">
                <TableCell component="th" scope="row">排出権取引</TableCell>
                {financials.map((f,index) => (
                    <TableCell sx={cellsx}>{f.jcredit_sell}</TableCell>
                ))}
            </TableRow>
            <TableRow
                className={`color_3s total`}>
                <TableCell component="th" scope="row">売上</TableCell>
                {financials.map((f,index) => (
                    <TableCell sx={cellsx}>{f.revenue}</TableCell>
                ))}
            </TableRow>
            <TableRow
                className="gyou">
                <TableCell component="th" scope="row">燃料価格</TableCell>
                {financials.map((f,index) => (
                    <TableCell sx={cellsx}>{f.fuel}</TableCell>
                ))}
            </TableRow>
            <TableRow
                className="gyou">
                <TableCell component="th" scope="row">購入燃料数</TableCell>
                {financials.map((f,index) => (
                    <TableCell sx={cellsx}>{f.fuelnum}</TableCell>
                ))}
            </TableRow>
            <TableRow
                className={`color_2 subtotal`}>
                <TableCell component="th" scope="row">燃料費</TableCell>
                {financials.map((f,index) => (
                    <TableCell sx={cellsx}>{f.fuelnum*f.fuel}</TableCell>
                ))}
            </TableRow>
            <TableRow
                className={`color_2 subtotal`}>
                <TableCell component="th" scope="row">排出権取引</TableCell>
                {financials.map((f,index) => (
                    <TableCell sx={cellsx}>{f.jcredit_buy}</TableCell>
                ))}
            </TableRow>
            <TableRow
                className="gyou">
                <TableCell component="th" scope="row">原材料価格</TableCell>
                {financials.map((f,index) => (
                    <TableCell sx={cellsx}>{f.material}</TableCell>
                ))}
            </TableRow>
            <TableRow
                className="gyou">
                <TableCell component="th" scope="row">購入材料数</TableCell>
                {financials.map((f,index) => (
                    <TableCell sx={cellsx}>{f.materialnum}</TableCell>
                ))}
            </TableRow>
            <TableRow
                className={`color_2 subtotal`}>
                <TableCell component="th" scope="row">材料費</TableCell>
                {financials.map((f,index) => (
                    <TableCell sx={cellsx}>{f.material*f.materialnum}</TableCell>
                ))}
            </TableRow>
            <TableRow
                className={`color_2 subtotal`}>
                <TableCell component="th" scope="row">減価償却費</TableCell>
                {financials.map((f,index) => (
                    <TableCell sx={cellsx}>{f.depreciation}</TableCell>
                ))}
            </TableRow>
            <TableRow
                className={`color_2s total`}>
                <TableCell component="th" scope="row">売上原価</TableCell>
                {financials.map((f,index) => (
                    <TableCell sx={cellsx}>{f.costOfGoodsSold}</TableCell>
                ))}
            </TableRow>
            <TableRow
                className={`color_1s total`}>
                <TableCell component="th" scope="row">売上総利益</TableCell>
                {financials.map((f,index) => (
                    <TableCell sx={cellsx}>{f.grossProfit}</TableCell>
                ))}
            </TableRow>
            <TableRow
                className={`color_2 subtotal`}>
                <TableCell component="th" scope="row">研究費</TableCell>
                {financials.map((f,index) => (
                    <TableCell sx={cellsx}>{f.research}</TableCell>
                ))}
            </TableRow>
            <TableRow
                className={`color_2 subtotal`}>
                <TableCell component="th" scope="row">広告宣伝費</TableCell>
                {financials.map((f,index) => (
                    <TableCell sx={cellsx}>{f.ad}</TableCell>
                ))}
            </TableRow>
            <TableRow
                className={`color_2s total`}>
                <TableCell component="th" scope="row">販売費及び一般管理費</TableCell>
                {financials.map((f,index) => (
                    <TableCell sx={cellsx}>{f.sellingAndGeneralAdministrativeExpenses}</TableCell>
                ))}
            </TableRow>
            <TableRow
                className={`color_1s total`}>
                <TableCell component="th" scope="row">営業損益</TableCell>
                {financials.map((f,index) => (
                    <TableCell sx={cellsx}>{f.operatingIncome}</TableCell>
                ))}
            </TableRow>
            <TableRow
                className={`color_2 subtotal`}>
                <TableCell component="th" scope="row">支払い利息</TableCell>
                {financials.map((f,index) => (
                    <TableCell sx={cellsx}>{f.interest}</TableCell>
                ))}
            </TableRow>
            <TableRow
                className={`color_2 subtotal`}>
                <TableCell component="th" scope="row">寄付金</TableCell>
                {financials.map((f,index) => (
                    <TableCell sx={cellsx}>{f.donate}</TableCell>
                ))}
            </TableRow>
            <TableRow
                className={`color_2s total`}>
                <TableCell component="th" scope="row">営業外費用</TableCell>
                {financials.map((f,index) => (
                    <TableCell sx={cellsx}>{f.nonOperatingExpenses}</TableCell>
                ))}
            </TableRow>
            <TableRow
                className={`color_1s total`}>
                <TableCell component="th" scope="row">経常利益</TableCell>
                {financials.map((f,index) => (
                    <TableCell sx={cellsx}>{f.ordinaryIncome}</TableCell>
                ))}
            </TableRow>
            <TableRow
                className={`color_3 subtotal`}>
                <TableCell component="th" scope="row">補助金</TableCell>
                {financials.map((f,index) => (
                    <TableCell sx={cellsx}>{f.hojo}</TableCell>
                ))}
            </TableRow>
            <TableRow
                className={`color_3s total`}>
                <TableCell component="th" scope="row">特別利益</TableCell>
                {financials.map((f,index) => (
                    <TableCell sx={cellsx}>{f.specialProfit}</TableCell>
                ))}
            </TableRow>
            <TableRow
                className={`color_2 subtotal`}>
                <TableCell component="th" scope="row">設備除去損</TableCell>
                {financials.map((f,index) => (
                    <TableCell sx={cellsx}>{f.jokyo}</TableCell>
                ))}
            </TableRow>
            <TableRow
                className={`color_2s total`}>
                <TableCell component="th" scope="row">特別損失</TableCell>
                {financials.map((f,index) => (
                    <TableCell sx={cellsx}>{f.specialLoss}</TableCell>
                ))}
            </TableRow>
            <TableRow
                className={`color_1s total`}>
                <TableCell component="th" scope="row">当期純損益</TableCell>
                {financials.map((f,index) => (
                    <TableCell sx={cellsx}>{f.netIncome}</TableCell>
                ))}
            </TableRow>


        </TableBody>
        </Table>
    </TableContainer>
    </div>
    );
}

/**
 * Drawerとボタンを表示する
 */

function PLDrawer({...props}) {
    return (
      <GXDrawer
          {...props}
          key="pl"
          title="損益計算書"
          component={<PL {...props}/>}
      />
    );
}

export {PLDrawer, PL}