import { loadmasu } from './masu';
function createPlayer(playerID) {
  return {
    id: playerID,
    position: 0,
    money: 10000,
    step:"",
  }
}

const Sugoroku = {
  name: 'sugoroku',
  minPlayers: 1,
  maxPlayers: 4,
  setup: ({ctx, matchID, playerID, numPlayers,playerName}, setupData) => {
    console.log(matchID); //undefined
    console.log(playerID);//undefined
    console.log(numPlayers);//undefined
    console.log(playerName);//undefined
    console.log(setupData);//一人用の時入ってこない
    console.log(ctx);

    const players = Array(ctx.numPlayers).fill().map((_, i) => createPlayer(i));

    const squares = loadmasu();
    return { players, squares };
  },

  turn: {
    // Define turn order
    // order: {
    //   first: (G, ctx) => 0,
    //   next: (G, ctx) => (ctx.playOrderPos + 1) % ctx.numPlayers,
    // },

    onBegin: ({G, ctx,}) => {

      const player = G.players[ctx.currentPlayer];
      player.step="dice";
    },

    onEnd: ({G, ctx}) => {

      const player = G.players[ctx.currentPlayer];

      player.step="";
    }
  },

  moves: {
    /* サイコロ */
    roll: ({G, ctx,random}) => {
      const player = G.players[ctx.currentPlayer];
      const roll = random.D6();

      console.log(`roll ${player} ${roll}`);

      player.position += roll;
      player.step="event";
    },
    doEvent:({G, ctx})=>{
      const player = G.players[ctx.currentPlayer];
      const masu = G.squares[player.position];
      player.step="confirm";
      if(masu.eff_kind){  //TODO:Kindによって処理分ける
        player.money += masu.eff_val;
      }

      console.log(masu.eff_val);
      console.log(player.money);

      console.log(`doEvent ${player}`);
      console.log(JSON.stringify(masu));
    },
    confirm: ({G,ctx,events}) => {
      events.endTurn()
    }

  },

  endIf: ({G, ctx}) => {
    // Game ends when one player reaches the last square
    if (G.players.some(p => p.position >= 99)) {
      return { winner: G.players.find(p => p.position >= 99).id };
    }
  },
  ai: {
    enumerate: (G, ctx) => {
      let moves = [];
      const player = G.players[ctx.currentPlayer];
      if(player.step==="dice") moves.push({ move: 'roll' });
      if(player.step==="event") moves.push({ move: 'doEvent' });
      if(player.step==="confirm") moves.push({ move: 'confirm' });

      return moves;
    },
  },


};

//module.exports = { Sugoroku };
export {Sugoroku};