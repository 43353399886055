import Joyride from 'react-joyride';
import PersonIcon from '@mui/icons-material/Person';

const tutoStyle = {
    textAlign: "left"
}

const actionTutorial ={
    key: 'actionTutorial',
    steps: [
        {
            target: '#playerboard',
            title:"プレイヤーボード",
            content: (
            <div style={tutoStyle}>
                プレイヤーの資金状態や製品の魅力度、保有している設備を表しています。<br/>
            </div>
            ),
            disableBeacon: true, // これを設定するとビーコンが出ずにすぐにモーダルが出ます
            disableOverlayClose: true,
            placement: 'bottom',
            spotlightClicks: true,
        },
        {
            target: '#playerinfo',
            title:"プレイヤーボード",
            content: (
            <div style={tutoStyle}>
                他プレイヤーの情報はここから確認できます<br/>
            </div>
            ),
        },
        {
            target: '#fieldboard',
            title:"市場ボード",
            content: (
                <div style={tutoStyle}>
                    市場の状況が表示されています。<br/>
                    ・原材料およびエネルギーの価格<br/>
                    ・市場の需要状況<br/>
                    ・プレイヤーの製品魅力度<br/>
                    <br/>
                </div>
            ),
        },
        {
            target: '#demand',
            title:"需要と魅力度",
            content: (
                <div style={tutoStyle}>
                    需要は顧客がどのような製品に魅力を感じているかを表しています。<br/>
                    対応した魅力度が最も高いプレイヤーから製品の販売権を得ることができます<br/>
                    <img height="80px" src="/image/gx/sample_at1.png"/>
                </div>
            ),
        },
        {
            target: '#fieldboard',
            title:"需要と魅力度",
            content: (
                <div style={tutoStyle}>
                    価格を求める需要<PersonIcon sx={{color:'rgb(255, 99, 132)'}}/>はプレイヤーの価格の魅力度が高いプレイヤーの製品を購入します。<br/>
                    以下の場合は<strong>Player2</strong>の製品を購入します。<br/>
                    <img height="80px" src="/image/gx/sample_at2.png"/><br/>
                    最も魅力度の高いプレイヤーが売り終わった後に、その次に魅力度の高いプレイヤーの製品を購入します。<br/>
                    魅力度同率のプレイヤーがいたときは、その他のパラメータを参照して優先度が決定されます。<br/>
                    
                </div>
            ),
        },
        {
            target: '#fieldboard',
            title:"需要と魅力度",
            size: 5,
            content: (
                <div style={tutoStyle}>
                    品質を求める需要<PersonIcon sx={{color:'rgb(53, 162, 235)'}}/>はプレイヤーの<strong>価格の魅力度と品質魅力度の合計値</strong>が高いプレイヤーの製品を購入します。<br/>
                    以下の場合は<strong>Player1</strong>の製品を購入します。<br/>
                    <img height="80px" src="/image/gx/sample_at3.png"/><br/>
                    品質魅力度単体ではなく、価格魅力度との合計値であることに注意しましょう。<br/>
                </div>
            ),
        },
        {
            target: '#fieldboard',
            title:"需要と魅力度",
            size: 5,
            content: (
                <div style={tutoStyle}>
                    環境を求める需要<PersonIcon sx={{color:'rgb(75, 192, 192)'}}/>はプレイヤーの<strong>価格の魅力度と品質魅力度と環境魅力度の合計値</strong>が高いプレイヤーの製品を購入します。<br/>
                    以下の場合は<strong>Player1</strong>の製品を購入します。<br/>
                    <img  height="80px"  src="/image/gx/sample_at4.png"/><br/>
                    環境魅力度ではなく、各魅力度のとの合計値であることに注意しましょう。<br/>
                </div>
            ),
        },

        {
            target: '#actionboard',
            title:"アクション選択フェーズ",
            content: (
            <div style={tutoStyle}>
                アクションの選択を行います。<br/>
                アクションは1ターンにつき<strong>2つまで</strong>選択できます。<br/>
            </div>
            ),
            placement: 'top',
        },
        {
            target: '#actiontab',
            title:"アクションタブ",
            placement: 'top',
            content: (
            <div style={tutoStyle}>
                アクションには以下の種類があります。<br/>
                ・製品生産機械の購入<br/>
                ・原材料・エネルギー生産機械の購入<br/>
                ・品質・環境改善や広告活動<br/>
                ・その他　工場拡張、借り入れ            </div>
            ),
        },
        {
            target: '#通常機械',
            title:"製品生産機械",
            placement: 'top',
            content: (
                <div style={tutoStyle}>
                    購入したい製品生産機械をクリックして選択します。<br/>

                    製品生産機械の購入効果は以下です<br/>
                    ・工場のスペースを一つ使用します<br/>
                    ・製品製造ターンに製品を製造できるようになります。<br/>
                    ・イナズマのアイコンは使用燃料です、毎ターン記載数の燃料を使用します<br/>
                    ・箱のマークはその機械が生産可能な製品数です<br/>
                    ・購入時にGXの欄に記載のある分の環境値が増減します<br/>
                    製品製造時には製造分の原材料を購入する必要があります。また燃料に関してはターン終了時に支払いを行います。<br/>
                </div>
            ),
        },
        {
            target: '#purchase',
            title:"購入",
            placement: 'top',
            content: (
                <div style={tutoStyle}>
                    選択したカードは購入候補としてここに表示されます。<br/>
                    選択が終わったら「決定」を押して投資を確定させましょう<br/>
                    <br/>
                    生産機械以外にも様々な効果をもったアクションがありますので他のタブも見てみましょう！<br/>
                </div>
            )
        }
    ],
}

const genTutorial ={
    key: 'genTutorial',
    steps: [
        {
            target: '#ソーラーパネル',
            title:"エネルギー生産機械",
            placement: 'top',
            content: (
                <div style={tutoStyle}>
                    エネルギー生産を自社内で調達することにより、毎ターンの燃料費支払いが減ります。<br/>
                    記載の数値分を自社で調達が可能です。<br/>
                    工場のスペースをひとつ使用します。<br/>
                </div>
            ),
            disableBeacon: true, // これを設定するとビーコンが出ずにすぐにモーダルが出ます
            disableOverlayClose: true,
            spotlightClicks: true,
        },
        {
            target: '#リサイクル装置',
            title:"原材料生産機械",
            placement: 'top',
            content: (
                <div style={tutoStyle}>
                    原材料を自社内で調達することにより、製品生産時の原材料費支払いが減ります。<br/>
                    記載の数値分を自社で調達が可能です。<br/>
                    工場のスペースをひとつ使用します。<br/>
                </div>
            )
        }
    ],
}

const impTutorial ={
    key: 'impTutorial',
    steps: [
        {
            target: '#品質改善',
            title:"品質改善・環境改善",
            placement: 'top',
            content: (
                <div style={tutoStyle}>
                    品質改善や環境への投資を行うことで、品質値や環境値をあげることができます。<br/>
                    これらの活動は工場スペースを必要としません。<br/>
                </div>
            ),
            disableBeacon: true, // これを設定するとビーコンが出ずにすぐにモーダルが出ます
            disableOverlayClose: true,
            spotlightClicks: true,
        },
        {
            target: '#広告-品質市場',
            title:"広告活動",
            placement: 'top',
            content: (
                <div style={tutoStyle}>
                    対応する市場の需要数を増やすことができます。<br/>
                    市場全体の需要数なので他のプレイヤーも有利になってしまうことに注意しましょう<br/>
                </div>
            )
        },
    ]
}

const otherTutorial ={
    key: 'otherTutorial',
    steps: [
        {
            target: '#工場拡張',
            title:"工場拡張",
            placement: 'top',
            content: (
                <div style={tutoStyle}>
                    工場スペースを増やすことができます。<br/>
                    初期状態では工場のスペースは4つです。<br/>
                </div>
            ),
            disableBeacon: true, // これを設定するとビーコンが出ずにすぐにモーダルが出ます
            disableOverlayClose: true,
            spotlightClicks: true,
        },
        {
            target: '#借り入れ',
            title:"借り入れ",
            placement: 'top',
            content: (
                <div style={tutoStyle}>
                    300の資金を借りることができます。<br/>
                    ただし、借入金は毎ターンの75の返済に加え、利子25を支払う必要があります。<br/>
                    結果的に4ターンの間100の支払いが必要です。<br/>
                </div>
            )
        },
    ]
}
const prodnumTutorial ={
    key: 'prodnumTutorial',
    steps: [
        {
            target: '#prodnum',
            title:"製品生産",
            placement: 'top',
            content: (
                <div style={tutoStyle}>
                    製品の生産を行います。<br/>
                    生産数に応じて原材料の購入が必要です。<br/>
                    売れ残りが発生すると損失が発生します<strong>市場の需要数、他プレイヤーの生産可能数、自社の資金</strong>をよく見て生産数を決定しましょう
                </div>
            ),
            disableBeacon: true, // これを設定するとビーコンが出ずにすぐにモーダルが出ます
            disableOverlayClose: true,
            spotlightClicks: true,
        },
    ]
}
const priceTutorial ={
    key: 'priceTutorial',
    steps: [
        {
            target: '#price',
            title:"価格決定",
            placement: 'top',
            content: (
                <div style={tutoStyle}>
                    製品の販売価格を設定します<br/>
                    <strong>価格を上げると価格魅力度が低下し、価格を下げると価格魅力度が上がります</strong><br/>
                    他プレイヤーの魅力度をよく見て優位性を維持できる価格を設定しましょう<br/>
                </div>
            ),
            disableBeacon: true, // これを設定するとビーコンが出ずにすぐにモーダルが出ます
            disableOverlayClose: true,
            spotlightClicks: true,
        },
    ]
}

const tradeTutorial ={
    key: 'tradeTutorial',
    steps: [
        {
            target: '#dice',
            title:"需要変化",
            placement: 'top',
            content: (
                <div style={tutoStyle}>
                    毎ターン市場の需要数は変化します<br/>
                    ルーレットの結果によって需要数が変化しますが、各市場の変化度合いは異なります<br/>
                    価格を求める需要は変化が少なく、環境を求める需要は上昇しやすくなっています<br/>

                    需要数の変化が終わった後は販売の結果と売り上げの確認ができます<br/>
                </div>
            ),
            disableBeacon: true, // これを設定するとビーコンが出ずにすぐにモーダルが出ます
            disableOverlayClose: true,
            spotlightClicks: true,
        },
    ]
}

const financeTutorial ={
    key: 'financeTutorial',
    steps: [
        {
            target: '#finance',
            title:"財務諸表",
            placement: 'left',
            content: (
                <div style={tutoStyle}>
                    財務諸表は財政状況を確認することができます。<br/>
                    ・損益計算書<br/>
                    ・貸借対照表<br/>
                    ・キャッシュフロー計算書<br/>
                    ・財務分析<br/>
                </div>
            ),
            disableBeacon: true, // これを設定するとビーコンが出ずにすぐにモーダルが出ます
            disableOverlayClose: true,
            spotlightClicks: true,
        },
        {
            target: '#pl',
            title:"損益計算書",
            placement: 'left',
            content: (
                <div style={tutoStyle}>
                    損益計算書は<strong>売上や費用を引いた利益を記録するもの</strong>です<br/>
                    売上高は製品の販売数と価格によって決定されます<br/>
                    費用は原材料費、燃料費、広告費、借入金の利子、その他の費用です<br/>
                    単に売上の高低を見るのではなく、費用を引いた利益を確認し財務の構造を確認しましょう。
                </div>
            )
        },
        {
            target: '#bs',
            title:"貸借対照表",
            placement: 'left',
            content: (
                <div style={tutoStyle}>
                    貸借対照表は<strong>資産の状況を記録するもの</strong>です<br/>
                    資産は現金等の流動資産だけではなく、機械設備等の固定設備も加味して考えます<br/>
                    また、資産の構造は負債と純資産に分けられます。<br/>
                    貸借対照表は<strong>資産と負債の構造を表すもの</strong>です<br/>
                    単に手持ち金だけを見るのではなく、固定資産や負債との関係を確認しましょう。
                </div>
            )
        },
        {
            target: '#cf',
            title:"キャッシュフロー計算書",
            placement: 'left',
            content: (
                <div style={tutoStyle}>
                    キャッシュフロー計算書は<strong>現金の流れを記録するもの</strong>です<br/>
                    現金の流れは<strong>営業活動、投資活動、財務活動</strong>に分けられます<br/>
                    営業活動は<strong>売上高、原材料費、燃料費、広告費、借入金の利子、その他の費用</strong>です<br/>
                    投資活動は<strong>設備の購入</strong>です<br/>
                    財務活動は<strong>借入による資金の増加や、返済による資金の低下</strong>です<br/>
                    キャッシュフローは現金の状況を表しており、黒字の状態でも現金がない場合は倒産してしまいます。<br/>
                    単純に利益だけを見るのではなく現金の状況を確認し、倒産しないようにしましょう。
                </div>
            )
        },
        {
            target: '#anal',
            title:"財務分析",
            placement: 'left',
            content: (
                <div style={tutoStyle}>
                    財務分析は<strong>各財務の状況を指標で分析したもの</strong>です<br/>
                    財務諸表の内容を簡単に確認することで、財務の状況を把握しやすくなります<br/>
                    財務諸表の内容を確認し、財務の状況を把握しましょう。
                </div>
            )
        }

    ]
}


function Tutorial({ running, callback, tutorial}) {
    const onCallback = (data) => {
        console.log("onCallback Joyride", data, "tutorial:", tutorial.key, "status:")
        if(data.type=="tour:end"){
            localStorage.setItem( tutorial.key, 'finished')
        }
        if(callback)callback(data)
    }
    var status = localStorage.getItem(tutorial.key);
    if( status == 'finished'){
        return(<span/>)
    }else{
        return(
            <Joyride
                continuous
                hideCloseButton
                run={running} // ツアーを始める時にtrueにする
                scrollToFirstStep
                showProgress // 1/2などの数が表示される
                showSkipButton
                steps={tutorial.steps}
                spotlightClicks
                callback={onCallback}
                styles={{
                    options: {
                        zIndex: 10000,
                        width:"33vw",
                    },
                }}
                locale={ {
                    skip: <strong aria-label="skip">スキップ</strong>,
                    next: '次へ',
                    back: '戻る',
                    last: '終了',
                }}
            />
        )
    }

}


export {Tutorial, actionTutorial, genTutorial, impTutorial, otherTutorial, prodnumTutorial, priceTutorial,tradeTutorial,financeTutorial}