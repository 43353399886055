

//Game型がClassが許容されないため、単純なオブジェクトとして定義する
//Financialクラスは計算用として利用する
const FinancialData = {
    price : 0,      // 販売価格
    sell : 0,       // 販売個数
    nebiki_price:50,   // 値引き販売価格
    nebiki_num : 0,     // 値引き売上個数
    jcredit_sell : 0,    // 排出権取引
    fuel : 0,       // 燃料価格
    fuelnum : 0,    // 燃料個数
    jcredit_buy : 0,    // 排出権取引
    material : 0,   // 原材料価格
    materialnum : 0,// 原材料個数
    labor : 0,      // 人件費
    depreciation : 0,   // 減価償却費//calcDepreciationで計算した値を入れる
    research : 0,   // 研究費
    ad : 0,         // 広告宣伝費
    interest : 0,   // 支払利息
    donate : 0,     // 寄付金
    hojo : 0,       // 補助金
    jokyo : 0,      // 設備除去損

    //CF関連
    setsubi : 0,    // 設備投資
    kariire : 0,    // 借入額
    hensai : 0,     // 借入金返済

    //BS関連
    money : 0,      // 現金
    kotei : [],    // 固定資産{name, kounyuu:購入価額, genka:減価償却, genzai:現在価値}の配列
    fusai : [],   // 負債
}




class Financial {
    constructor({
        price = 0,      // 販売価格
        sell = 0,       // 販売個数
        nebiki_price=50,   // 値引き販売価格
        nebiki_num = 0,     // 値引き売上個数
        jcredit_sell = 0,    // 排出権取引
        fuel = 0,       // 燃料価格
        fuelnum = 0,    // 燃料個数
        jcredit_buy = 0,    // 排出権取引
        material = 0,   // 原材料価格
        materialnum = 0,// 原材料個数
        labor = 0,      // 人件費
        depreciation = 0,   // 減価償却費
        research = 0,   // 研究費
        ad = 0,         // 広告宣伝費
        interest = 0,   // 支払利息
        donate = 0,     // 寄付金
        hojo = 0,       // 特別利益
        jokyo = 0,      // 特別損失
        setsubi = 0,    // 今期購入設備投資
        kariire = 0,    // 借入額
        hensai = 0,     // 借入金返済

        //BS関連
        money = 0,      // 現金
        kotei = [],    // 固定資産 {name, kounyuu:購入価額, genka:減価償却, genzai:現在価値}の配列
        fusai = [],   // 負債 {kari:借入額, hensai:毎月返済額, zan:借入残高, interest:25} //400/300は扱いづらいので、利子は25固定とする。
    } = {}) {
        this.price = price;
        this.sell = sell;
        this.nebiki_price = nebiki_price;
        this.nebiki_num = nebiki_num;
        this.jcredit_sell = jcredit_sell;
        this.fuel = fuel;
        this.fuelnum = fuelnum;
        this.jcredit_buy = jcredit_buy;
        this.material = material;
        this.materialnum = materialnum;
        this.labor = labor;
        this.depreciation = depreciation;
        this.research = research;
        this.ad = ad;
        this.interest = interest;
        this.donate = donate;
        this.hojo = hojo;
        this.jokyo = jokyo;
        this.setsubi = setsubi;
        this.kariire = kariire;
        this.hensai = hensai;
        this.money = money;
        this.kotei = kotei;
        this.fusai = fusai;
    }

    // 売上高 = 販売価格 × 販売個数
    get revenue() {
        return this.price * this.sell + this.jcredit_sell + this.nebiki_price * this.nebiki_num;
    }

    // 売上原価 = (燃料価格 × 燃料個数) + (原材料価格 × 原材料個数) + 人件費 + 減価償却費
    get costOfGoodsSold() {
        return (this.fuel * this.fuelnum) + (this.material * this.materialnum) + this.labor + this.depreciation + this.jcredit_buy;
    }

    //売り上げ総利益
    get grossProfit() {
        return this.revenue - this.costOfGoodsSold;
    }

    // 販売費及び一般管理費 = 研究費 + 広告宣伝費
    get sellingAndGeneralAdministrativeExpenses() {
        return this.research + this.ad;
    }
    
    //営業利益
    get operatingIncome() {
        return this.grossProfit - this.sellingAndGeneralAdministrativeExpenses;
    }

    // 営業外費用 = 支払利息 + 寄付金
    get nonOperatingExpenses() {
        return this.interest + this.donate;
    }
    //　経常利益
    get ordinaryIncome() {
        return this.operatingIncome - this.nonOperatingExpenses;
    }
    //　特別利益
    get specialProfit() {
        return this.hojo;
    }
    // 特別損失
    get specialLoss() {
        return this.jokyo;
    }

    //当期純利益
    get netIncome() {
        return this.ordinaryIncome + this.specialProfit - this.specialLoss;
    }

    /* BS用 */

    // 流動資産合計
    get currentAssets() {
        return this.money;
    }

    // 機械設備合計
    get fixedAssets() {
        var sum = 0;
        for(const k of this.kotei){
            sum += k.kounyuu;
        }
        return sum;
    }
    //減価償却累計(購入-現在価値)
    get accumulatedDepreciation() {
        var sum = 0;
        for(const k of this.kotei){
            sum += k.kounyuu - k.genzai;
        }
        return sum;
    }
    // 固定資産純額
    get netFixedAssets() {
        return this.fixedAssets - this.accumulatedDepreciation;
    }
    // 総資産
    get totalAssets() {
        return this.currentAssets + this.netFixedAssets;
    }
    // 負債合計
    get totalLiabilities() {
        var sum = 0;
        for(const f of this.fusai){
            sum += f.zan;
        }

        return sum;
    }
    // 純資産
    get netAssets() {
        return this.totalAssets - this.totalLiabilities;
    }

    /* CF用 */
    //営業CF
    get operatingCF() {
        return this.netIncome + this.depreciation;
    }
    //投資CF
    get investmentCF() {
        return this.setsubi;
    }
    //財務CF
    get financialCF() {
        return this.kariire - this.hensai;
    }
    //フリーキャッシュフロー
    get freeCF() {
        return this.operatingCF - this.investmentCF;
    }
    //CF
    get CF() {
        return this.operatingCF - this.investmentCF + this.financialCF;
    }

    /*経営指標 */
    //自己資本比率
    get equityRatio() {
        return this.netAssets / this.totalAssets;
    }
    //売上高総利益率
    get grossProfitRatio() {
        if(this.revenue == 0)return 0;
        return this.grossProfit / this.revenue;
    }
    //営業利益率
    get operatingProfitRatio() {
        if(this.revenue == 0)return 0;
        return this.operatingIncome / this.revenue;
    }
    //経常利益率
    get ordinaryProfitRatio() {
        if(this.revenue == 0)return 0;
        return this.ordinaryIncome / this.revenue;
    }
    //変動費
    get variableCost() {
        return (this.material * this.materialnum)+this.jcredit_buy;
    }
    //変動比率
    get variableCostRatio() {
        if(this.revenue == 0)return 0;
        return this.variableCost / this.revenue;
    }
    //固定費
    get fixedCost() {
        return this.research + this.ad + this.depreciation + (this.fuel * this.fuelnum);
    }
    //損益分岐点
    get breakEvenPoint() {
        return this.fixedCost / (1-this.variableCostRatio);
    }
    //インタレストカバッレジレシオ
    get interestCoverageRatio() {
        if(this.interest == 0)return 0;

        return this.operatingIncome / this.interest;
    }
    //固定比率
    get fixedCostRatio() {
        return this.netFixedAssets / this.netAssets;
    }
    //総資産回転率
    get totalAssetTurnover() {
        return this.revenue / this.totalAssets;
    }
    //固定資産回転率
    get fixedAssetTurnover() {
        if(this.netFixedAssets == 0)return 0;
        return this.revenue / this.netFixedAssets;
    }

}

/**
 *  Finalcialオブジェクトの配列と現在ターン数を渡し、koteiの購入価額から内容を計算する
 *  今期の減価償却費を計算して返すとともに、koteiのgenzaiを更新する
 *  定額法5年償却、配列の先頭から順に償却し、配列のindexを「購入した期-1」とみなす
 */
function calcDepreciation(financialList, turn){
    turn = turn - 1;//配列のindexと合わせるため

    var depreciation = 0;
    //まず今期分の減価償却を行う
    for(const k of financialList[turn].kotei){
        financialList[turn].setsubi += k.kounyuu;
        k.genzai -= k.genka;
        depreciation += k.genka;
    }
    //前期の設備を今期分の配列に追加し、減価償却を行う。現在価額が0になったものは追加しない
//    for(var i = 0; i < turn; i++){
    if(turn > 0){
//        for(const k of financialList[i].kotei){
        for(const k of financialList[turn-1].kotei){
            if(k.genzai > 0){
                const prek = { ...k }; //shalow copy
                prek.genzai -= prek.genka;
                financialList[turn].kotei.push(prek);
                depreciation += prek.genka;
            }
        }
    }
//    }
    //今期分の減価償却トータル値を追加
    financialList[turn].depreciation = depreciation;
    
    return depreciation;
}

/** 
 * Finalcialオブジェクトの配列から、有効手形を計算し、利子を計算して返す
 * 利子は25円固定とする
 */
function calcInterest(financialList, turn,update=false){
    turn = turn - 1;//配列のindexと合わせるため
    var fusai=[];
    if(update){
        fusai = financialList[turn].fusai
    }else{
        //アップデートしない場合はシャローコピー
        fusai = financialList[turn].fusai.map((f)=>({...f}));
    }


    var interest = 0;
    var hensai = 0;
    //まず今期借りた分の利子を計算する
    for(const f of fusai){
        f.zan -= f.hensai;
        interest += f.interest;
        hensai += f.hensai;
    }

    //前期の負債を今期分の配列に追加し、利子を計算する。残高が0になったものは追加しない
    if(turn > 0){
        for(const f of financialList[turn-1].fusai){
            if(f.zan > 0){
                const pref = { ...f }; //shalow copy
                pref.zan -= pref.hensai;
                fusai.push(pref);
                interest += pref.interest;  //TODO:25固定
                hensai += pref.hensai;
            }
        }
    }
    if(update){
        //今期分の利子トータル値を追加
        financialList[turn].interest = interest;
        //今期分の返済トータル値を追加
        financialList[turn].hensai = hensai;
    }
    
    return {interest, hensai};
}

export {Financial, FinancialData, calcDepreciation, calcInterest};



