import { DataGrid } from '@mui/x-data-grid';
import { Drawer, Button } from '@mui/material';

import React from 'react';
import { GXDrawer } from './GXDrawer';
import MoreVertIcon from '@mui/icons-material/MoreVert';
/**
 * 行動ログを表示
 */
function ActionLog({...props}) {
    //DataGridを使用してログを表示する
    const { G, playerID, ctx, moves, matchData, playerName } = props;
    const columns = [
        { field: 'turn', headerName: 'ターン', type: 'number', width: 100,  },
        { field: 'player', headerName: 'プレイヤー', width: 150 },
        { field: 'kind', headerName: '種類', width: 150},
        { field: 'action', headerName: '行動', width: 200 },
    ];
    const rows = G.log.slice(0).reverse().map((log, index) => {
        return {id:index,turn:log.turn, player:matchData[log.id].name, kind:log.kind, action:log.action};
    });
    return (
        <div style={{ width: '100%' }}>
            <h2>行動ログ</h2>
            列名の<MoreVertIcon />をクリックすることによりソートやフィルタができます。<br/>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableSelectionOnClick
            />
        </div>
    );
}



/**
 * Drawerとボタンを表示する
 */

function ActionLogDrawer({...props}) {
    return (
      <GXDrawer
          {...props}
          key="actionLog"
          title="行動ログ"
          component={<ActionLog {...props}/>}
      />
    );
}

export {ActionLog, ActionLogDrawer};