import React from 'react';
import { GameLobby } from './lobby/Lobby';
import { Sugoroku } from './sugoroku/SugorokuGame';
import { SugorokuBoard } from './sugoroku/SugorokuClient';
import { GX } from './gx/GXGame';
import { GXBoard } from './gx/GXBoard';
// import { Lobby } from 'boardgame.io/react';
import {DEBUG, GAME_SERVER, LOBBY_SERVER} from './setting';

import './App.css';
import JoinKey from './lobby/JoinKey';

// import { SugorokuClient } from './client'; // Import the client from client.js
// const App = () => (
//   <div>
//     <SugorokuClient playerID="0" />
//     <SugorokuClient playerID="1" />
//     <SugorokuClient playerID="2" />
//     <SugorokuClient playerID="3" />
//   </div>
// );
const App = () => {
  const [join, setJoin] = React.useState(null);
  
  //認証前
  if(join===null || 
    //promiseが解決されていない
    (join instanceof Promise && join._state===0) ){
    return (
      //参加コードを入力してください
      <JoinKey setJoin={setJoin}/>
    )
  //認証済み
  }else{

    return (
    <GameLobby
      // gameServer={ GAME_SERVER }
      // lobbyServer={ LOBBY_SERVER }
      gameServer={ DEBUG?GAME_SERVER:   join.url }
      lobbyServer={ DEBUG?LOBBY_SERVER: join.url }
        gameComponents={[
        { game: GX, board: GXBoard },
        { game: Sugoroku, board: SugorokuBoard }
      ]}
      debug={DEBUG}
      grname={join.name}
    />
    )
  }
};

export default App;
