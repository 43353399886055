// client.js
import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {Paper,Button,Drawer, Zoom, Chip} from '@mui/material';
import CountUp from 'react-countup';
import {priceAttraction, qualityAttraction, greenAttraction} from '../data/AttractionTable';
import { GXDrawer } from './GXDrawer';
import { getPlayerColor, getPlayerName } from '../util';
import { action_kind } from '../data/ActionKind';
import { Financial } from '../data/Financial';
import { Card,CardContent } from '@mui/material';
import { GAME_TURN } from '../GXSettings';
const GXPlayerBoard = ({G, ctx,player, playerName="AI Player" }) => {

  const rootsx={ 
    // height:"100%",
    backgroundImage: "url(image/gx/player_bg.png)",
    backgroundSize: "cover",
    padding: "1vw",
  }
  const namesx={
    height:"10%",
    width:"50%",
    //黒背景、白枠、白文字
    backgroundColor: "rgba(50, 50, 50)",
    borderRadius: "4px",
    color: "white",
    fontWeight: "bold",
    fontSize: "1.5rem",
    border: "solid 5px white",

  }
  const statussx={
    // height:"60%",
    aspectRatio : "16/2.6",
    backgroundImage: "url(image/gx/status_bg.png)",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    padding: "0.5vw 0.8vw",
  }
  const setubisx = {
    height:"45%",
    margin: "0.1vw",
  }

  var gridsx = {
    padding: "4px 0",
  }
  const fontstyle = {
    fontSize: '2rem',
    textAlign: 'center',
    lineHeight: '0.95em',
    fontWeight: 'bold',
    color: 'white',
  };
  const itemfontstyle = {...fontstyle, fontSize: '1.6rem'};
  const phasestyle = {
    ...fontstyle,
    fontSize: '1.2rem',
    textAlign: 'right',
  };

  const moneysx = {
    aspectRatio : "398/62",
    // 右揃え
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    padding: "0 0.8vw",
    backgroundSize: "cover",
    backgroundImage: "url(image/gx/status_02.png)",
  }
  const assetsx = {
    ...moneysx,
    backgroundImage: "url(image/gx/status_01.png)",
  }

  const itemnamesx = {
    aspectRatio: "3/2",
    // 子要素を右下そろえに
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    padding: "0.8vw 0.8vw",
    backgroundSize: "cover",
    //backgroundImageはループ文で変更
    
  }

  const shikinsx = {
    fontWeight:"bold",
    display: "flex",
    justifyContent: "space-between",
    margin: "4px 4px",
  }
  const thumbstyle = {
    width:"32%",
    // height:"60px",
    borderRadius: "4px",
    padding: "2px",
  }
  // 破産している場合破産表示を行う
  var hasan="";
  if(player.hasan != 0){
    hasan = "(破産)";    
  }
  
  // 資産
  var f = null;
  if(G.gamedata.turn > 1){
    f = new Financial(player.financial[G.gamedata.turn-2]);
  }
  const ease={"enter":"cubic-bezier(0.175, 10.885, 0.32, 1.275)","exit":"cubic-bezier(0.175, 1.885, 0.32, 1.275)"} 

  return (
    <div style={rootsx}>
      <Grid container spacing={0.5}>
        <Grid item xs={6} sx={
          {
            ...namesx,
            border: "solid 5px "+getPlayerColor(player.id),
          }}>
          {playerName}{hasan}
       </Grid>
        <Grid item xs={2}>
          <React.Fragment> 
            {ctx.activePlayers != null &&
              ( ctx.activePlayers[player.id]==undefined?
                <Chip 
                  label={"行動済み"}
                  color={"secondary"}
                  style={{ margin: "0 4px" }}
                />
                :
                <Chip 
                label={"行動中"}
                color={"primary"}
                style={{ margin: "0 4px" }}
                />
              )
            }
          </React.Fragment>
        </Grid>
        <Grid item xs={4} sx={phasestyle}>
          { G.gamedata.turn <= GAME_TURN? G.gamedata.turn+"期" :"ゲーム終了"}
            {
              ctx.phase == "selectAction"?
                "行動選択フェーズ":
              ctx.phase == "production"?
                "製造フェーズ":
              ctx.phase == "sale"?
                "販売フェーズ":
              ctx.phase == "selectPrice"?
                "価格決定フェーズ":
              ctx.phase == "trade"?
                "取引フェーズ":
              ctx.phase == "event"?
                "イベントフェーズ":
                ""
            }
        </Grid>
      </Grid> 
      <div style={statussx}>
        <Grid container spacing={0.5}>
          <Grid item xs={6} sx={gridsx}>
              <Paper variant="outlined" sx={moneysx}>
                <Typography sx ={shikinsx} color="text.secondary">
                  <CountUp end={player.money} duration={1.5} style={fontstyle}/>
                </Typography>
              </Paper>
          </Grid>
          <Grid item xs={6} sx={gridsx}>
              <Paper variant="outlined"sx={assetsx}>
                <Typography sx ={shikinsx} color="text.secondary">
                {
                    f == null ?
                    <span style={fontstyle}>-</span>
                    :
                    <CountUp end={f.netAssets} duration={1.5} style={fontstyle}/>
                }
                </Typography>
              </Paper>
          </Grid>
        </Grid>
        <Grid container sx={{height:"100%"}} spacing={0.5}>

          <br/>
          {/* <Grid item xs={3} sx={gridsx}  >
                <Paper variant="outlined" sx={itemnamesx}>
                  <Typography sx={itemnamesx} color="text.secondary">
                  販売価格
                  </Typography>
                  <CountUp end={player.price} duration={1.5} style={fontstyle_mini}/>
                </Paper>
            </Grid> */}

          {
            [
              // ["土地", player.space, 2],
              ["販売価格", player.price, "status_03.png"],
              ["消費電力",  player.energy,"status_04.png"],
              ["自家発電", player.genPow,"status_05.png"],
              ["生産量", player.product, "status_06.png"],
              ["材料生産", player.genMat, "status_07.png"],
              // 品質魅力を比例関係にしたため不要に
              // ["価格魅力", priceAttraction[player.price]],
              // ["品質", player.quality],
              // ["品質魅力", qualityAttraction[player.quality]],
              // ["環境", player.green],
              // ["環境魅力", greenAttraction[player.green]],
            ].map((output, index)=>(
              <Grid item xs={2.4}>
                <Paper variant="outlined" sx={{...itemnamesx, backgroundImage: `url(image/gx/${output[2]})`}}>
                  <CountUp end={output[1]} duration={1.5} style={itemfontstyle}/>
                </Paper>
            </Grid>
            ))
          }
        </Grid>
      </div>

      <div style={setubisx}>
        {
          [...Array(player.space)].map((_, index) => {
            const machine = player.machine.length > index ? player.machine[index] : null;
            return(
              <React.Fragment key={index}>
                <Zoom key={machine==null?index:machine.id} in={true} timeout={(index+1)*300} ea >
                {machine === null ?
                  <img
                      id={index}
                      src="image/gx/blank.png"
                      style={thumbstyle}
                    />
                :
                    <img
                      id={machine.name}
                      src={action_kind[machine.id].thumb}
                      style={thumbstyle}
                    />
                }
                </Zoom>
                {index % 3 === 2 && <br/>}
              </React.Fragment>
            )
          })
        }
      </div>  
    </div>
  );
};

/**
 * Drawerとボタンを表示する
 */

function GXPlayerBoardDrawer({ ...props}) {
  const { G, playerID, ctx, moves, matchData, playerName } = props;

  const playersx={
    padding:"1vw",
    backgroundColor:"#849056",
  }

  //全員分のボードを表示する
  const component = G.players.map((p, index) => {
    const opponentName = getPlayerName(p.id, matchData)
    return (
      <div style={playersx}>
        <GXPlayerBoard {...props} key={p.id} player={p} playerName={opponentName}/>
        <hr/>
      </div>
    )
  });

  return (
    <GXDrawer
        {...props}
        key="pl"
        title={"プレイヤーボード"}
        component={component}
    />
  );
}

export {GXPlayerBoard, GXPlayerBoardDrawer};