import { MIN_PRICE, MAX_PRICE } from "../GXSettings.js";
import { Financial } from "./Financial.js";
/**
 * 価格と価格魅力度の対応
 **/
const priceAttraction= (()=>{
    var ret = {};
    for(var i=0; MIN_PRICE<=(MAX_PRICE-(20*i)); i++){
        ret[(MAX_PRICE-(20*i))] = i;
    }
    return ret;
})();


/**
 * 品質と品質魅力度の対応
 **/
// const qualityAttraction = {
//     0: 0,
//     1: 1,
//     2: 1,
//     3: 2,
//     4: 5,
//     5: 7,
//     6: 8,
//     7: 9,
//     8: 9,
//     9: 10,
//     10: 10,
//     11: 11
// }
const qualityAttraction = { //オンライン版は簡便化のため比例関係にする
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    10: 10,
    11: 11
}
/**
 * 環境と環境魅力度の対応
 */
const greenAttraction = {
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    10: 10,
    11: 11,
    12: 12,
    13: 13,
    14: 14,
    15: 15,
    16: 16,
    17: 17,
    18: 18,
    19: 19,
    20: 20,
}

/**
 * プレイヤーデータを元に魅力値を計算する
 */
function calcAttraction(player){
    return {
        price:priceAttraction[player.price],
        quality:qualityAttraction[player.quality],
        green:greenAttraction[player.green],
        pq:priceAttraction[player.price]+qualityAttraction[player.quality],
        pqg:priceAttraction[player.price]+qualityAttraction[player.quality]+greenAttraction[player.green],
    }
}
/**
 * プレイヤーの魅力値から、どのプレイヤーがどの市場に優先度があるか計算する
 */
function calcTradePriority(pleyers){
    // 返却値格納用
    var tradePriority = {
        "cost":[],//価格市場の優先権、プレイヤーIDが格納され配列先頭から順に優先度が高い。
        "pq":[],//価格+品質市場
        "pqg":[],//価格+品質+環境市場
    };
    // プレイヤーの魅力値を計算する
    const playerAttraction = [];
    for(const p of pleyers){
        playerAttraction.push(
            {
                id: p.id,
                pAt: calcAttraction(p)
            }
        );
    }
    // 以下の形で格納する
    // tradePriority.cost = [<魅力値>:[<playerid>],<魅力値>:<playerid>];
    // tradePriority.pq = [<魅力値>:[<playerid>],<魅力値>:<playerid>];
    // tradePriority.pqg = [<魅力値>:[<playerid>],<魅力値>:<playerid>];
    playerAttraction.map((p)=>{
        if(tradePriority.cost[p.pAt.price] == undefined){
            tradePriority.cost[p.pAt.price] = [];
        }
        if(tradePriority.pq[p.pAt.pq] == undefined){
            tradePriority.pq[p.pAt.pq] = [];
        }
        if(tradePriority.pqg[p.pAt.pqg] == undefined){
            tradePriority.pqg[p.pAt.pqg] = [];
        }
        tradePriority.cost[p.pAt.price].push(p.id);
        tradePriority.pq[p.pAt.pq].push(p.id);
        tradePriority.pqg[p.pAt.pqg].push(p.id);
    });
    return tradePriority;
}


/**
 * 市場需要の数と、優先権を加味して、プレイヤーが市場に出す量を計算する
 * @param {*} players
 * @param {*} tradeP calcTradePriorityの返却値の特定種別の配列
 * @param {*} demand 市場需要数
 * @returns {sell:[プレイヤーごとの売り上げ数], unsold:余りの需要数}
 */ 
function calcTradeAmount( players, tradeP, demand, kind){
    // 返却値用　プレイヤー売り上げ数
    var tradeAmount = {sell:[], unsold:demand};
    // 初期化
    for(const p of players){
        tradeAmount.sell[p.id] = 0;
    }

    // 魅力度高い順
    tradeP.reverse();

    // 魅力度の高いプレイヤーから順に処理する
    for(const pri of tradeP){
        if(pri == undefined){
            continue;
        }
        // 魅力度の高いプレイヤーの在庫数を積算する
        var stock = 0;
        for(const pid of pri){
            stock += players[pid].stock
        }
        // 積算数より需要数が多い場合
        if(stock < tradeAmount.unsold){
            // プレイヤーの売上数を生産数分計上し、在庫数を空にし、需要数を減らす
            for(const pid of pri){
                tradeAmount.sell[pid] += players[pid].stock;
                tradeAmount.unsold -= players[pid].stock;
                players[pid].stock = 0;
            }
            //次の優先度を処理する
            continue;

        // 積算数より需要数が少ない場合
        }else{
            // 在庫0ではないプレイヤーid配列
            var pids = [];
            for(const pid of pri){
                if(players[pid].stock > 0){
                    pids.push(pid);
                }
            }
            if(pids.length == 0){
                continue;
            }
            // 需要数を優先度の高いプレイヤーで山分けできるところまで山分けする
            var share = Math.floor(tradeAmount.unsold / pids.length);

            // プレイヤーの売上数を計上し、在庫数を減らし、/*需要数は次に持ち越す→越さないで他優先度で決める*/
            for(const pid of pids){
                const ure = players[pid].stock < share ? players[pid].stock : share;
                tradeAmount.sell[pid] += ure;
                tradeAmount.unsold -= ure;
                players[pid].stock -= ure;
            }

            // プレイヤーごとの以下の配列を作る[{pid:pid,p:価格魅力度, q:品質, g:環境, total:総資産}]
            var playerAttraction = [];
            for(const pid of pids){
                var totalAssets = 0;
                if(players[pid].financial.length >= 2){
                    const financial = new Financial(players[pid].financial.slice(-2,-1)[0]);//最後から二個目＝前期のデータを取得
                    totalAssets = financial.totalAssets;
                }
                playerAttraction.push(
                    {
                        pid: pid,
                        p: priceAttraction[players[pid].price],
                        q: qualityAttraction[players[pid].quality],
                        g: greenAttraction[players[pid].green],
                        total: totalAssets,
                    }
                );
            }
            //在庫がないプレイヤーを除外する
            playerAttraction = playerAttraction.filter(function(o){return players[o.pid].stock != 0;});
            if(playerAttraction.length == 0){
                continue;
            }

            // この後の処理で最大プレイヤー以外をplayerAttraction配列から除外していく
            // // p
            // const maxP = Math.max.apply(null,playerAttraction.map(function(o){return o.p;}));
            // playerAttraction = playerAttraction.filter(function(o){return o.p == maxP;});

            // q
            if(kind == "p"){
                var maxQ = Math.max.apply(null,playerAttraction.map(function(o){return o.q;}));
                playerAttraction = playerAttraction.filter(function(o){return o.q == maxQ;});
            }
            // g
            if(kind == "p" || kind == "q"){
                var maxG = Math.max.apply(null,playerAttraction.map(function(o){return o.g;}));
                playerAttraction = playerAttraction.filter(function(o){return o.g == maxG;});
            }
            // total
            var maxTotal = Math.max.apply(null,playerAttraction.map(function(o){return o.total;}));
            playerAttraction = playerAttraction.filter(function(o){return o.total == maxTotal;});

            //同率プレイヤーがいなければ余りを割り当てる
            if( playerAttraction.length == 1 ){
                tradeAmount.sell[playerAttraction[0].pid] += tradeAmount.unsold;
                players[playerAttraction[0].pid].stock -= tradeAmount.unsold;
            }else{
                console.log("★同率プレイヤーあり★",playerAttraction,tradeAmount.unsold);
            }
            tradeAmount.unsold = 0;

            // 需要が0になったため、処理を終了する
            break;
        }
    }
    return tradeAmount;
}
function calcTradeAmountP( players, tradeP, demand){
    return calcTradeAmount( players, tradeP, demand, "p");
}
function calcTradeAmountQ( players, tradeP, demand){
    return calcTradeAmount( players, tradeP, demand, "q");
}
function calcTradeAmountG( players, tradeP, demand){
    return calcTradeAmount( players, tradeP, demand, "g");
}



export { priceAttraction, qualityAttraction, greenAttraction, calcAttraction, calcTradePriority, 
    calcTradeAmount, calcTradeAmountP, calcTradeAmountQ, calcTradeAmountG
}