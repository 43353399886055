// client.js
import * as React from 'react';
import  { useRef, useState  } from 'react';

import PropTypes from 'prop-types';

import { useTheme } from '@mui/material/styles';
import {
  ToggleButton,
  ToggleButtonGroup,
  Button,
  Slider,
  Grid,
  Typography,
  Zoom,
  Card,
  CardMedia,
  CardContent,
  Tabs,
  Tab,
  Slide,
  Grow,
  Paper,
  Popover,
  Chip,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from '@mui/material';

//icon
import VerifiedIcon from '@mui/icons-material/Verified';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import CancelIcon from '@mui/icons-material/Cancel';

//装飾用ライブラリ
import ReactDice, { ReactDiceRef } from 'react-dice-complete'
import {SlotMachine} from './SlotMachine.tsx';
import { TypeAnimation } from 'react-type-animation';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { TimerView } from './TimerView.js';

import {action_kind} from '../data/ActionKind';
import {diceCost as dc, diceQuality as dq, diceGreen as dg, diceText, diceImage,
        diceEvent1Text, diceEvent2Text, diceEvent3, diceEvent3Text, diceEvent3Desc} from '../data/DiceTable';
import { AppBar } from '@mui/material';
import GXModal from './GXModal';
import {calcInterest} from '../data/Financial';
import {PlayerDataList} from './PlayerDataList';
import { getPlayerName } from '../util.js';

import './css/trade-table.css';
import './css/board.css';

//音
import useSound from 'use-sound';
import cardSound from '../sound/card_mekuri.mp3';
import tabSound from '../sound/tab.mp3';
import clickSound from '../sound/click.mp3';
import slotSound from '../sound/slot.mp3';
import typingSound from '../sound/typing.mp3';
import poSound from '../sound/po.mp3';
import finishSound from '../sound/finish.mp3';
import { Tutorial, 
  actionTutorial, genTutorial, impTutorial, otherTutorial, prodnumTutorial, priceTutorial,tradeTutorial,financeTutorial } from './Tutorial';
import { TradeModal } from './TradeModal.js';
import { PlayerResult, ResultModal } from './ResultModal.js';
import { AllPlayerNetAssetsChart, NetAssetsRanking } from './FinancialAnalyze.js';
import {priceAttraction} from '../data/AttractionTable';
import {DEMO_GAME_TURN, GAME_TURN, MAX_PRICE, MIN_PRICE} from '../GXSettings'

const buttonsx = {
  "&.Mui-selected":{
    fontWeight:900,
  }
}
const cardsx={
  // width:"100%",
  margin:"2vw 0",
  border:0,
  "&.Mui-selected":{
    fontWeight:900,
    backgroundColor:"#ff8888",
  },
  //フォーカス時
  "&:hover":{
    backgroundColor:"#ffcccc",
  },
  //選択&フォーカス時
  "&.Mui-selected:hover":{
    backgroundColor:"#ff8888",
  },
}
const cardimagesx={
  borderRadius:"0.5vw",
  width:"100%",
  height:"auto",
}

const tabsx={
  ".MuiTabs-indicator":{
    height:"100%",
    opacity:0.5,
  },
  height:30,
  minHeight:30,
}

const titlestyle = {
  fontWeight:900,
  //左寄せ
  margin: "0 0 auto 0", 
  display: "flex",
  /*線の種類（二重線）太さ 色*/
  borderBottom: "double 5px #1976d2",
}

const rightcardsx={
  backgroundColor:"#000000bb",
  color:"#ffffff",
  aspectRatio:"10/15",
  borderRadius: "10px",
  padding:"1vw",
}

const teppansx={
  backgroundImage: "url('image/gx/teppan02.png')",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
}

const hekomiText_white ={
	// padding: "0.5rem",
	color: "#FFF",
  fontSize: "3rem",
	bordeRadius: "1px",
	textShadow: "1px 6px 5px rgba( 0, 0, 0, 0.8)",
  boxShadow: "inset 1px 3px 6px rgba( 0, 0, 0, 0.9)",
  backgroundColor: "#333333",
}
const hekomiText_black ={
  fontSize: "3rem",
  fontWeight: "bold",
  color: "transparent",
  background: "#000",
  textShadow: "2px 2px 4px rgba( 50, 50, 50)",
  "-webkit-background-clip": "text",
};

/**
 * アクションボード親
 * @param {*} param0 
 * @returns 
 */
const GXActionBoard = ({ ...props }) => {
  const { G, playerID, ctx, moves, matchData, settings, playerName } = props;
  const [actionTutoStart, setActionTutoreal]  = React.useState(false);
  const [priceTuto, setPriceTuto]  = React.useState(false);
  const [prodTuto, setProdTuto]  = React.useState(false);
  const [tradeTuto, setTradeTuto]  = React.useState(false);
  const [financeTuto, setFinanceTuto] = React.useState(false);

  const [chartModal, setChartModal] = React.useState(false);
  const [actionModal, setActionModal] = React.useState(false);
  const [productionModal, setProductionModal] = React.useState(false);
  const [priceModal, setPriceModal] = React.useState(false);
  const [demandModal, setDemandModal] = React.useState(false);
  const [periodModal, setPeriodModal] = React.useState(false);
  const [rankModal, setRankModal] = React.useState(false);
  const [tradeModal, setTradeModal] = React.useState(false);
  const [eventModal, setEventModal] = React.useState(false);

  const [finishPlay] = useSound(finishSound,{volume:settings.vol*0.5});
  const ease={"enter":"cubic-bezier(0.175, 20.885, 0.32, 1.275)"} 
  // ゲーム終了時
  if (ctx.gameover || (playerName.startsWith('DEMO_') && G.gamedata.turn > DEMO_GAME_TURN)) {
    //デモプレイ早期終了
    if(G.gamedata.demo || (playerName.startsWith('DEMO_') && G.gamedata.turn > DEMO_GAME_TURN)){
      return(
        <ResultModal {...props} onClose={()=>{window.location.href = "https://gxkojo.sirkus.co.jp/";}} headerimage={"gxkojo.png"}>
            <div>
              <h2>デモプレイ終了！！</h2>
              デモプレイはここまでです。お疲れ様でした。<br/>
              フルバージョンでは以下の機能が追加されています。<br/>
              <ul style={{textAlign:"left"}}>
                <li>ターン数が{GAME_TURN}期</li>
                <li>プレイヤー全員の財務状況の確認</li>
                <li>様々な財務分析ツール</li>
                <li>e-learningによる。経営の基礎学習とゲームの解説</li>
                <li>講師によるマーケティングと財務知識の講義</li>
                <li>ゲーム結果のディスカッションと現実の経営戦略との関連付け</li>
              </ul>

              <a href="https://gxkojo.sirkus.co.jp/" target="_blank">お問い合わせはこちら</a>
            </div>
        </ResultModal>
      )
    }

    //正規ゲーム終了
    var winner = "";
    if(ctx.gameover.winner == -1){
      winner = "全プレイヤー破産・・・";
    }else{
      winner = getPlayerName(ctx.gameover.winner, matchData)+ "さんの勝利です!!";
    }
    return(
      <React.Fragment>
      { //結果発表表示
        <GXModal {...props} blur={"3"} backgroundImage={"url('image/gx/kekkahappyu_bg.png')"} onClose={()=>{finishPlay();setRankModal(true)}} component={
          <Grid container spacing={3} sx={{width:'80%',margin:"0 auto",
          }}>
            <Grid item xs={3}>
              <Zoom in={true} easing={ease} style={{ transitionDelay: '500ms'}}>
                <img src={`image/gx/title_ke.png`} style={{width:"100%"}}/>
              </Zoom>
            </Grid>
            <Grid item xs={3}>
              <Zoom in={true} easing={ease} style={{ transitionDelay: '1000ms'}}>
                <img src={`image/gx/title_ka.png`} style={{width:"100%"}}/>
              </Zoom>
            </Grid>
            <Grid item xs={3}>
              <Zoom in={true} easing={ease} style={{ transitionDelay: '1500ms'}}>
                <img src={`image/gx/title_ha.png`} style={{width:"100%"}}/>
              </Zoom>
            </Grid>
            <Grid item xs={3}>
              <Zoom in={true} easing={ease} style={{ transitionDelay: '2000ms'}}>
                <img src={`image/gx/title_pyou.png`} style={{width:"100%"}}/>
              </Zoom>
            </Grid>
          </Grid>
        }/>
      }
      { //今期純資産
        rankModal &&
        G.gamedata.turn > 1 &&
        <ResultModal {...props} onClose={()=>setChartModal(true)} headerimage={"title_equity.png"}>
            <NetAssetsRanking {...props}/>
        </ResultModal>
      }
      { //純資産チャート
        (G.gamedata.turn > 1 && chartModal) &&
        <ResultModal {...props} onClose={()=>{setActionModal(true)}} headerimage={"title_equitygraph.png"}>
          <AllPlayerNetAssetsChart {...props}/>
        </ResultModal>
      }

      { actionModal &&
        <div style={{
          backgroundImage: "url('image/gx/teppan02.png')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          }}>
            <div style={{padding:"1.5rem"}}>
              <div style={{...hekomiText_black,fontSize:"2rem"}}>
                  ゲーム終了!!
              </div>
              <div style={{color:"black",fontSize:"1.5rem"}}>
                  {winner}　
                  各プレイヤーの財務諸表を確認してみましょう
              </div>
              <PlayerDataList {...props}/>
            </div>
        </div>
      }
      </React.Fragment>
    )
  }

  /**
   * action名からactionを返却する
   */
  function getActionByName(name){
    for(const action of Object.values(action_kind)){
      if(action.name === name){
        return action;
      }
    }
    return null;
  }
  /**
   * G.log[ {
        id: 0
        turn: 1
        action: "通常機械"
        kind: "行動選択"
        },...]
        の中から前のターンかつ指定のKindである要素をすべて返却する
   */
  function preaction(kind){
    if(kind=="")return [];

    const result = [];
    for(const log of G.log){
      if(log.turn === G.gamedata.turn && log.kind === kind){
        result.push({
          "id":log.id,
          "name":getPlayerName(log.id,matchData),
          "action":log.action,
          "actionObject":getActionByName(log.action)
        });
      }
    }
    // ユーザーごとにまとめて["id":[{"action"...},{"action"...}]]の形にする
    const result2 = [];
    for(const r of result){
      if(result2[r.id] === undefined){
        result2[r.id] = [];
      }
      result2[r.id].push(r);
    }

    return result2;
  }

  // フェーズごとの表示切替
  const phases = [
    {img:"phase_action.png",kind:""},
    {img:"phase_production.png",kind:"行動選択"},
    {img:"phase_price.png",kind:"生産"},
    {img:"phase_demad.png",kind:"価格決定"},
    {img:"title_product_sales.png",kind:""},
    {img:"phase_event.png",kind:""}
  ].map((e,index)=>(
      <img src={"/image/gx/"+e["img"]} style={{width:"80rem"}}/>
  ))

  // アクション選択結果画面
  const preaction_actions = preaction("行動選択");
  const action_results = [];
  if(preaction_actions.length > 0 ){
    for(const p of G.players){
      action_results.push(
        <PlayerResult player={p} {...props}>
          { preaction_actions[p.id] && 
            preaction_actions[p.id].map((e)=>(
            <img src={e.actionObject.resultimg} style={{width:"50%"}}/>
          ))}
        </PlayerResult>
      )
    }
  }

  // 生産数決定結果画面
  const preaction_production = preaction("生産");
  const production_results = [];
  if(preaction_production.length > 0 ){
    for(const p of G.players){
      production_results.push(
        <PlayerResult player={p} {...props}>
          { preaction_production[p.id] &&
            preaction_production[p.id].map((e)=>(
              <div style={{
                fontSize:"5rem",
                // fontWeight:900,
                color:"#ffffff",
                backgroundImage: "url('image/gx/result_player_bg_child_l.png')",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                textAlign:"center",
                }}>
                {e.action}×<img src="image/gx/icon_box.png" style={{height:"5rem"}}/>
              </div>
          ))}
        </PlayerResult>
      )
    }
  }
  // 価格決定結果画面
  const preaction_price = preaction("価格決定");
  const price_results = [];
  if(preaction_price.length > 0 ){
    for(const p of G.players){
      price_results.push(
        <PlayerResult player={p} {...props}>
          { preaction_price[p.id] &&
            preaction_price[p.id].map((e)=>(
              <Grid container>
                <Grid item xs={6} sx={{
                  backgroundImage: `url('image/gx/presult_rice${e.action}.png')`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}>
                  </Grid>
                  <Grid item xs={6} sx={{
                  backgroundImage: "url('image/gx/result_player_bg_child_s.png')",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  fontSize:"4rem",
                  height:"6rem",
                  textAlign:"center",
                  }}>
                    {p.price}
                  </Grid>
              </Grid>
          ))}
        </PlayerResult>
      )
    }
  }
            




  // 前のターンで破産したプレイヤーがいる場合
  var isHasan = false;
  var hasanPlayer = [];
  for(const p of G.players){
    if(p.hasan !=0 && p.hasan == G.gamedata.turn-1){
      isHasan = true;
      hasanPlayer.push(getPlayerName(p.id,matchData));
    }
  }
  

  const hasanComponent = (
    <Paper style={{width:"80%", margin:"auto"}}>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        現金がマイナスになったプレイヤーがいます
      </Typography>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        プレイヤー：{
          hasanPlayer.map((e)=>(
            <div>{e}</div>
          ))
        }は破産処理を行い、現金1000万円を受け取りますがゲームに勝利できなくなります
      </Typography>
    </Paper>
    )

  return (
    <div style={{textAlign:"center"}}>
      { ctx.phase==="selectAction" && (
        <div>
          <Tutorial running={actionTutoStart} tutorial={actionTutorial}/>
          <Tutorial running={financeTuto} tutorial={financeTutorial}/>
          { //今期純資産
            G.gamedata.turn > 1 &&
            <ResultModal {...props} onClose={()=>setChartModal(true)} headerimage={"title_equity.png"}>
                <NetAssetsRanking {...props}/>
            </ResultModal>
          }
          { //純資産チャート
            (G.gamedata.turn > 1 && chartModal) &&
            <ResultModal {...props} onClose={()=>{setPeriodModal(true)}} headerimage={"title_equitygraph.png"}>
              <AllPlayerNetAssetsChart {...props}/>
            </ResultModal>
          }
          { //N期表示
            ( G.gamedata.turn == 1 || periodModal) &&
            <GXModal {...props} onClose={()=>{setActionModal(true)}} component={
              <img src={`image/gx/period_${G.gamedata.turn}.png`} style={{width:"80%"}}/>
            }/>
          }
          { //アクション選択モーダル
            actionModal &&
            <GXModal {...props} onClose={()=>{
              setPeriodModal(false);
              setChartModal(false);
              setActionModal(false);
              setActionTutoreal(true);
              if(G.gamedata.turn === 2){
                setFinanceTuto(true);
              }
            }} component={phases[0]}/>
          }
          <SelectAction
            {...props}
            />
       </div>
      )}
      { ctx.phase==="production" && (
        <div>
          <Tutorial running={prodTuto} tutorial={prodnumTutorial}/>
          <ResultModal {...props} onClose={()=>setProductionModal(true)} headerimage={"title_select_act.png"}>
            {action_results}
          </ResultModal>
          { productionModal &&
            <GXModal {...props} onClose={()=>{setProductionModal(false);setProdTuto(true)}} component={phases[1]}/>
          }

          <SelectProduction
            {...props}
            />       
        </div>
      )}
      { ctx.phase==="selectPrice" && (
        <div>
          <Tutorial running={priceTuto} tutorial={priceTutorial}/>
          <ResultModal {...props} onClose={()=>setPriceModal(true)} headerimage={"title_productionnum.png"}>
            {production_results}
          </ResultModal>
          { priceModal &&
            <GXModal {...props} onClose={()=>{setPriceModal(false);setPriceTuto(true)}} component={phases[2]}/>
          }
          <SelectPrice
            {...props}
            />         
        </div>
      )}
      { ctx.phase==="trade" && (
        <div>
          <Tutorial running={tradeTuto} tutorial={tradeTutorial}/>
          <ResultModal {...props} onClose={()=>setDemandModal(true)} headerimage={"title_select_price.png"}>
            {price_results}
          </ResultModal>
          { demandModal &&
              <GXModal {...props} onClose={()=>{setDemandModal(false);setTradeTuto(true)}} component={phases[3]}/>
          }
            <Trade
              {...props}
              />       
        </div>
      )}
      {
        ctx.phase==="event" && (
          <div>
            <GXModal {...props} component={phases[5]}/>
            <EventBoard
              {...props}
              />
          </div>
      )}
      { isHasan && (
        <div>
          <GXModal {...props} component={hasanComponent}/>
        </div>
      )}
    </div>
  );
};

/**
 * 設備購入アクションボード
 * @param {*} param0 
 * @returns 
 */
const SelectAction = ({ ...props }) => {
  const { G, playerID, ctx, moves, matchData,settings } = props;
  const theme = useTheme();
  //音
  const [cardPlay] = useSound(cardSound,{volume:settings.vol});
  const [tabPlay] = useSound(tabSound,{volume:settings.vol*0.5});
  const [clickPlay] = useSound(clickSound,{volume:settings.vol});

  const [selection1, setSelection1] = React.useState(() => []);
  const [selection2, setSelection2] = React.useState(() => []);
  const [selection3, setSelection3] = React.useState(() => []);
  const [selection4, setSelection4] = React.useState(() => []);

  const [tabValue, setTabValue] = React.useState(1);
  const [tabValueEnd, setTabEndValue] = React.useState(0);//Transition完了後にtabValueを代入

  const [selecctScreen, setSelectScreen] = React.useState(true); //購入候補画面用

  const tabChange = (event, newValue) => {
    tabPlay();
    setTabValue(newValue);
  };
  const [result, setResult] = React.useState({"complete":false,"message":"設備や行動を選択してください(２つまで選択可能)"});

  const headersx={
    fontWeight:900,
  }
  const rootgridsx={
      // backgroundImage: "url('image/gx/belt_machine_bg.png')",
      // backgroundSize: "contain",
  }
  const action_leftsx={
    backgroundImage: "url('image/gx/belt_machine.png')",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
  }
  const action_centersx={
    backgroundImage: "url('image/gx/belt_conveyor.png')",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom",
  }
  const action_rightsx={
    backgroundImage: "url('image/gx/belt_machine_r.png')",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  }



  React.useEffect(() => {
    setTabValue(0); //タブのアニメが初回動かないバグがあるので無駄にタブを切り替える
  }, []);

  /* 決定 */
  const done = () => {
    clickPlay();
    moves.done([...selection1,...selection2,...selection3,...selection4], result);
    setResult(result);
  }
 
  /* タブ用ここから */
  function CustomTabPanel(props) {
    const { children, tabValue, index, onChange, selectionValue, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={tabValue !== index}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {tabValue === index && (
          <ToggleButtonGroup
            color="primary"
            value={selectionValue}
            onChange={(e,v)=>{onChange(v)}}
          >
            {children}
        </ToggleButtonGroup>
        )}
      </div>
      );
  }
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `actiontab-${index}`,
      'aria-controls': `actiontabpanel-${index}`,
    };
  }
  /* タブ用ここまで */

  const productionList = [];
  const generatorList = [];
  const investList = [];
  const otherList = [];

  for (const action of Object.entries(action_kind)) {
    /* カード要素 */
    const card = (title)=>(
      <ToggleButton
        key={action[0]}
        onClick={()=>cardPlay()}
        color="primary"
        value={action[0]} 
        sx={{
          ...cardsx,
        }}>
          <img
            width={180}
            height={250}
            id={action[1].name}
            src={action[1].image} 
            style={cardimagesx}
            title={title}
          />
      </ToggleButton>

    );

    /* 生産機械タブ */
    if(action[1].kind === "production"){
      const title=`
${action[1].name}
コスト：${action[1].price}
減価償却：${action[1].price/5}
生産量：${action[1].prod}
消費電力：${action[1].energy}
品質：${action[1].quality}
GX変動：${action[1].gx}
`
      productionList.push(
        card(title)
      )
    /* 原材料・エネルギータブ */
    }else if(action[1].kind === "generator"){
      const title=`
${action[1].name}
コスト：${action[1].price}
減価償却：${action[1].price/5}
GX変動：${action[1].gx}
${action[1].genM>0?"原材料生産："+action[1].genM:"自家発電量："+action[1].genP}
`
      generatorList.push(
        card(title)
      )
    /* 品質・環境改善 */
    }else if(action[1].kind === "invest"){
      const title=`
${action[1].name}
コスト：${action[1].price}
`
      investList.push(
        card(title)
      )
    }else if(action[1].kind === "other"){
      const title=`
${action[1].name}
コスト：${action[1].price}
`
      otherList.push(
        card(title)
      )
    }
  }

  //その他にダミーカード追加
  const dummycard = ()=>(
    <ToggleButton
      key={"dummy"}
      color="primary"
      disabled={true}
      sx={{
        ...cardsx,
      }}>
        <img
          width={180}
          height={250}
          id={"dummy"}
          src={"image/gx/cardback.png"}
          style={cardimagesx}
        />
    </ToggleButton>
  );
  otherList.unshift(dummycard());
  otherList.push(dummycard());


  // 選択アクション取り消しボタン
  // actionKindのidから選択アクションの種類を判定して配列から除外
  const removeAction = (id) => {
    clickPlay();
    const kind = action_kind[id].kind;
    if(kind === "production"){
      setSelection1(selection1.filter((e)=>e!==id));
    }else if(kind === "generator"){
      setSelection2(selection2.filter((e)=>e!==id));
    }else if(kind === "invest"){
      setSelection3(selection3.filter((e)=>e!==id));
    }else if(kind === "other"){
      setSelection4(selection4.filter((e)=>e!==id));
    }
  }

  //const expire = G.gamedata.turn == 1 ? 600 : 300;
  const expire = 1200;

  var purchaseList = [...selection1,...selection2,...selection3,...selection4];
  return (
    <Grid container id="actionboard" sx={rootgridsx}>
      <Grid item xs={1.5} sx={action_leftsx}>
      </Grid>

      <Grid item xs={8}>
      { //アクション選択画面
        selecctScreen ?
        (
          <div>
          <AppBar position="static">
            <Tabs
              id="actiontab" 
              sx={tabsx}
              value={tabValue} 
              onChange={tabChange} 
              variant="fullWidth"
              indicatorColor="secondary"
              textColor="inherit"
              aria-label="purchasetab">
                <Tab id="producttab" sx={tabsx} icon={<PrecisionManufacturingIcon/>} iconPosition="start" label="生産機械" {...a11yProps(0)} />
                <Tab id="gentab" sx={tabsx} icon={<EnergySavingsLeafIcon/>} iconPosition="start" label="材料・電力" {...a11yProps(1)} />
                <Tab id="techtab" sx={tabsx} icon={<AddCircleOutlineIcon/>} iconPosition="start" label="開発・環境" {...a11yProps(2)} />
                <Tab id="othertab" sx={tabsx} icon={<AccountBalanceIcon/>} iconPosition="start" label="その他" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={tabValue}
            onChangeIndex={(v)=>{setTabValue(v)}}
            onTransitionEnd={()=>{setTabEndValue(tabValue)}}
            style={action_centersx}
            // style={{ 
            //   backgroundImage: "url('image/gx/belt_conveyor.png')",
            //   backgroundSize: "contain",
            // }}
          >
            <CustomTabPanel tabValue={tabValue} index={0} selectionValue={selection1} onChange={setSelection1}>
            {productionList}
            </CustomTabPanel>
            <CustomTabPanel tabValue={tabValue} index={1} selectionValue={selection2} onChange={setSelection2}>
              {<Tutorial running={tabValueEnd==1} tutorial={genTutorial}/>}            
              {generatorList}
            </CustomTabPanel>
            <CustomTabPanel tabValue={tabValue} index={2} selectionValue={selection3} onChange={setSelection3}>
              <Tutorial running={tabValueEnd==2} tutorial={impTutorial}/>
              {investList}
            </CustomTabPanel>
            <CustomTabPanel tabValue={tabValue} index={3} selectionValue={selection4} onChange={setSelection4}>
              <Tutorial running={tabValueEnd==3} tutorial={otherTutorial}/>
              {otherList}
            </CustomTabPanel>
          </SwipeableViews>
          </div>
        )
        :
        // アクション確認画面
        (
          <Grow in={true}  style={{ transformOrigin: '0 0 0' }}><div>
            <TableContainer sx={{width:"80%", margin:"auto"}}component={Paper}>
              <Table>
                <TableHead>
                  <TableRow sx={{borderBottom:2}}>
                    <TableCell sx={headersx}>購入候補</TableCell>
                    <TableCell sx={headersx}>コスト</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    purchaseList.map((id)=>(
                      <TableRow>
                        <TableCell>{action_kind[id].name}</TableCell>
                        <TableCell>{action_kind[id].price==0?"借入による入金は行動選択後です":action_kind[id].price}</TableCell>
                        <TableCell align='right'><Button onClick={()=>removeAction(id)} disabled={ctx.activePlayers[playerID]==undefined}  variant="outlined" endIcon={<CancelIcon/>} color='secondary'>取り消し</Button></TableCell>
                      </TableRow>
                    ))
                  }
                  <TableRow sx={{borderTop:2}}>
                    <TableCell sx={headersx}>合計費用</TableCell>
                    <TableCell sx={headersx}>{purchaseList.reduce((a,b)=>a+action_kind[b].price,0)} (購入後プレイヤー資金　{G.players[playerID].money-purchaseList.reduce((a,b)=>a+action_kind[b].price,0)})</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div></Grow>
        )
        
      }
      </Grid>
      <Grid item xs={2.5} sx={action_rightsx}>
        <div style={rightcardsx}>
        <TimerView expire={expire} onExpire={()=>{moves.done([], result);}}/><br/>
        {result["message"]}<br/>
          {
            selecctScreen ?
            <React.Fragment>
              <div id="purchase">
                <hr/>
                {
                  purchaseList.map((e)=>(
                    <div>
                    <Chip
                    key={e}
                    label={`${action_kind[e].name}  ${action_kind[e].price}`}
                    variant="outlined" 
                    color={"primary"}
                    style={{ margin: "0 4px",backgroundColor:"#ffffff", width:"10vw" }}
                    onClick={()=>removeAction(e)}
                  />
                  </div>
                  ))
                }
                合計費用：{purchaseList.reduce((a,b)=>a+action_kind[b].price,0)}
                <br/>
              </div>
              <Button onClick={()=>{clickPlay();setSelectScreen(false);}} disabled={ctx.activePlayers[playerID]==undefined}  variant="contained" endIcon={<VerifiedIcon/>}>確認</Button>
            </React.Fragment>
            :
            <div>
            <Button onClick={()=>{clickPlay();setSelectScreen(true);}} disabled={ctx.activePlayers[playerID]==undefined}  variant="contained" endIcon={<FirstPageIcon/>}>戻る</Button>
            <Button onClick={done} disabled={ctx.activePlayers[playerID]==undefined}  variant="contained" endIcon={<VerifiedIcon/>}>決定</Button>            
            </div>
          }
        </div>
      </Grid>
    </Grid>
  );
};

/**
 * 価格選択アクションボード
 * @param {} param0 
 * @returns 
 */
const SelectPrice = ({ ...props }) => {
  const { G, playerID, ctx, moves, matchData,settings } = props;
  const [selection, setSelection] = React.useState("0");
  //音
  const [cardPlay] = useSound(cardSound,{volume:settings.vol});
  const [clickPlay] = useSound(clickSound,{volume:settings.vol});
  const player = G.players[playerID];

  const handleChange = (event, newVal) => {

    setSelection(newVal);
  };

  const [result, setResult] = React.useState({"complete":false,"message":"価格を選択"});
  const done = () => {
    clickPlay();
    moves.done(selection, result);
    setResult(result);
  }

  //const expire = G.gamedata.turn == 1 ? 240 : 120;
  const expire = 1200;

  const kari_price = player.price + parseInt(selection) > MAX_PRICE ? MAX_PRICE : 
                    player.price + parseInt(selection) < MIN_PRICE ? MIN_PRICE : player.price + parseInt(selection);
  const kari_attraction = priceAttraction[kari_price];                    

  return (
    <Grid container>
      <Grid item xs={9.5} sx={teppansx}>
        <Grid container sx={{margin:"2rem 0 0 0"}} columns={19}>
          <Grid item xs={2.5} sx={{margin: "auto 0"}}>
            <img src="image/gx/text_kakaku.png" style={{height:"3rem"}}/>
          </Grid>
          <Grid item xs={2.5}>
            <div style={hekomiText_white}>{player.price}</div>
          </Grid>
          <Grid item xs={1} sx={{margin: "auto 0"}}>
            <img src="image/gx/text_arrow.png" style={{height:"3rem"}}/>
          </Grid>
          <Grid item xs={2.5}>
            <div style={hekomiText_white}>{kari_price}</div>
          </Grid>
          <Grid item xs={1}>
          </Grid>
          <Grid item xs={5}  sx={{margin: "auto 0"}}>
            <img src="image/gx/text_kakakusokyuryoku.png" style={{height:"3rem"}}/>
          </Grid>
          <Grid item xs={1.5}>
            <div style={hekomiText_white}>{priceAttraction[player.price]}</div>
          </Grid>
          <Grid item xs={1}  sx={{margin: "auto 0"}}>
            <img src="image/gx/text_arrow.png" style={{height:"3rem"}}/>
          </Grid>
          <Grid item xs={1.5}>
            <div style={hekomiText_white}>{kari_attraction}</div>
          </Grid>
          <Grid item xs={0.5}>
          </Grid>
        </Grid>
      <div id="price">
        <Grid container>
          <Grid item xs={2} sx={{margin: "auto 0"}}>
            <img src="image/gx/price_max&min.png" style={{height:"15rem"}}/>
          </Grid>
          <Grid item xs={10}>
            <ToggleButtonGroup
              sx={{
                fontWeight:900,
              }}
              color="primary"
              value={selection}
              exclusive
              onChange={handleChange}
              aria-label="text formatting"
              disabled={ctx.activePlayers[playerID]==undefined}
            >
              {
                [
                  ["-40","image/gx/price-40.png"],
                  ["-20","image/gx/price-20.png"],
                  ["0","image/gx/price0.png"],
                  ["20","image/gx/price+20.png"],
                  ["40","image/gx/price+40.png"],
                ].map((e)=>(
                  <ToggleButton 
                    value={e[0]} 
                    sx={{...cardsx, width:"20%", height:"auto"}}
                    onClick={()=>cardPlay()}
                    >
                      <img src={e[1]} alt={e[1]} style={cardimagesx}/>
                  </ToggleButton>
                ))
              }
            </ToggleButtonGroup>
          </Grid>
       </Grid>
      </div>
      </Grid>

      <Grid item xs={2.5}>
        <div style={rightcardsx}>
          <TimerView expire={expire} onExpire={()=>{
            if(selection === null){
              return moves.done("0", result);
            }
            return done();
          }}/><br/>
          {result["message"]}<br/><br/>
          価格決定を決定してください。<br/>
          価格を上げると価格魅力度が減り、下げると増えます。<br/>
          価格を20増減するごとに、価格魅力度が1変化します。<br/>
        <hr/>
        <Button onClick={done} disabled={ctx.activePlayers[playerID]==undefined} variant="contained" endIcon={<VerifiedIcon/>}>決定</Button>
        </div>
      </Grid>
    </Grid>
  );
};

/**
 * 生産量決定アクションボード
 * @param {*} param0 
 * @returns 
 */
const SelectProduction = ({ ...props }) => {
  const { G, playerID, ctx, moves, matchData,settings } = props;
  const player = G.players[playerID];
  const [result, setResult] = React.useState({"complete":false,"message":"生産量を選択してください"});
  const [numProduction, setValue] = React.useState(0);
  //音
  const [clickPlay] = useSound(clickSound,{volume:settings.vol});
  const [poPlay] = useSound(poSound,{volume:settings.vol*0.5});

  const handleChange = (event, newValue) => {
    poPlay();
    setValue(newValue);
  };
  const done = () => {
    clickPlay();
    moves.done(isNaN(numProduction)?0:numProduction, result);
    setResult(result);
  }
  //プレイヤーデータに基づき生産量の最大値を設定
  const maxProduction = player.product?player.product:0;

  // 生産費用
  const productionCost =  (numProduction - player.genMat) > 0
                          ? (numProduction - player.genMat) * G.gamedata.matPrice
                          : 0;
  const playerProd =  player.genMat > 0
                      ? `自社調達により${player.genMat}個までの生産は無料です。`
                      : "";

  // const expire = G.gamedata.turn == 1 ? 240 : 120;
  const expire = 1200;

  return (
    <Grid container>
      <Grid item xs={9.5} sx={teppansx}>
        <Grid container spacing={1} sx={{
          height:"100%",
        }}>
          <Grid item xs={9}>
            <div style={{margin:"1rem 0"}}>
              <img src="image/gx/text_seisansu.png" style={{height:"3rem"}}/>
            </div>
            <Grid container sx={{margin:"3.5rem 1rem 0 1rem"}}>
              <Grid item xs={2}>
                <div style={hekomiText_white}>{G.gamedata.matPrice}</div>
              </Grid>
              <Grid item xs={1.5}>
                <img src="image/gx/text_x.png" style={{height:"4rem"}}/>
              </Grid>
              <Grid item xs={1.5}>
              <div style={hekomiText_white}>{isNaN(numProduction)?0:numProduction}</div>
              </Grid>
              <Grid item xs={1}>
                <img src="image/gx/text_minus.png" style={{height:"4rem"}}/>
              </Grid>
              <Grid item xs={1.5} sx={hekomiText_white}>
                <div style={hekomiText_white}>{player.genMat}</div>
              </Grid>
              <Grid item xs={1.5}>
                <img src="image/gx/text_equal.png" style={{height:"4rem"}}/>
              </Grid>
              <Grid item xs={2.5} sx={hekomiText_white}>
                <div style={hekomiText_white}>{productionCost}</div>
              </Grid>
            </Grid>
            <Grid container sx={{margin:"0.5rem 1rem"}}>
              <Grid item xs={2}>
                <img src="image/gx/text_zairyokakaku.png" style={{height:"1.5rem"}}/>
              </Grid>
              <Grid item xs={1.5}></Grid>
              <Grid item xs={1.5}>
                <img src="image/gx/text_seisans_s.png" style={{height:"1.5rem"}}/>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1.5}>
                <img src="image/gx/text_recycle.png" style={{margin:"0 0 0 -0.5rem",height:"1.5rem"}}/>
              </Grid>
              <Grid item xs={1.5}></Grid>
              <Grid item xs={2.5}>
                <img src="image/gx/text_cost.png" style={{height:"1.5rem"}}/>
              </Grid>
            </Grid>

            <div id="prodnum" style={{margin:"3rem"}}>
              <Slider
                aria-label="生産量"
                defaultValue={0}
                max={maxProduction}
                value={isNaN(numProduction)?0:numProduction}
                onChange={handleChange}
                valueLabelDisplay="on"
                disabled={ctx.activePlayers[playerID]==undefined}
                marks={true}
                sx={{
                  width:`${maxProduction * 50}px`,
                  //ボタン画像
                  "& .MuiSlider-thumb":{
                    width:"3rem",
                    height:"3rem",
                    backgroundImage: "url('image/gx/slider_button.png')",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  },
                  //線を黒く
                  "& .MuiSlider-rail":{
                    backgroundColor: "#bbbbbb",
                    border: "solid 1rem #dddddd",
                    borderRadius: "2rem",
                    outline: "solid 0.2rem #aaaaaa",
                    opacity: 1,
                    height: "1rem",
                    right: "-1rem",

                  },
                  //選択後の線を黒く
                  "& .MuiSlider-track":{
                    //枠を太くする
                    // borderLeft: "solid 1rem #dddddd",
                    backgroundColor: "#555555",
                    color: "#ffffff",
                    height: "1rem",
                  },
                }}
              />
            </div>

          </Grid>
          <Grid item xs={0.2} sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            
          }}>
            <div style={{height:"85%", width:"0.5rem", backgroundColor:"#444444",margin:"-1.5rem 0 0 0"}}></div>
          </Grid>
          <Grid item xs={2.8}>
            <div style={{margin:"1rem 0"}}>
              <img src="image/gx/text_juyou.png" style={{height:"3rem"}}/>
            </div>
            <Grid container sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}>
              <Grid item xs={4}>
                <img src="image/gx/text_p.png" style={{height:"3rem"}}/>
              </Grid>
              <Grid item xs={6} sx={{
                  ...hekomiText_white, 
                  border:"solid 0.4rem #bd494e",
                  margin:"0.5rem 0"
                }}>
                {G.gamedata.demandCost}
              </Grid>
              <Grid item xs={4}>
                <img src="image/gx/text_q.png" style={{height:"3rem"}}/>
              </Grid>
              <Grid item xs={6} sx={{
                  ...hekomiText_white, 
                  border:"solid 0.4rem #287db5",
                  margin:"0.5rem 0"
                }}>
                {G.gamedata.demandQuality}
              </Grid>
              <Grid item xs={4}>
                <img src="image/gx/text_g.png" style={{height:"3rem"}}/>
              </Grid>
              <Grid item xs={6} sx={{
                  ...hekomiText_white, 
                  border:"solid 0.4rem #29965e",
                  margin:"0.5rem 0"
                }}>
                {G.gamedata.demandGreen}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={2.5}>
        <div style={rightcardsx}>
          <TimerView expire={expire} onExpire={done}/><br/>
          {result["message"]}<br/><br/>
        <hr/>
        <Button onClick={done} disabled={ctx.activePlayers[playerID]==undefined} variant="contained" endIcon={<VerifiedIcon/>}>決定</Button>
        </div>
      </Grid>
    </Grid>
  );
};

const Trade = ({ ...props}) => {
  const { G, playerID, ctx, moves, matchData,settings } = props;

  const player = G.players[playerID];
  const [result, setResult] = React.useState({"complete":false,"message":"需要数を決定するサイコロを振ります"});

  // const localstep = React.useRef("initial");//"initial","dodice","donedice
  const [localstep, setLocalStep] = React.useState( "initial" );

  const [tradeInfoPrice, setTradeInfoPrice] = React.useState( "" );
  const [tradeInfoPQ, setTradeInfoPQ] = React.useState( "" );
  const [tradeInfoPQG, setTradeInfoPQG] = React.useState( "" );
  const [yourProfit, setYourProfit] = React.useState( "" );
  const [yourCost, setYourCost] = React.useState( "" );

  //音
  const [slotPlay] = useSound(slotSound,{volume:settings.vol});
  const [typingPlay] = useSound(typingSound,{volume:settings.vol});

  /* 状態制御 */
  const [titleMessage, setTitle] = React.useState("需要数変動ルーレット");
  React.useEffect(() => {
    
    if(ctx.step === "trade" && localstep === "donedice"){
      setLocalStep("trade");
      setTitle("取引結果！");
      return;
    }else if(ctx.step === "trade" && localstep === "trade"){
      clickOK();
      return;
    }
  });

  /* OKボタン押下時 */
  const clickOK = () => {

    //初期状態の場合サイコロを振る。サイコロコールバックでstep移行
    if(localstep === "initial"){ 
      setLocalStep("dodice");
      slotPlay();
      // スロットの終わりを2秒仮定で待ち状態更新
      setTimeout(()=>{
        setLocalStep("donedice");
      }, 2000);
    }else if(localstep === "donedice"){
      //スロット終了後の処理
      setTitle("他プレイヤーの決定を待っています");
      moves.done(result); //トレードへ
    
    //トレード結果表示
  // }else if(localstep === "trade_price"){
    }else if(localstep === "trade"){
      //音
      // typingPlay();

      var text = "";
      ctx.nowProfit[0].forEach((e, index) => {
        if(e > 0){
          text += `${matchData[index].name}さんが${e}を売却。`;
        }
      })
      if(text === ""){
        text += "売上なし";
      }
      setTradeInfoPrice(text)

    //   setLocalStep("trade_pq");
    // }else if(localstep === "trade_pq"){
      // 品質市場の売上
      text = "";
      ctx.nowProfit[1].forEach((e, index) => {
        if(e > 0){

          text += `${matchData[index].name}さんが${e}を売却。`;
        }
      })
      //誰も売り上げていない場合
      if(text === ""){
        text += "売上なし";
      }
      setTradeInfoPQ(text);

    //   setLocalStep("trade_pqg");
    // }else if(localstep === "trade_pqg"){
      // 環境市場の売上
      text = "";
      ctx.nowProfit[2].forEach((e, index) => {
        if(e > 0){

          text += `${matchData[index].name}さんが${e}を売却。`;
        }
      })
      //誰も売り上げていない場合
      if(text ===  ""){
        text += "売上なし";
      }
      setTradeInfoPQG(text);
      // 売上表示
      var sellnum=0;
      for(const a of ctx.nowProfit){
        sellnum += a[playerID]
      }
      var urenokoriText ="";
      if( player.stock > 0 ){
        urenokoriText = `売れ残った在庫${player.stock}個を1つ50で売却し、${player.stock*50}を売上げました。`;
      }

      setYourProfit(`あなたの売上個数は${sellnum}。売上金額は${sellnum * player.price}です。${urenokoriText}`);

      //playerの利子を計算
      const {interest, hensai} = calcInterest( player.financial, G.gamedata.turn, false);
      // 支払った燃料費
      const enCost = (player.energy - player.genPow ) * G.gamedata.powPrice;
      text="";
      if(enCost > 0){
        text += `\n燃料費${enCost}を支払います。`;
      }
      if(hensai > 0){
        text += ` \n借り入れ返済額${hensai+interest}を支払います。`;
      }
      setYourCost(text);

      setLocalStep("donetrade");
    }else if(localstep === "donetrade"){

      setTitle("他のプレイヤーを待っています");
      moves.done(result);
    }
    
  }

  /* style */
  const tablesx = {
    border:1,
  }

  /* 表示切り替え */
  const visibilityByStep = (step) => {
    
    if(step === ctx.step){
      return "active";
    }else{
      return "hidden";
    }
  }
  //const expire = G.gamedata.turn == 1 ? 120 : 60;
  const expire = 1200;

  return (
    <div>
      { ctx.step==="dice" && (
        <Grid container>
          <Grid item xs={9.5} sx={{
            ...teppansx, 
            backgroundImage:localstep=="dodice"?"url('image/gx/demand_slot2.png')":"url('image/gx/demand_slot.png')",
            //子要素を上下中央に
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
          }}>
            <Grid container>
              <Grid item xs={3.4}>
              </Grid>
              <Grid item xs={7}>
                <Grid container sx={{
                  backgroundImage:"url('image/gx/slot_window.png')",
                  backgroundSize:"contain",
                  backgroundRepeat:"no-repeat",
                  height:"20rem",
                }}>
                  {//ルーレット表示
                  [
                    {color:'rgb(255, 99, 132)', img:'p_default.png', textData: diceImage(dc(G),"p"), stopIndex:G.gamedata.dice.demandCost-1},
                    {color:'rgb(53, 162, 235)', img:'q_default.png', textData: diceImage(dq(G),"q"), stopIndex:G.gamedata.dice.demandQuality-1},
                    {color:'rgb(75, 192, 192)', img:'g_default.png', textData: diceImage(dg(G),"g"), stopIndex:G.gamedata.dice.demandGreen-1},
                  ].map((e, index)=>(
                    <Grid item key={index} xs={4} sx={{
                    }}>
                        {!(localstep==="initial")?<SlotMachine class="slot" slotclass="slotMachineText" key={`p${G.gamedata.turn}`} textData={e.textData} stopIndex={e.stopIndex} />
                          :
                          <img src={`image/gx/${e.img}`} style={{
                            width: '100%',
                            objectFit: 'contain',
                            objectPosition: 'center',                  
                          }}/>
                        }
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={1.6}>
                <div onClick={clickOK} style={{width:"100%",height:"100%",cursor:"pointer"}}></div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2.5}>
            <div style={rightcardsx}>
            {titleMessage}<br/><br/>
            {localstep==="initial" &&
              <React.Fragment>
                <TimerView expire={expire/3} onExpire={clickOK}/><br/>
                <Button onClick={clickOK} disabled={ctx.activePlayers[playerID]==undefined} variant="contained" endIcon={<NavigateNextIcon/>}>
                  スタート！
                </Button>
              </React.Fragment>
            }
            {localstep==="donedice" &&
              <React.Fragment>
                <TimerView expire={expire} onExpire={clickOK}/><br/>
                <Button onClick={clickOK} disabled={ctx.activePlayers[playerID]==undefined} variant="contained" endIcon={<NavigateNextIcon/>}>
                  OK
                </Button>
              </React.Fragment>
            }
            </div>
          </Grid>
        </Grid>
      )}{/* dice div */}
      { ctx.step==="trade" && (
        <div>
          <div style={hekomiText_black}>{titleMessage}</div><br/>
          <TradeModal {...props} onClose={clickOK}/>
            <React.Fragment>
              <Button onClick={clickOK} disabled={ctx.activePlayers[playerID]==undefined} variant="contained" endIcon={<NavigateNextIcon/>}>
                OK
              </Button>
            </React.Fragment>
        </div>
      )}{/* trade div */}
    </div>
  );
};

/**
 * イベントボード
 */
const EventBoard = ({ ...props }) => {
  const { G, playerID, ctx, moves, matchData,settings } = props;
  const [slotPlay] = useSound(slotSound,{volume:settings.vol});

  const [titleMessage, setTitle] = React.useState("イベント発生！！！");
  const [localstep, setLocalStep] = React.useState( "initial" );
  const [result, setResult] = React.useState({"complete":false,"message":""});


  /* OKボタン押下時 */
  const clickOK = () => {
    //初期状態の場合サイコロを振る。サイコロコールバックでstep移行
    if(localstep === "initial"){ 
      setLocalStep("dodice");
      slotPlay();
      // スロットの終わりを2秒仮定で待ち状態更新
      setTimeout(()=>{
        setLocalStep("donedice");
      }, 2000);
    }else if(localstep === "donedice"){
      //スロット終了後の処理
      setTitle("他プレイヤーの決定を待っています");
      moves.done(result);
    }
  }
  //const expire = G.gamedata.turn == 1 ? 180 : 60;
  const expire = 1200;

  return(
    <Grid container>
      <Grid item xs={9.5} sx={{
        ...teppansx, 
        backgroundImage:localstep=="dodice"?"url('image/gx/event_slot2.png')":"url('image/gx/event_slot.png')",
        //子要素を上下中央に
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        margin:"0 !important"
      }}>
        <Grid container>
          <Grid item xs={3.4}>
          </Grid>
          <Grid item xs={7}>
            <Grid container sx={{
              backgroundImage:"url('image/gx/slot_window.png')",
              backgroundSize:"contain",
              backgroundRepeat:"no-repeat",
              height:"20rem",
            }}>
              {//ルーレット表示
              [
                {color:'rgb(53, 162, 235)', img:'en_default.png', textData: diceEvent2Text, stopIndex:G.gamedata.dice.events2-1},
                {color:'rgb(255, 99, 132)', img:'mat_default.png', textData: diceEvent1Text, stopIndex:G.gamedata.dice.events1-1},
                {color:'rgb(75, 192, 192)', img:'event_default.png', textData: diceEvent3Text, stopIndex:G.gamedata.dice.events3-1},
              ].map((e, index)=>(
                <Grid item key={index} xs={4} sx={{
                }}>
                    {!(localstep==="initial")?
                        <SlotMachine class="slot" slotclass="slotMachineText" key={`p${G.gamedata.turn}`} textData={e.textData} stopIndex={e.stopIndex} />
                      :
                        <img src={`image/gx/${e.img}`} style={{
                            width: '100%',
                            objectFit: 'contain',
                            objectPosition: 'center',                  
                          }}/>
                      }
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={1.6}>
            <div onClick={clickOK} style={{width:"100%",height:"100%",cursor:"pointer"}}></div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2.5}>
        <div style={rightcardsx}>
        {localstep==="initial" &&
          <React.Fragment>
            <TimerView expire={expire/3} onExpire={clickOK}/><br/>
            {titleMessage}<br/><br/>
            <Button onClick={clickOK} disabled={ctx.activePlayers[playerID]==undefined} variant="contained" endIcon={<NavigateNextIcon/>}>
              スタート！
            </Button>
          </React.Fragment>
        }
        {localstep==="donedice" &&
          <React.Fragment>
            <TimerView expire={expire} onExpire={clickOK}/><br/>
            {titleMessage}<br/><br/>
            <Typography variant="h6" gutterBottom>
              {diceEvent3Desc[diceEvent3[G.gamedata.dice.events3-1]].title}
            </Typography><br/>
            <Typography gutterBottom>
            {diceEvent3Desc[diceEvent3[G.gamedata.dice.events3-1]].desc}
            </Typography><br/>
            <Button onClick={clickOK} disabled={ctx.activePlayers[playerID]==undefined} variant="contained" endIcon={<NavigateNextIcon/>}>
              OK
            </Button>
          </React.Fragment>
        }
        </div>
      </Grid>
    </Grid>
  )
}

export {GXActionBoard, SelectAction,SelectPrice};