/*TODO: ResultModalを使ったものにすべきだが、独自コードになってしまっている */

import * as React from 'react';
import { 
    Backdrop,
    Modal,
    Fade,
    Slide,
    Button,
    Typography,
    Box,
    Avatar,
} from '@mui/material';
import CountUp from 'react-countup';
import useSound from 'use-sound';
import SceneSound from '../sound/taiko.mp3';
import UreSound from '../sound/ure.mp3';
import { Grid, Zoom } from '@mui/material';

import { TransitionGroup } from 'react-transition-group';

import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import zIndex from '@mui/material/styles/zIndex';
import { getPlayerName } from '../util';
import { calcInterest } from '../data/Financial';
const style = {
  // position: 'fixed',
  top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  // width: '600',
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
  textAlign: 'center',
//   zIndex: 1000,

};
const modalstyle = {
}
function TradeModal({ ...props}) {
  const { G, playerID, ctx, moves, matchData, playerName, settings,onClose} = props;
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") 
       return;
    setOpen(false);
    if(onClose){onClose();}
  }
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        // onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
            backdrop: {
              timeout: 500,
              sx:{
                backdropFilter: 'brightness(70%) blur(10px)',
              }
            },
          }}
        style={{display:'flex',alignItems:'center',justifyContent:'center'}}
      >
        <Slide direction="down" in={open} mountOnEnter unmountOnExit>
            <div style={{zIndex:1000}}>
            <TradeComponent {...props} onClose={handleClose}/>
            </div>
        </Slide>
      </Modal>
    </div>
  );
}

function TradeComponent({ ...props}) {
    const { G, playerID, ctx, moves, matchData, playerName, settings, onClose} = props;
    const [isHover, setIsHover] = React.useState(false);

    const [step, setStep] = React.useState(0);//0:初期フェーズ 1:P, 2:Q, 3:G, 4:売れ残り 5:売上　6:支出

    const [playTaiko] = useSound(SceneSound, {volume:settings.vol*0.5});

    const shijou = ["P市場","P+Q市場","P+Q+G市場"];
    const rootsx={
        width: "80vw",
        maxWidth: `${1600*0.8}px`,
        aspectRatio: "16/8",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        padding: "2rem 0",
        border: "solid 2px #fff",
        borderRadius: "20px",
    }
    const headersx={
        backgroundImage: "url(image/gx/result_town.png)",
        backgroundSize: "contain",
        aspectRatio: "16/1.5",
        display: "flex",
        //真ん中寄せ
        justifyContent: "center",
        // paddingLeft: "7vw",
    }
    const demandsx={
        width: "20vw",
        fontSize: "3rem",
        fontWeight: "bold",
        color: "white",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        //左右中央
        alignItems: "center",
    }
    const resultsx={
        backgroundImage: step <=4?"url(image/gx/result_player_bg.png)":"url(image/gx/result_player_bg_noline.png)",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        width: "85%",
        aspectRatio: "1359/163",
        margin: "0.2rem auto",
        display: "inline-flex",
    }
    const namesx={
        textAlign: "center",
        fontSize: "3rem",
        fontWeight: "bold",
        color: "white",
    }
    const uresx={
        textAlign: "left",
    }

    const imagesx={
        width: "3rem",
    }
    const shishutusx={
        padding: "0 2rem",
    }
    const shishutunamesx={
        ...namesx,
        fontSize: "4rem",
    }

    const shishutu_childsx={
        aspectRatio: "10/5",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        padding: "2rem 0",
        border: "solid 2px #fff",
        borderRadius: "20px",
        // backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        //文字を上下左右中央
        // display: "flex",
        // flexDirection: "column",
        // justifyContent: "center",
        // alignItems: "center",
        fontSize: "10rem",
        fontWeight: "bold",
        color: "white",

    }
    const encostsx={
        ...shishutu_childsx,
        backgroundImage: "url(image/gx/icon_elect.png)",
    }
    const hensaisx={
        ...shishutu_childsx,
        backgroundImage: "url(image/gx/icon_repayment.png)",
    }

    const [open, setOpen] = React.useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        if(onClose){onClose();}
    }
    const next = () => {
        playTaiko();
        if(step==6){
            handleClose();
        }else{
            setStep(step+1);
        }
    }


    // 最初に生産した製品数を受け渡せていないので再計算にて対応する
    const numProd= (ctx, player) =>{
        var sellnum=0;
        for(const a of ctx.nowProfit){
          sellnum += a[player.id]
        }
        return sellnum + player.stock;
    }
    const demand = [
        0,
        G.gamedata.demandCost,
        G.gamedata.demandQuality,
        G.gamedata.demandGreen,
    ]
    const personsx = [
        {fontSize: "3rem", color: 'rgb(255, 99, 132)'},
        {fontSize: "3rem", color: 'rgb(53, 162, 235)'},
        {fontSize: "3rem", color: 'rgb(75, 192, 192)'},
        {fontSize: "3rem", color: 'rgb(128, 128, 128)'},
    ]
    const headerimage=[
        "",
        "result_title_p.png",
        "result_title_q.png",
        "result_title_g.png",
        "result_title_sale.png",
        "result_title_earnings.png",
        "result_title_shiharai.png",
    ]
    const demandimage=[
        "",
        "result_count_p.png",
        "result_count_q.png",
        "result_count_g.png",
        "sale_50.png",

    ];

    // const ease={"enter":"cubic-bezier(0.5, 0, 0.75, 0)","exit":"cubic-bezier(0.5, 0, 0.75, 0)"}
    //elastic ease
    const ease={"enter":"cubic-bezier(0.175, 10.885, 0.32, 1.275)","exit":"cubic-bezier(0.175, 1.885, 0.32, 1.275)"} 

    //プレイヤー毎売上情報
    var tradeinfo=[];
    G.players.map((player, index) => {
        //売り上げ数
        var sellnum=0;
        for(const a of ctx.nowProfit){
            sellnum += a[player.id]
        }
        const {interest, hensai} = calcInterest( player.financial, G.gamedata.turn, false);
        var enCost = (player.energy - player.genPow ) * G.gamedata.powPrice;
        enCost = enCost < 0 ? 0 : enCost;

        tradeinfo[player.id] = {
            sellnum: sellnum,
            sellprice: sellnum * player.price,
            stock: player.stock,
            stockprice: player.stock * 50,
            selltotal: sellnum * player.price + player.stock * 50,
            interest: interest,
            hensai: hensai,
            hensaitotal: interest + hensai,
            enCost: enCost,
        };
    })

    return (
        <Box sx={style}>
            <div style={headersx}>
                { step>0 &&
                    <Zoom in={true} key={step} timeout={200} easing={ease}>
                        <img src={"image/gx/"+headerimage[step]}
                            style={{
                                //親におさまるように
                                width: "50rem",
                                height: "100%",
                                objectFit: "contain",
                            }}/>
                    </Zoom>
                }                
                { step <=4 &&
                <React.Fragment>
                    　　　　　　
                    <div style={{...demandsx, backgroundImage:`url(image/gx/${demandimage[step]})`}}>
                        　　{step<=3 && demand[step]}
                    </div>
                </React.Fragment>
                }
            </div>
            <div style={rootsx}>
                { step==0 &&
                    <div style={{
                            height:"80%",
                            textAlign: "center", 
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                        }}>
                        <img style={{width:"100%"}} src={"image/gx/title_product_sales.png"}/>
                    </div>
                }
                { step > 0 && step < 6 &&
                    G.players.map((player, index) => (
                        <Grid container key={index} spacing={2}  style={resultsx}>
                            <Grid item xs={3.2} sx={namesx}>
                                {getPlayerName(player.id, matchData)}
                            </Grid>
                            <Grid item xs={8.8} sx={uresx}>
                                {step < 5 && numProd(ctx, player) >=1 &&
                                    <React.Fragment>
                                        <img src='image/gx/transparent.png' style={imagesx}/>
                                        {
                                            [...Array(numProd(ctx, player))].map((_, i) => (
                                                <img src={"image/gx/icon_box.png"} style={imagesx}/>
                                            ))
                                        }
                                        <br/>
                                        <img src='image/gx/transparent.png' style={imagesx}/>
                                    </React.Fragment>
                                }
                                {step>=1 && step < 5 && ctx.nowProfit[0][player.id] >=1 &&
                                    [...Array(ctx.nowProfit[0][player.id])].map((_, i) => (
                                        <Zoom in={true}  timeout={(i+1)*200} easing={ease} >
                                             <PersonIcon key={i} sx={personsx[0]}/>
                                        </Zoom>
                                    ))}
                                {step>=2 && step < 5 && ctx.nowProfit[1][player.id] >=1 &&
                                    [...Array(ctx.nowProfit[1][player.id])].map((_, i) => (
                                        <Zoom in={true}  timeout={(i+1)*200} easing={ease}  >
                                            <PersonIcon key={i} sx={personsx[1]}/>
                                        </Zoom>
                                    ))}
                                {step>=3 && step < 5 && ctx.nowProfit[2][player.id] >=1 &&
                                    [...Array(ctx.nowProfit[2][player.id])].map((_, i) => (
                                        <Zoom in={true}  timeout={(i+1)*200} easing={ease}  >
                                            <PersonIcon key={i} sx={personsx[2]}/>
                                        </Zoom>
                                    ))}
                                {step>=4 && step < 5 && player.stock >=1 &&
                                    [...Array(player.stock)].map((_, i) => (
                                        <Zoom in={true}  timeout={(i+1)*200} easing={ease}  >
                                            <PersonOffIcon key={i} sx={personsx[3]}/>
                                        </Zoom>

                                    ))
                                }
                                {step==5 &&
                                    <div style={namesx}>
                                        <CountUp end={tradeinfo[player.id].selltotal} duration={2}/>
                                    </div>
                                }
                                <br/>
                            </Grid>
                        </Grid>
                    ))
                }
                { step==6 &&
                    <Grid container spacing={4}  style={shishutusx}>
                        <Grid item xs={6} sx={shishutunamesx}>
                            <div>
                                電気代
                            </div>
                            <div style={encostsx}>
                                <CountUp end={tradeinfo[playerID].enCost} duration={2}/>
                            </div>
                        </Grid>
                        <Grid item xs={6} sx={shishutunamesx}>
                            <div>
                                借入返済
                            </div>
                            <div style={hensaisx}>
                                <CountUp end={tradeinfo[playerID].hensaitotal} duration={2}/>
                            </div>
                        </Grid>
                    </Grid>
                }
                <div>
                <Button onClick={()=>next()} color='success' >  
                    <img style={{width:"15rem"}}src='image/gx/button_next.png'/>
                </Button>
                </div>
            </div>
        </Box>

    );
}



export {TradeModal};