// client.js
import React,{useEffect} from 'react';
import { Client } from 'boardgame.io/react';
import { SocketIO } from 'boardgame.io/multiplayer'
// import { LobbyClient } from 'boardgame.io/client';

import { GXActionBoard } from './view/GXActionBoard';
import { GXFieldBoard } from './view/GXFieldBoard';
import { GXPlayerBoard,GXPlayerBoardDrawer } from './view/GXPlayerBoard';
import { PLDrawer } from './view/PL';
import { BSDrawer } from './view/BS';
import { CFDrawer } from './view/CF';
import { AnalyzeDrawer } from './view/FinancialAnalyze';
import { ActionLog, ActionLogDrawer } from './view/ActionLog';
import {ManualDrawer} from './view/Manual';
import { PlayerDataList } from './view/PlayerDataList';
import GXModal from './view/GXModal';
import { ChatView } from './view/ChatView';

import './view/css/board.css';

import {
  Grid,
  Card,
  CardContent,
  Zoom,
  Slider,
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
  
import { GAME_SERVER } from '../setting';

import useSound from 'use-sound';
import bgm from './sound/bgm.mp3';
import { Chat } from '@mui/icons-material';


export const GXBoard = ({ ...props }) => {
  const { G, playerID, ctx, moves, matchData, playerName, sendChatMessage } = props;
  
  const mainsx = {
    height: "100%",
  }

  const upperboardsx = {
    height: "45%",
  }

  const playerboardsx = {
    height: "100%",
  }
  const fieldboardsx = {
    height: "100%",
  }
  const fieldcardsx={
    height: "100%",
    overflow: 'auto',
    backgroundImage: "url(image/gx/field_bg.png)",
    backgroundSize: "contain",
  }

  const actionboardsx = {
    height: "50%",
  }

  const cardsx1 = {
    height: "100%",
    overflow: 'auto',
  }
  const playercardsx = {
    ...cardsx1,
    backgroundImage: "url(image/gx/player_bg.png)",
    backgroundSize: "cover",
  }
  const actioncardsx = {
    ...cardsx1,
    // backgroundImage: "url('image/gx/belt_machine_bg.png')",
    // backgroundSize: "contain",
  }
  const titlesx={
    textAlign: "center",
    fontSize: "1.5rem",
    fontWeight: "bold",
    textDecoration:"underline double lightgreen",
  }

  const [start, setStart] = React.useState(false);

  const [settings, setSettings] = React.useState({vol:G.env.volume});

  const [welcome1, setWelcome1] = React.useState(false);
  const [welcome2, setWelcome2] = React.useState(false);



  const volumeChange = (event, newValue) => {
    setSettings({ ...settings, vol: newValue });
  };

  //ループするbgm
  const [playBGM,{ stop: stopBGM }] = useSound(bgm,{
    volume:settings.vol*0.025,
    loop:true,
    interrupt: true,
  });
  //部屋から退室したときにBGMを停止
  useEffect(() => {
    return () => {
      console.log("★STOPBGM")
      stopBGM();
    };
  }, [stopBGM]);

  if(matchData[0]["name"]==undefined){
    matchData[0]["name"] = "あなた";
    matchData[1]["name"] = "AI Player";

  }

  const player = G.players[playerID];

  // プレイヤー数分のボードを作成
  const otherPlayerBoardList = [];
  const selfPlayerBoard = [];
  for (const player of G.players) {
    var opponentName ="Player";

    if(player.id == playerID){ // 自分のボードを作成
      opponentName = playerName//+"(あなた)";
      selfPlayerBoard.push(
          <Card sx={playercardsx} raised={true} >
              <div id="playerboard" style={{height:"100%"}}>
              <GXPlayerBoard {...props} className="selfPlayerBoard" key={player.id} player={player} playerName={opponentName}/>
              </div>
          </Card>
      )
    }
  }

  function handleSplashClose(){
    playBGM(); //BGM再生
    setStart(true);
  }

  return (
    <div style={{
      //画面全体に黒背景、子要素は上下左右中央
      backgroundColor: '#000000',
      height: '100%',
      width: '100%',
      // position: 'absolute',
      // top: 0,
      // left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // overflow: 'scroll',
      }}>
    <div boarder="2" className='boardroot'>
      <Grid container sx={{ height:"100%", color: 'white', backgroundColor: '#849056'}}>
        {!start &&
          <div>
            <GXModal {...props} onClose={()=>setWelcome1(true)}
              component={<img src="/image/gx/catch.webp" width="600"></img>}/>
            
            {welcome1 &&
              <GXModal {...props}  onClose={()=>setWelcome2(true)} component={
                <Paper>
                  <h1>マーケティングファクトリーへようこそ！！</h1>
                  <br/>
                  このゲームは投資・製造・販売を繰り返し<span style={{
                    color:"green",
                    fontSize:"1.5rem",
                    fontWeight:"bold"
                  }}>利益の最大化</span>を目指すゲームです。<br/>
                  <img src="/image/gx/inst1.png" width="400" /><br/>
                </Paper>
              }/>
              }
            {welcome2 &&
              <GXModal {...props}  onClose={handleSplashClose} component={
                <Paper>
                  <Grid container>
                    <Grid item xs={6}>
                      <h2 style={titlesx}>①投資</h2>
                      製品を生産するための設備に投資を行います。まずは生産設備へ投資しましょう。<br/>
                      また資金を増やすための借り入れや、変動費や固定費を下げるための設備や<br/>
                      競合と差別化するための会社の魅力度をあげるための投資もあります。<br/>
                      アクションを最大二つまで選択し、投資を行いましょう。<br/>
                      <img src="/image/gx/inst2.png" width="300" />
                    </Grid>
                    <Grid item xs={6}>
                      <h2 style={titlesx}>②生産</h2>
                      生産数を決定し、製品を生産します。<br/>
                      生産には原材料の購入を行う必要があります。<br/>
                      自社の財務状況と市場の需要を考慮し、生産数を決定しましょう。<br/>
                      <img src="/image/gx/inst3.png" width="300" />
                    </Grid>
                    <Grid item xs={6}>
                      <h2 style={titlesx}>③販売価格の決定</h2>
                      製品の販売価格を決定します。<br/>
                      製品の販売価格をあげることで利益を増やすことができますが、<br/>
                      製品の価格魅力度が減少します。<br/>
                      競合の状況や市場の需要を考慮し、販売価格を決定しましょう。<br/>
                      <img src="/image/gx/inst4.png" width="300" />
                    </Grid>
                    <Grid item xs={6}>
                      <h2 style={titlesx}>販売</h2>
                      製品を販売します。<br/>
                      販売数は自社の魅力度と市場の需要によって決定されます。<br/>
                      市場は価格に魅力を感じる顧客、品質に魅力を感じる顧客、環境に魅力を感じる顧客がいます。<br/>
                      自社と競合の魅力度と市場の需要を考慮し市場にあわせた差別化を行っていくことが重要です。<br/>
                      <img src="/image/gx/inst5.png" width="300" />
                    </Grid>
                  </Grid>
                </Paper>
              }/>
              }

          </div>
        }


        {/* メインのグリッド */}
        <Grid item xs={10.5} sx={mainsx}>
          <Grid container spacing={1} sx={upperboardsx}>
            <Grid item xs={6} sx={playerboardsx}>
              {selfPlayerBoard[0]}
            </Grid>

            <Grid item xs={6}  sx={fieldboardsx}>
              <Card sx={fieldcardsx} raised={true}>
                <CardContent>
                  <div id="fieldboard">
                  <GXFieldBoard G={G} matchData={matchData}/>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <br/>
            <div style={actionboardsx}>
              {/* <Card sx={actioncardsx} raised={true}>
                <CardContent> */}
                { start && playerID != null &&
                  <GXActionBoard
                    key={playerID}
                    className="actionBoard"
                    G={G}
                    ctx={ctx}
                    moves={moves}
                    playerID={playerID}
                    matchData={matchData}
                    settings={settings}
                    playerName={playerName}
                    />
                  }
                {playerID == null &&
                  <div>
                    <h2>観戦者モード</h2>
                    <PlayerDataList {...props}/>
                  </div>
                }
                {/* </CardContent>
              </Card> */}
            </div>
        </Grid>


        {/* サイドバー */}
        <Grid item xs={1.5} sx={{backgroundColor:"#444444"}}>
          <div id="sidebar" style={{margin:"1rem"}}>
            <ManualDrawer {...props} settings={settings}></ManualDrawer>
            <br/> <br/>
            { playerID != null &&
              <div>
                <div id="playerinfo">
                <GXPlayerBoardDrawer {...props}/><br/>
                </div>
                <br/>
                { G.gamedata.turn == 1 ?
                    <div>まだ財務情報がありません</div>
                  :
                  <Zoom  in={true} timeout={1000}>
                    <div id="finance">
                      <div id="pl"><PLDrawer {...props} settings={settings}/></div>
                      <div id="bs"><BSDrawer {...props} settings={settings}/></div>
                      <div id="cf"><CFDrawer {...props} settings={settings}/></div>
                      <div id="anal"><AnalyzeDrawer {...props} settings={settings}/></div>
                    </div>
                  </Zoom>
                }
              </div>
            }
              <br/>
            <ActionLogDrawer {...props}/><br/>
            <br/>
            音量
            <Slider aria-label="Volume" max={0.2}   step={0.001} value={settings.vol} onChange={volumeChange} />
            <br/>
            チャット
            <List sx={{
              width: '100%',
              maxWidth: 360,
              bgcolor: 'background.paper',
              color: 'black',
              }}>
              {
                [{message:"よろしく！", img:"emo_yoro.png"},
                 {message:"ちょっとまって", img:"emo_matte.png"},
                 {message:"やったー！", img:"emo_yatta.png"},
                 {message:"ガーン・・・", img:"emo_ga-n.png"},
                 {message:"すごい！", img:"emo_sugoi.png"},
                 {message:"値段下げようかな", img:"emo_sage.png"},
                 {message:"値段あげよう",img:"emo_age.png"}].map((item,idx) => (
                  <ListItem disablePadding key={idx}>
                    <ListItemButton sx={{padding:"1px"}} onClick={()=>sendChatMessage({message:item.message,img:item.img,time:Date.now()})}>
                      <ListItemIcon sx={{minWidth:"1rem"}}>
                        <Chat />
                      </ListItemIcon>
                      <ListItemText primary={item.message} />
                    </ListItemButton>
                  </ListItem>
                ))
              }
            </List>
          </div>
        </Grid>
      </Grid>
      {/* chat */}
      <ChatView {...props}/>
    </div>
    </div>
  );

};

// const GXClient = Client({
//   game: GX,
//   board: GXBoard,
//   numPlayers: 4,
//   multiplayer: SocketIO({ server: GAME_SERVER }),
// });

// export { GXClient };
