// client.js
import React from 'react';
import { Client } from 'boardgame.io/react';
import { Sugoroku } from './SugorokuGame'; // Import the game from game.js
import { SocketIO } from 'boardgame.io/multiplayer'

export const SugorokuBoard = ({ G, playerID, ctx, moves, matchID, playerName }) => {
  console.log(playerName);

  const player = G.players[playerID];
  const roll = () => moves.roll();
  const doEvent = () => moves.doEvent();
  const confirm = () => {
    moves.confirm();
  };
  return (
    <div>
      <h1>Sugoroku Game</h1>
      <h2>you are player {playerID}</h2>
      <h2>Current Player: {ctx.currentPlayer}</h2>
      <h3>Position: {player.position}</h3>
      <h3>Money: {player.money}</h3>
      {ctx.currentPlayer === playerID && player.step==="dice" && (
        <button onClick={roll}>サイコロを振る</button>
      )}
      {ctx.currentPlayer === playerID && player.step==="event" && (
        <button onClick={doEvent}>イベント</button>
      )}
      {ctx.currentPlayer === playerID && player.step==="confirm" && (
        <div>
            <div>{G.squares[player.position].summary}</div>
            <div>{G.squares[player.position].desc}</div>
           <button onClick={confirm}>ターン終了</button>
        </div>
      )}
    </div>
  );
};

const SugorokuClient = Client({
  game: Sugoroku,
  board: SugorokuBoard,
  numPlayers: 4,
  multiplayer: SocketIO({ server: 'localhost:8000' }),
});

export { SugorokuClient };
