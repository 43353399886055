// client.js
import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import CountUp from 'react-countup';
import PersonIcon from '@mui/icons-material/Person';
import Zoom  from '@mui/material/Zoom';

import {greenAttraction, priceAttraction, qualityAttraction, calcAttraction} from '../data/AttractionTable';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';


import './css/trade-table.css'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export const GXFieldBoard = ({ G, matchData }) => {
  const fieldrootsx={
    height: "100%",
  }

  const paramsx={
    height: "50%",
  }

  const bgblack={
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  }
  const upperfield={
    width: "100%",
    aspectRatio : "16/2",
    padding: "4px 0",
  }
  const graphsx={
    ...bgblack,
    aspectRatio : "16/5.2",
  }

  const fontstyle = {
    fontSize: '2rem',
    textAlign: 'center',
    lineHeight: '0.95em',
    fontWeight: 'bold',
    color: 'white',
  };

  const pricesx = {
    ...bgblack,
    aspectRatio : "5/6",
    // 中央下ぞろえ
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // padding: "0 0 1vw 0",
    backgroundSize: "cover",
  }
  const matpricesx = {
    ...pricesx,
    backgroundImage: "url(image/gx/mat_price.png)",
    backgroundSize: "contain",
  }
  const powpricesx = {
    ...pricesx,
    backgroundImage: "url(image/gx/pow_price.png)",
    backgroundSize: "contain",

  }
  const demandsx = {
    ...bgblack,
    aspectRatio : "8/1.8",
  }
  const fontsx={
    backgroundColor: 'transparent',
    padding: "0.2vw",
  }
  const girdsx = {
  }

  var Pdata=[];
  var PQdata=[];
  var PQGdata=[];
  var playerLabels=[];
  for(const p of G.players){
    var playername = "Player";
    for( const m of matchData){
      if(m.id == p.id){
        playername = m.name;
        break;
      }
    }

    // playerLabels.push("Player"+(p.id+1));
    playerLabels.push(playername);
    const attraction = calcAttraction(p);
    Pdata.push(attraction.price);
    PQdata.push(attraction.quality);
    PQGdata.push(attraction.green);


  }



  const data = {
    color: 'white',
    labels: playerLabels,
    datasets: [
      {
        label: 'P',
        data: Pdata,
        backgroundColor: 'rgb(255, 99, 132)',
      },
      {
        label: 'Q',
        data: PQdata,
        backgroundColor:  'rgb(53, 162, 235)',
      },
      {
        label: 'G',
        data: PQGdata,
        backgroundColor:'rgb(75, 192, 192)',
      },
    ],
  };
  const options = {
    //全体のフォント色を白に
    color: 'white',
    indexAxis: 'y',
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: 'white',
        font:{
          family:'DotGothic16',
          size: 16,
        } 
      },
    },
    responsive: true,
    scales: {
      x: {
        display: false,
        stacked: true,
        max:50,
      },
      y: {
        stacked: true,
        ticks: {
          color: 'white',
          font:{
            family:'DotGothic16',
            size: 16,
          }
        },
      },
    },
  };
  

  const ease={"enter":"cubic-bezier(0.175, 10.885, 0.32, 1.275)","exit":"cubic-bezier(0.175, 1.885, 0.32, 1.275)"} 


  return (
    <div style={fieldrootsx}>
      <Grid container spacing={1} sx={upperfield}>
        <Grid item xs={1.8} sx={girdsx}>
          <Paper variant="outlined" sx={powpricesx}>
            <CountUp end={G.gamedata.powPrice} duration={1.5} style={fontstyle}/>
          </Paper>
        </Grid>
        <Grid item xs={1.8} sx={girdsx}>
          <Paper variant="outlined" sx={matpricesx}>
            <CountUp end={G.gamedata.matPrice} duration={1.5} style={fontstyle}/>
          </Paper>
        </Grid>
          {/* <table class="trade-table">
            {
              [
                ["原材料価格", G.gamedata.matPrice],
                ["燃料価格", G.gamedata.powPrice],
              ].map((output, index)=>(
              <tr class="trade-row">
                <th class="trade-header">{output[0]}</th>
                <td class="price-data"><CountUp end={output[1]} duration={1.5}/></td>
              </tr>
            ))}
          </table>
        </Grid> */}
        <Grid item xs={8.4}>
          <Paper variant="outlined" sx={demandsx}>

          <table id="demand">
            {
              [
                ["価格需要", G.gamedata.demandCost, 'rgb(255, 99, 132)'],
                ["品質需要", G.gamedata.demandQuality, 'rgb(53, 162, 235)'],
                ["環境需要", G.gamedata.demandGreen,'rgb(75, 192, 192)'],
              ].map((output, index)=>(
              <tr>
                <th>
                  <Paper variant="outlined" sx={{...fontsx, color: output[2], borderColor: output[2]}}>
                    {output[0]}
                  </Paper>
                </th>
                <td>
                  {
                    output[1] > 0 &&
                      [...Array(output[1])].map((_, i) => (
                        <Zoom  in={true} timeout={1000} easing={ease}>
                          <PersonIcon key={i} sx={{color:output[2]}}/>
                        </Zoom >
                      ))
                  }
                </td>
              </tr>
            ))}
          </table>
          </Paper>
        </Grid>
      </Grid>
      <div style={graphsx}>
        <Bar options={options} data={data}/>
      </div>
    </div>
  );
};