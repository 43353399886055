import { 
    Grid,
    Button,
    Collapse
 } from '@mui/material';
import * as React from 'react';
import {
    Typography, Paper
} from '@mui/material';
import { PLDrawer } from './PL';
import { BSDrawer } from './BS';
import { CFDrawer } from './CF';
import { AnalyzeDrawer } from './FinancialAnalyze';
import { GXPlayerBoardDrawer } from './GXPlayerBoard';
import { ActionLogDrawer } from './ActionLog';
import { getPlayerColor } from '../util';
import { ResultModal } from './ResultModal';
import { AllPlayerNetAssetsChart } from './FinancialAnalyze';

function PlayerDataList({...props}) {
    const { G, playerID, ctx, moves, matchData, playerName } = props;
    const [state, setState] = React.useState({});
    const [open, setOpen] = React.useState(false);

    var textsx = {
        color: "#FFF",
        fontSize: "1.5rem",
        fontWeight: "bold",
        bordeRadius: "1px",
        textShadow: "1px 6px 5px rgba( 0, 0, 0, 0.8)",
        boxShadow: "inset 1px 3px 6px rgba( 0, 0, 0, 0.9)",
        backgroundColor: "#333333",
        padding: "0.5rem",
        textAlign: "center",
    }
    var playerNames = [];
    for( const p of G.players){
        for( const m of matchData){
            if(m.id == p.id){
                playerNames[p.id]=m.name;
                break;
            }
        }
    }
    const toggleChart = () => {
        setOpen(!open);
    }

    return (
        <div>
            <GXPlayerBoardDrawer {...props}/>
            <ActionLogDrawer {...props}/><br/><br/>
            <Button onClick={toggleChart}  variant="outlined" sx={{backgroundColor:"white",width:"100%"}}>純資産推移</Button>
            <Collapse in={open} sx={{backgroundColor:"rgba(0,0,0,0.5)"}}>
                <AllPlayerNetAssetsChart {...props}/>
            </Collapse>
            <Grid container spacing={2}>
            {
                G.players.map((player, index) => {
                    return (
                        <Grid item xs={3}>
                            <Typography variant="h6" gutterBottom sx={{
                                ...textsx,
                                color: `${getPlayerColor(player.id)}`,
                                }}>
                                {playerNames[player.id]}
                            </Typography>
                            <PLDrawer {...props} playerID={player.id}/>
                            <BSDrawer {...props} playerID={player.id}/>
                            <CFDrawer {...props} playerID={player.id}/>
                            <AnalyzeDrawer {...props} playerID={player.id}/>
                        </Grid>
                    )
                })
            }
            </Grid>
        </div>
    );
}
export {PlayerDataList};