import React from "react";
import { useTimer } from "react-timer-hook";


function TimerView({ expire, onExpire } ){
  const time = new Date();
  time.setSeconds(time.getSeconds() + expire);
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp:time,
    onExpire: () => {
        console.warn('onExpire called');
        onExpire();
        }
        
  });

  return (
      <span>
        {days > 0 && <span>{days}日</span>}
        {hours > 0 && <span>{hours}時間</span>}
        {minutes > 0 && <span>{minutes}分</span>}
        <span>{seconds}秒</span>
        {/* <p>{isRunning ? "Running" : "Not running"}</p>
        <button onClick={start}>Start</button>
        <button onClick={pause}>Pause</button>
        <button onClick={resume}>Resume</button>
        <button
          onClick={() => {
            // Restarts to 5 minutes timer
            const time = new Date();
            time.setSeconds(time.getSeconds() + 300);
            restart(time as unknown as number);
          }}
        >
          Restart
        </button> */}
      </span>
  );
}

export { TimerView };