/**
    name:"きかい１", //名前
    price:400, //値段
    machine:true, //設備かどうか
    energy:2, //必要電力
    prod:2, //生産量
    quality:0, //即時品質改善効果
    gx:-1, //即時GX値改善効果
    genP:0, //発電量
    genM:0 //原材料生産量

 */

const IMAGE_DIR = "/image/gx/";
/*正規表現メモ
(.+),(.+),(.+),(.+),(.+),(.+),(.+),(.+),(.+),(.+),(.+),(.+),(.+),(.+)
    "\1":{\n        kind:"\2",\n        name:"\3",\n        price:\4,\n        machine:\5,\n        energy:\6,\n        prod:\7,\n        quality:\8,\n        gx:\9,\n        genP:\10,\n        genM:\11,\n        image:\12,\n        thumb:\13,\n        resultimage:\14,\n    },
*/
const action_kind = {
    "1":{
        kind:"production",
        name:"通常機械",
        price:400,
        machine:true,
        energy:3,
        prod:2,
        quality:0,
        gx:-1,
        genP:0,
        genM:0,
        image:IMAGE_DIR+"prod1.png",
        thumb:IMAGE_DIR+"prod1_thumb.png",
        resultimg:IMAGE_DIR+"prod1_result.png",
    },
    "2":{
        kind:"production",
        name:"大型機械",
        price:700,
        machine:true,
        energy:5,
        prod:4,
        quality:0,
        gx:-3,
        genP:0,
        genM:0,
        image:IMAGE_DIR+"prod2.png",
        thumb:IMAGE_DIR+"prod2_thumb.png",
        resultimg:IMAGE_DIR+"prod2_result.png",
    },
    "3":{
        kind:"production",
        name:"GX機械",
        price:550,
        machine:true,
        energy:2,
        prod:2,
        quality:0,
        gx:0,
        genP:0,
        genM:0,
        image:IMAGE_DIR+"prodgx1.png",
        thumb:IMAGE_DIR+"prodgx1_thumb.png",
        resultimg:IMAGE_DIR+"prodgx1_result.png",
    },
    "4":{
        kind:"production",
        name:"大型GX機械",
        price:900,
        machine:true,
        energy:3,
        prod:3,
        quality:0,
        gx:1,
        genP:0,
        genM:0,
        image:IMAGE_DIR+"prodgx2.png",
        thumb:IMAGE_DIR+"prodgx2_thumb.png",
        resultimg:IMAGE_DIR+"prodgx2_result.png",
    },
    "5":{
        kind:"generator",
        name:"ソーラーパネル",
        price:300,
        machine:true,
        energy:0,
        prod:0,
        quality:0,
        gx:1,
        genP:3,
        genM:0,
        image:IMAGE_DIR+"genen1.png",
        thumb:IMAGE_DIR+"genen1_thumb.png",
        resultimg:IMAGE_DIR+"genen1_result.png",
    },
    "6":{
        kind:"generator",
        name:"水素発電機",
        price:500,
        machine:true,
        energy:0,
        prod:0,
        quality:0,
        gx:2,
        genP:6,
        genM:0,
        image:IMAGE_DIR+"genen2.png",
        thumb:IMAGE_DIR+"genen2_thumb.png",
        resultimg:IMAGE_DIR+"genen2_result.png",
    },
    "7":{
        kind:"generator",
        name:"リサイクル装置",
        price:400,
        machine:true,
        energy:0,
        prod:0,
        quality:0,
        gx:2,
        genP:0,
        genM:1,
        image:IMAGE_DIR+"genmat1.png",
        thumb:IMAGE_DIR+"genmat1_thumb.png",
        resultimg:IMAGE_DIR+"genmat1_result.png",
    },
    "8":{
        kind:"generator",
        name:"大型リサイクル装置",
        price:700,
        machine:true,
        energy:0,
        prod:0,
        quality:0,
        gx:3,
        genP:0,
        genM:2,
        image:IMAGE_DIR+"genmat2.png",
        thumb:IMAGE_DIR+"genmat2_thumb.png",
        resultimg:IMAGE_DIR+"genmat2_result.png",
    },
    "9":{
        kind:"invest",
        name:"品質改善",
        price:300,
        machine:false,
        energy:0,
        prod:0,
        quality:1,
        gx:0,
        genP:0,
        genM:0,
        image:IMAGE_DIR+"impqlt.png",
        thumb:IMAGE_DIR+"impqlt_thumb.png",
        resultimg:IMAGE_DIR+"impqlt_result.png",
    },
    "10":{
        kind:"invest",
        name:"環境投資",
        price:200,
        machine:false,
        energy:0,
        prod:0,
        quality:0,
        gx:2,
        genP:0,
        genM:0,
        image:IMAGE_DIR+"impgx.png",
        thumb:IMAGE_DIR+"impgx_thumb.png",
        resultimg:IMAGE_DIR+"impgx_result.png",
    },
    "11":{
        kind:"invest",
        name:"広告-品質市場",
        price:200,
        machine:false,
        energy:0,
        prod:0,
        quality:0,
        gx:0,
        genP:0,
        genM:0,
        image:IMAGE_DIR+"adqlt.png",
        thumb:IMAGE_DIR+"adqlt_thumb.png",
        resultimg:IMAGE_DIR+"adqlt_result.png",
    },
    "12":{
        kind:"invest",
        name:"広告-環境市場",
        price:200,
        machine:false,
        energy:0,
        prod:0,
        quality:0,
        gx:0,
        genP:0,
        genM:0,
        image:IMAGE_DIR+"adgx.png",
        thumb:IMAGE_DIR+"adgx_thumb.png",
        resultimg:IMAGE_DIR+"adgx_result.png",
    },
    "13":{
        kind:"other",
        name:"工場拡張",
        price:600,
        machine:false,
        energy:0,
        prod:0,
        quality:0,
        gx:0,
        genP:0,
        genM:0,
        image:IMAGE_DIR+"expand.png",
        thumb:IMAGE_DIR+"expand_thumb.png",
        resultimg:IMAGE_DIR+"expand_result.png",
    },
    "14":{
        kind:"other",
        name:"借り入れ300",
        price:0,
        machine:false,
        energy:0,
        prod:0,
        quality:0,
        gx:0,
        genP:0,
        genM:0,
        image:IMAGE_DIR+"debt300.png",
        thumb:IMAGE_DIR+"debt300_thumb.png",
        resultimg:IMAGE_DIR+"debt300_result.png",
    },
}

/**
 * アクション分析
 * 配列で受け取ったアクションの送料および
 * アクション中の設備情報のみ返す
 * @param {*} actionList 
 * @returns [0:設備リスト, 1:購入合計金額, 2:品質変動, 3:GX値変動, 4:行動名リスト]
 */
function parseActionInfo(actionList){
    var machine=[]; // 設備情報
    var total=0; // 値段合計
    var q=0;
    var gx=0;
    var names=[];
    for(const m of actionList){
        if(action_kind[m].machine){
            machine.push({
                id:m,
                cost:action_kind[m].price,
                name:action_kind[m].name,
                prod:action_kind[m].prod,
                energy:action_kind[m].energy,
                genP:action_kind[m].genP,
                genM:action_kind[m].genM
            });
        }
        total += action_kind[m].price;
        q += action_kind[m].quality;
        gx += action_kind[m].gx;
        names.push(action_kind[m].name);
    }
    return [machine, total, q, gx, names];
}


/**
 * 設備情報の合計値をだす
 * @param {*} machines 
 * @returns 
 */
function sumMachineInfo(machines){
    var result = {
        energy:0,
        prod:0,
        genP:0,
        genM:0
    }

    for(const m of machines){
        result.energy+= m.energy;
        result.prod += m.prod;
        result.genP += m.genP;
        result.genM += m.genM;
    }

    return result;
}


export {action_kind, parseActionInfo, sumMachineInfo};