import React from 'react';
// import { LobbyAPI } from 'boardgame.io';
import { LobbyClient } from 'boardgame.io/client';
import { Lobby } from 'boardgame.io/react';
import { LobbyLoginForm } from './LobbyLoginForm.tsx';
import { LobbyCreateMatchForm } from './LobbyCreateMatchForm.tsx';
import { LobbyMatchInstance } from './LobbyMatchInstance.tsx';
import { RandomBot,MCTSBot } from 'boardgame.io/ai';
import { SocketIO } from 'boardgame.io/multiplayer'
import { Local } from 'boardgame.io/multiplayer'
import './lobby.css';
import { 
  Container,
  Button,
  Typography,
 } from '@mui/material';


const LobbyPhases = {
    ENTER : 'enter',
    PLAY : 'play',
    LIST : 'list',
  }
export class GameLobby extends Lobby{

  _startMatch = (gameName, matchOpts) => {
    const gameCode = this.connection._getGameComponents(gameName);
    if (!gameCode) {
      this.setState({
        errorMsg: 'game ' + gameName + ' not supported',
      });
      return;
    }

    let multiplayer = undefined;
    if (matchOpts.numPlayers > 1) {
      multiplayer = this.props.gameServer
        ? SocketIO({ server: this.props.gameServer })
        : SocketIO();
    }

//    if (matchOpts.numPlayers == 1) {
    if (matchOpts.numPlayers == 1) {
      // const maxPlayers = gameCode.game.maxPlayers;
      const maxPlayers = 2; //maxplayer数をハードコード
      const bots = {};
      for (let i = 1; i < maxPlayers; i++) {
        //bots[i + ''] = MCTSBot; //TODO:ボットの定義ハードコード
        bots[i + ''] = RandomBot; //TODO:ボットの定義ハードコード
      }
      multiplayer = Local({ bots });
    }

    const app = this.props.clientFactory({
      game: gameCode.game,
      board: gameCode.board,
      debug: this.props.debug,
      multiplayer,
    });

    // ブラウザが落ちた時用のバグ対応
    if(this.connection.playerCredentials==undefined){
      this.state.credentialStore[this.state.playerName] = "1";
      this.connection.playerCredentials = "1";
    }

    const match = {
      app: app,
      matchID: matchOpts.matchID,
      playerID: matchOpts.numPlayers > 1 ? matchOpts.playerID : '0',
      credentials: this.connection.playerCredentials,
    };

    this._clearRefreshInterval();
    this.setState({ phase: LobbyPhases.PLAY, runningMatch: match });
  };


  _createMatch = async (gameName, numPlayers, grname) => { //grname追加
    try {
      //await this.connection.create(gameName, numPlayers, );
      this.client = new LobbyClient({ server:this.props.gameServer });
      await this.client.createMatch(gameName, {numPlayers:numPlayers , setupData:{numPlayers:numPlayers, grName:grname}, grName:grname } ); //grname追加

      await this.connection.refresh();
      // rerender
      this.setState({});
    } catch (error) {
      this.setState({ errorMsg: error.message });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const name = this.state.playerName;
    this.state.credentialStore[name]="1"  //TODO:ユーザー認証を無効かするためのハードコード
    const creds = this.state.credentialStore[name];
    if (
      prevState.phase !== this.state.phase ||
      prevState.credentialStore[name] !== creds ||
      prevState.playerName !== name
    ) {
      this._createConnection(this.props);
      this._updateConnection();
      const cookie = {
        phase: this.state.phase,
        playerName: name,
        credentialStore: this.state.credentialStore,
      };
      // Cookies.save('lobbyState', cookie, { path: '/' });


    }
    if (prevProps.refreshInterval !== this.props.refreshInterval) {
      this._startRefreshInterval();
    }
  }

  renderMatches = (
    matches,//: LobbyAPI.MatchList['matches'],
    playerName,//: string
    grname,//: 追加
  ) => {

    //GrNameでフィルタリング
    const filteredMatches = matches.filter(match => match.setupData.grName == grname);

    if(filteredMatches.length===0){
      return (<div>...まだゲームの部屋が作成されていません</div>);
    }

    return filteredMatches.map((match,index) => {
      const { matchID, gameName, players } = match;
      return (
        <LobbyMatchInstance
          key={'instance-' + matchID}
          match={{ matchID, gameName, players: Object.values(players) }}
          playerName={playerName}
          onClickJoin={this._joinMatch}
          onClickLeave={this._leaveMatch}
          onClickPlay={this._startMatch}
          index={index}
        />
      );
    });
  };

  render() {
      const { gameComponents, renderer, grname } = this.props; // grnameを追加
      const { errorMsg, playerName, phase, runningMatch } = this.state;
  
      if (renderer) {
        return renderer({
          errorMsg,
          gameComponents,
          matches: this.connection.matches,
          phase,
          playerName,
          runningMatch,
          handleEnterLobby: this._enterLobby,
          handleExitLobby: this._exitLobby,
          handleCreateMatch: this._createMatch,
          handleJoinMatch: this._joinMatch,
          handleLeaveMatch: this._leaveMatch,
          handleExitMatch: this._exitMatch,
          handleRefreshMatches: this._updateConnection,
          handleStartMatch: this._startMatch,
        });
      }
  
      return (
        <div id="lobby-view" className="lobby-view">
          <div className={this._getPhaseVisibility(LobbyPhases.ENTER)}>
            <LobbyLoginForm
              key={playerName}
              playerName={playerName}
              onEnter={this._enterLobby}
              grname={grname} // grnameを追加
            />
          </div>
  
          <div className={this._getPhaseVisibility(LobbyPhases.LIST)}>
            <Container maxWidth="lg" sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              border: 'solid',
              borderColor: 'primary.main',
              borderRadius: '10px',
              padding: '20px',
            }}>
              <p style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "transparent",
                background: "#000",
                textShadow: "2px 2px 4px rgba( 50, 50, 50)",
                "-webkit-background-clip": "text",
              }}>{playerName}さん、こんにちは！
                <span className="buttons" id="lobby-exit">
                  <Button onClick={this._exitLobby} 
                    color="secondary" variant="outlined"
                  >
                    プレイヤー名入力に戻る
                  </Button>
                </span>
              </p>
              <div  id="match-creation" style={{"width":"100%"}}>
              <Typography component="h1" variant="h5">
                  新しいゲーム部屋を作成する
                </Typography>
                <LobbyCreateMatchForm
                  games={gameComponents}
                  createMatch={this._createMatch}
                  grname={grname} // grnameを追加
                />
              </div>
              <p className="phase-title">ゲーム部屋に参加する</p>
              <div id="instances">
                <table>
                  <tbody>
                    {this.renderMatches(this.connection.matches, playerName, grname)} {/* grnameを追加 */}
                  </tbody>
                </table>
                <span className="error-msg">
                  {errorMsg}
                  <br />
                </span>
              </div>
              {/* <p className="phase-title">
                Matches that become empty are automatically deleted.
              </p> */}
            </Container>
          </div>
  
          <div className={this._getPhaseVisibility(LobbyPhases.PLAY)}>
            {runningMatch && (
              <runningMatch.app
                key={runningMatch.matchID}
                playerName={(grname=="demo"?"DEMO_":"") +playerName}
                matchID={runningMatch.matchID}
                playerID={runningMatch.playerID}
                credentials={runningMatch.credentials}
              />
            )}
            <div className="exit_button" id="match-exit">
              <button onClick={this._exitMatch}>ゲームから退出する</button>
            </div>
          </div>
        </div>
      );
  }
}