import React from 'react';
import {
  TextField,
  Button,
  Avatar,
  Typography,
  Container,
  CssBaseline,
  Box,
  Link,
  Grid,
  Stack,
  FormControlLabel,
  Checkbox,
  Paper,
  InputLabel,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
type LoginFormProps = {
  playerName?: string;
  onEnter: (playerName: string) => void;
  grname: string;//追加
};

type LoginFormState = {
  playerName?: string;
  nameErrorMsg: string;
};

export class LobbyLoginForm extends React.Component<LoginFormProps, LoginFormState> {
  static defaultProps = {
    playerName: '',
  };

  state = {
    playerName: this.props.playerName,
    nameErrorMsg: '',
  };

  render() {
    const namelength=8;

    if(this.state.playerName==='Visitor'){ //Visitor設定箇所がライブラリの名かと思われるので苦肉の処置
      this.state.playerName = '';
      this.setState({
        playerName:'',
        nameErrorMsg: `プレイヤー名を入力してください(${namelength}文字まで)`,
      });
    }
    

    return (
      <div style={{padding:20}}>
        {
        /* <p className="phase-title"></p>
        <input
          type="text"
          value={this.state.playerName}
          onChange={this.onChangePlayerName}
          onKeyPress={this.onKeyPress}
        /> */}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: 'solid',
            borderColor: 'primary.main',
            borderRadius: '10px',
            padding: '20px',
          }}
        >
        <Typography component="h1" variant="h5">
          ようこそ! {this.props.grname}
        </Typography>
        <br/>
        <TextField label={this.state.nameErrorMsg} variant="outlined" fullWidth 
                  value={this.state.playerName}
                  onChange={this.onChangePlayerName}
                  onKeyPress={this.onKeyPress}
                  inputProps={{
                    maxLength: namelength,
                  }}/>

        {/* <span className="buttons">
          <button className="buttons" onClick={this.onClickEnter}>
            Enter
          </button>
        </span> */}
        <Button variant="contained" onClick={this.onClickEnter}>入室</Button>
        <br />
        </Box>
      </Container>
      </div>
    );
  }

  onClickEnter = () => {
    if(this.state.playerName){
      if (this.state.playerName === '') return;
      this.props.onEnter(this.state.playerName);
    }
  };

  onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      this.onClickEnter();
    }
  };

  onChangePlayerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value.trim();
    this.setState({
      playerName: name,
      nameErrorMsg: name.length > 0 ? '' : 'プレイヤー名が空です',
    });
  };
}
