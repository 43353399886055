// const diceCost = [-1, 0, 0, 0, 0, 1];
// const diceQuality = [-1, 0, 0, 1, 1, 1];
// const diceGreen = [0, 1, 1, 1, 1, 2];
// const diceCostText = ["-1", "±0", "±0", "±0", "±0", "+1"];
// const diceQualityText = ["-1", "±0", "±0", "+1", "+1", "+1"];
// const diceGreenText = ["±0", "+1", "+1", "+1", "+1", "+2"];

import { MAX_MAT, MAX_POW, MIN_MAT, MIN_POW } from "../GXSettings";

// プレイヤー人数やG.gamedataによって変動するダイスの値
// TODO:classにしてGをコンストラクタで与えるべきだったかも
const diceCost = (G)=>{
    return [-1, 0, 0, 0, 1, 1];
}
const diceQuality = (G)=>{
    var me=[]
    if(G.players.length == 2)       me = [-1, 0, 0, 0, 1, 1];
    else if(G.players.length == 3)  me = [-1, 0, 0, 1, 1, 1];
    else if(G.players.length == 4)  me = [-1, 0, 0, 1, 1, 1];
    else                            me = [-1, 0, 1, 1, 1, 1];//5
    return me;
}
const diceGreen = (G)=>{
    var me=[]
    if(G.players.length == 2)       me =  [0, 0, 0, 1, 1, 1];
    else if(G.players.length == 3)  me =  [0, 0, 1, 1, 1, 1];
    else if(G.players.length == 4)  me =  [0, 1, 1, 1, 1, 2];
    else                            me =  [0, 1, 1, 2, 2, 2];//5
    return me;
}

const diceText = (dice)=>(
    //サイコロの目配列をテキスト配列に変換する
    dice.map((d)=>{
        if(d==-1) return "-1";
        if(d==0) return "±0";
        if(d==1) return "+1";
        return "+2";//d==2
    })
);
const diceImage = (dice,kind="p")=>(
    //サイコロの目配列を画像配列に変換する
    dice.map((d)=>{
        if(d==-1) return `demand_${kind}-1.png`;
        if(d==0)  return `demand_${kind}0.png`;
        if(d==1)  return `demand_${kind}+1.png`;
        return `demand_${kind}+2.png`;//d==2
    })
);


// TODO:各イベントがハードコーディング気味
const diceEvent1=[0,1,1,1,2,2];
const event1Kind=["mat_hold.png","mat_up.png","mat_down.png"];
const diceEvent1Text=diceEvent1.map((d)=>event1Kind[d]);
function diceEvent1Proc(G, dice){
    if(dice==0){
        //変動なし
    }else if(dice==1){//原材料増加
        G.gamedata.matPrice+=20;
        if(G.gamedata.matPrice>MAX_MAT){ //MAX
            G.gamedata.matPrice=MAX_MAT;
        }
    }else if(dice==2){//原材料低下
        G.gamedata.matPrice-=20;
        if(G.gamedata.matPrice<MIN_MAT){ //MIN
            G.gamedata.matPrice=MIN_MAT;
        }
    }
}

const diceEvent2=[0,1,1,1,2,2];
const event2Kind=["en_hold.png","en_up.png","en_down.png"];
const diceEvent2Text=diceEvent2.map((d)=>event2Kind[d]);
function diceEvent2Proc(G,dice){
    if(dice==0){
        //変動なし
    }else if(dice==1){//燃料増加
        G.gamedata.powPrice+=10;
        if(G.gamedata.powPrice>MAX_POW){ //MAX
            G.gamedata.powPrice=MAX_POW;
        }
    }else if(dice==2){//燃料低下
        G.gamedata.powPrice-=10;
        if(G.gamedata.powPrice<MIN_POW){ //MIN
            G.gamedata.powPrice=MIN_POW;
        }
    }
}

const diceEvent3=[0,0,1,2,3,4];
const event3Kind=["hojo.png","comp.png","recession.png","boom.png","jcredit.png"];
const diceEvent3Text=diceEvent3.map((d)=>event3Kind[d]);
const gxThreshold = 10;
const diceEvent3Desc={
    0:{
        title:"環境に取り組んでいる企業への補助金！",
        desc:`環境値が${gxThreshold}以上の企業は補助金100を取得！`,
    },
    1:{
        title:"代替品・新規参入の脅威　価格競争の激化！",
        desc:`価格需要が減った・・・`,
    },
    2:{
        title:"景気後退　消費者の品質や環境意識が減少・・・",
        desc:`価格需要が増加！　品質需要の低下・・・環境需要の低下・・・`,
    },
    3:{
        title:"好景気　消費者の品質や環境意識が高まる！",
        desc:`品質需要の増加！環境需要の増加！　価格需要が低下・・・`,
    },
    4:{
        title:"排出権取引",
        desc:`最も使用燃料の高いプレイヤーから最も使用燃料の少ないプレイヤーへ100支払い！(自社発電は排出していないものとして扱う)`,
    }
}
function diceEvent3Proc(G,dice){
    if(dice==0){ //GX補助金
        //GX値が高いプレイヤーに補助金
        G.players.forEach((player)=>{
            if(player.green >= gxThreshold){
                player.money+=100;
                player.financial[G.gamedata.turn-1]["hojo"] += 100;
                player.financial[G.gamedata.turn-1]["money"] = player.money;
            }
        });
    }else if(dice==1){//代替品・新規参入の脅威
        G.gamedata.demandCost -= 1;
        //マイナスの場合0に補正
        if(G.gamedata.demandCost < 0) G.gamedata.demandCost = 0;
    }else if(dice==2){//景気後退
        G.gamedata.demandCost += 2;
        G.gamedata.demandQuality -= 1;
        G.gamedata.demandGreen -= 1;
        //マイナスの場合0に補正
        if(G.gamedata.demandQuality < 0) G.gamedata.demandQuality = 0;
        if(G.gamedata.demandGreen < 0) G.gamedata.demandGreen = 0;
    }else if(dice==3){//好景気
        G.gamedata.demandCost -= 1;
        G.gamedata.demandQuality += 1;
        G.gamedata.demandGreen += 1;
        //マイナスの場合0に補正
        if(G.gamedata.demandCost < 0) G.gamedata.demandCost = 0;
    }else if(dice==4){//排出権取引
        //使用エネルギーplayer.energy　から自社発電量player.genPowを差し引いたものを加味して比較を行う
        let maxPow = -1;
        let minPow = 1000;
        let maxPlayer = null;
        let minPlayer = null;
        G.players.forEach((player)=>{
            let pow = player.energy - player.genPow;
            if(pow > maxPow){
                maxPow = pow;
                maxPlayer = player;
            }
            if(pow < minPow){
                minPow = pow;
                minPlayer = player;
            }
        });
        if(maxPlayer == null || minPlayer == null) return;
        if(maxPlayer == minPlayer) return;

        maxPlayer.money -= 100;
        minPlayer.money += 100;
        maxPlayer.financial[G.gamedata.turn-1]["jcredit_buy"] = 100;
        minPlayer.financial[G.gamedata.turn-1]["jcredit_sell"] = 100;
        maxPlayer.financial[G.gamedata.turn-1]["money"] = maxPlayer.money;
        minPlayer.financial[G.gamedata.turn-1]["money"] = minPlayer.money;

    }
}





export {diceCost, diceQuality, diceGreen,
        diceText,diceImage,
        diceEvent1, diceEvent1Text, diceEvent1Proc,
        diceEvent2, diceEvent2Text, diceEvent2Proc,
        diceEvent3, diceEvent3Text, diceEvent3Proc, diceEvent3Desc,
    };