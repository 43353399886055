import * as React from 'react';
import { 
    Backdrop,
    Modal,
    Fade,
    Slide,
    Button,
    Typography,
    Box,
    Avatar,
} from '@mui/material';
import CountUp from 'react-countup';
import useSound from 'use-sound';
import PhaseSound from '../sound/phase.mp3';
import { Grid, Zoom } from '@mui/material';

import { TransitionGroup } from 'react-transition-group';

import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import zIndex from '@mui/material/styles/zIndex';
import { getPlayerName } from '../util';
import { calcInterest } from '../data/Financial';
const style = {
  // position: 'fixed',
  top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  // width: '600',
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
  textAlign: 'center',
//   zIndex: 1000,

};
const rootsx={
    width: "80vw",
    maxWidth: `${1600*0.8}px`,
    aspectRatio: "16/8",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    padding: "2rem 0",
    border: "solid 2px #fff",
    borderRadius: "20px",
    fontSize: "2rem",
    fontWeight: "bold",
    color: "white",
}
const headersx={
    // backgroundImage: "url(image/gx/result_town.png)",
    // backgroundSize: "contain",
    aspectRatio: "16/1.5",
    display: "flex",
    //真ん中寄せ
    justifyContent: "center",
    // paddingLeft: "7vw",
}
const demandsx={
    width: "20vw",
    fontSize: "3rem",
    fontWeight: "bold",
    color: "white",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    //左右中央
    alignItems: "center",
}
const resultsx={
    backgroundImage: "url(image/gx/result_player_bg_noblack.png)",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    width: "85%",
    aspectRatio: "1359/163",
    margin: "0.2rem auto",
    display: "inline-flex",
}
const namesx={
    textAlign: "center",
    fontSize: "3rem",
    fontWeight: "bold",
    color: "white",
    margin: "auto 0",
}
const uresx={
    textAlign: "left",
    margin: "auto 0",
    padding: "0 1.5rem",
}

const imagesx={
    width: "3rem",
}
const shishutusx={
    padding: "0 2rem",
}
const shishutunamesx={
    ...namesx,
    fontSize: "4rem",
}

function ResultModal({ ...props}) {
    const { G, playerID, ctx, moves, matchData, playerName, headerimage, children, onClose, initopen=true} = props;
    const [open, setOpen] = React.useState(initopen);
    const handleOpen = () => setOpen(true);
    const handleClose = (event, reason) => {
      if (reason && reason == "backdropClick") 
         return;
      setOpen(false);
      if(onClose){onClose();}
    }
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          // onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
              sx:{
                backdropFilter: 'brightness(70%) blur(10px)',
              }
            },
          }}
          style={{display:'flex',alignItems:'center',justifyContent:'center'}}
        >
          <Slide direction="down" in={open} mountOnEnter unmountOnExit>
              <div style={{zIndex:1000}}>
              <ResultComponent {...props} onClose={handleClose}/>
              </div>
          </Slide>
        </Modal>
      </div>
    );
}
  

function ResultComponent({ ...props}) {
    const { G, playerID, ctx, moves, matchData, playerName, settings, children, headerimage, onClose} = props;

    const ease={"enter":"cubic-bezier(0.175, 10.885, 0.32, 1.275)","exit":"cubic-bezier(0.175, 1.885, 0.32, 1.275)"} 
    const [playTaiko] = useSound(PhaseSound, {volume:settings.vol*0.5});

    const next = () => {
        playTaiko();
        onClose();
    }
    
    return (
        <Box sx={style}>
            <div style={headersx}>
                <Zoom in={true} timeout={200} easing={ease}>
                    <img src={"image/gx/"+headerimage}
                        style={{
                            //親におさまるように
                            width: "50rem",
                            height: "100%",
                            objectFit: "contain",
                        }}/>
                </Zoom>
            </div>
            <div style={rootsx}>
                {children}
                <div>
                    <Button onClick={()=>next()} color='success' >  
                        <img style={{width:"15rem"}}src='image/gx/button_next.png'/>
                    </Button>
                </div>
            </div>
        </Box>

    );
}

function PlayerResult({ ...props}){
    const { G, playerID, ctx, moves, matchData, playerName, player, children} = props;
    return(
        <Grid container style={resultsx}>
            <Grid item xs={4} sx={namesx}>
                {getPlayerName(player.id, matchData)}
            </Grid>
            <Grid item xs={8} sx={uresx}>
                {children}
            </Grid>
        </Grid>
    );
}

/**
 * 全プレイヤーの純資産状況をchart.jsで表示する
 * @param {*} param0 
 */
function FinancialResult({...props}){


}


export {ResultModal, PlayerResult};