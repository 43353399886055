
import React from 'react';
import { Button, Drawer } from '@mui/material';
import useSound from 'use-sound';
import paSound from '../sound/pa.mp3';

/**
 * Drawerとボタンを表示する
 */
function GXDrawer({key,title,component,...props}) {
    const { G, playerID, ctx, moves, matchData, playerName, settings } = props;
    const player = G.players[playerID];
    var vol = 0;
    if( settings !== undefined ){
      vol = settings["vol"];
    }
    const [play] = useSound(paSound,{volume:vol});
  
    const [state, setState] = React.useState({});
  
    const toggleDrawer = (anchor, open) => (event) => {
      play();
      if (
        event.type === 'keydown' &&
        ((event).key === 'Tab' ||
          (event).key === 'Shift')
      ) {
        return;
      }
  
      setState({ ...state, [anchor]: open });
    }; 
  
    return (
      <React.Fragment key={key}>
          <Button onClick={toggleDrawer(key, true)}  variant="outlined" sx={{backgroundColor:"white",width:"100%"}}>{title}</Button>
          <Drawer
          PaperProps={{
              sx: { width: "60%",maxWidth:"960px" },
          }}
          anchor={'right'}
          open={state[key]}
          onClose={toggleDrawer(key, false)}
          >
          {component}
          </Drawer>
      </React.Fragment>
    );

  }
  
  export {GXDrawer};