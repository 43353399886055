import * as React from 'react';
import { Button, Drawer } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Financial } from '../data/Financial';
import { GXDrawer } from './GXDrawer';
import './css/finance-table.css';


/**
 * P/Lを表示する
 * @param {} param0 
 * @returns 
 */
function BS({...props}) {
    const { G, playerID, ctx, moves, matchData, playerName } = props;
    const player = G.players[playerID];
    // 財務データを取得
    var financials = player.financial.map((f) => {
        return new Financial(f);
    });
    // 配列の最後は現在進行形のターンなので削除する
    financials.pop();


    const cellsx = {
        textAlign:"right",
    }
    
    return (
    <div>
        {player.hasan !=0 && (
            <div class="hanko-style">破 産</div>
        )}
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
            <TableRow>
                <TableCell align="right"></TableCell>
                {/*各ターン数表示(配列のインデックス)*/
                financials.map((f,index) => (
                    <TableCell align="right">{index+1}期</TableCell>
                ))}
            </TableRow>
            </TableHead>
            <TableBody>
                <TableRow
                    className="gyou">
                    <TableCell component="th" scope="row">現金及び預金</TableCell>
                    {financials.map((f,index) => (
                        <TableCell sx={cellsx}>{f.money}</TableCell>
                    ))}
                </TableRow>
                <TableRow
                    className={`color_1 subtotal`}>
                    <TableCell component="th" scope="row">流動資産</TableCell>
                    {financials.map((f,index) => (
                        <TableCell sx={cellsx}>{f.currentAssets}</TableCell>
                    ))}
                </TableRow>
                <TableRow
                    className="gyou">
                    <TableCell component="th" scope="row">機械装置</TableCell>
                    {financials.map((f,index) => (
                        <TableCell sx={cellsx}>{f.fixedAssets}</TableCell>
                    ))}
                </TableRow>
                <TableRow
                    className="gyou">
                    <TableCell component="th" scope="row">減価償却累計額</TableCell>
                    {financials.map((f,index) => (
                        <TableCell sx={cellsx}>{f.accumulatedDepreciation}</TableCell>
                    ))}
                </TableRow>
                <TableRow
                    className={`color_1 subtotal`}>
                    <TableCell component="th" scope="row">固定資産</TableCell>
                    {financials.map((f,index) => (
                        <TableCell sx={cellsx}>{f.netFixedAssets}</TableCell>
                    ))}
                </TableRow>
                <TableRow
                    className={`color_1s total`}>
                    <TableCell component="th" scope="row">資産</TableCell>
                    {financials.map((f,index) => (
                        <TableCell sx={cellsx}>{f.totalAssets}</TableCell>
                    ))}
                </TableRow>
                <TableRow
                    className={`color_2s total`}>
                    <TableCell component="th" scope="row">負債</TableCell>
                    {financials.map((f,index) => (
                        <TableCell sx={cellsx}>{f.totalLiabilities}</TableCell>
                    ))}
                </TableRow>
                <TableRow
                    className={`color_3s total`}>
                    <TableCell component="th" scope="row">純資産</TableCell>
                    {financials.map((f,index) => (
                        <TableCell sx={cellsx}>{f.netAssets}</TableCell>
                    ))}
                </TableRow>
            </TableBody>
            </Table>
        </TableContainer>
    </div>
    );
}

/**
 * Drawerとボタンを表示する
 */
function BSDrawer({...props}) {
  return (
    <GXDrawer
        {...props}
        key="bs"
        title="貸借対照表"
        component={<BS {...props}/>}
    />
  );
}

export {BSDrawer, BS}