const MAX_PRICE = 500;
const MIN_PRICE = 100;
const SHOBUN_PRICE = 50;
const MAX_Q = 10;
const MIN_Q = 0;
const MAX_GX = 20;
const MIN_GX = 0;

const MAX_MAT = 140;
const MIN_MAT = 60;
const MAX_POW = 50;
const MIN_POW = 20;

const GAME_TURN = 8;
const DEMO_GAME_TURN=4;

export {
    MAX_PRICE,
    MIN_PRICE,
    SHOBUN_PRICE,
    MAX_Q,
    MIN_Q,
    MAX_GX,
    MIN_GX,
    MAX_MAT,
    MIN_MAT,
    MAX_POW,
    MIN_POW,
    GAME_TURN,
    DEMO_GAME_TURN,
    };
