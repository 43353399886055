import * as React from 'react';
import { 
    Snackbar,
    Slide,
    Fade,
    List,
    ListItem,
    ListItemText,
    Avatar,
 } from '@mui/material';
 import { TransitionGroup } from 'react-transition-group';
import { m } from 'framer-motion';
import { getPlayerColor, getPlayerName } from '../util';
import './css/fukidashi.css'
 
const ChatView = ({ ...props }) => {
    const {matchData, chatMessages} = props;
    const [open, setOpen] = React.useState(true);
    const [anchorOrigin, setAnchorOrigin] = React.useState({
        vertical: 'bottom',
        horizontal: 'left',
    });
    const [autoHideDuration, setAutoHideDuration] = React.useState(3000);
    const [displayMessage, setDisplayMessage] = React.useState([]);
    const [now, setNow] = React.useState(Date.now());

    const message_timeout = 5000;

    /* chatMessagesの構造は以下
       [
          { id: 'foo', sender: '0', payload: {message:"Hi",time:1702800222839} },
          { id: 'bar', sender: '1', payload: {message:'Let’s go!', time:1702800222839} },
        ]
    */
   /* 500msごとにリフレッシュ */
    React.useEffect(() => {
        const interval = setInterval(() => {
            setNow(Date.now());
        }, 500);
        return () => clearInterval(interval);
    }, []);

    function stringAvatar(playerid) {
        return {
          sx: {
            bgcolor: getPlayerColor(playerid),
            width: "5rem",
            height: "5rem",
          },
        //   children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
            children: getPlayerName(playerid,matchData),
        };
      }

    return (
        <React.Fragment>

            <div style={{
                        position:"absolute",
                        //親要素と同じ位置に配置
                        bottom:"6rem",
                        left:0,
                        width:"100%",
                        textAlign:"center",
                        zIndex:1000,
                        //クリックイベントは下に通す
                        pointerEvents:"none",

                        }}>
            <TransitionGroup>

            {chatMessages.map((message) => {
                console.log("★★",now,message.payload.time,now - message.payload.time );
                if(now - message.payload.time > message_timeout) return null;
                return (
                    <Fade key={message.id} in={true} timeout={1000} mountOnEnter unmountOnExit>
                        <div>
                            {/* 吹き出しのイメージでチャット表示*/}
                            <div class="balloon6">
                                <div class="faceicon">
                                    <Avatar {...stringAvatar(message.sender)} />
                                </div>
                                <div class="chatting">
                                    <div class="says">
                                        <img 
                                            src={`/image/gx/${message.payload.img}`}
                                            style={{
                                                width:"100%",
                                                height:"100%",
                                                objectFit:"contain",
                                            }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </Fade>
                )
            })}
              </TransitionGroup>
              </div>
        </React.Fragment>
    )
}

export {ChatView};