import * as React from 'react';
import { 
  Backdrop,
  Modal,
  Fade,
  Slide,
  Box,
  Zoom,
  Button,
  Typography,
 } from '@mui/material';

import useSound from 'use-sound';
import SceneSound from '../sound/scene.mp3';  


const style = {
  // position: 'fixed',
  top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  // width: '600',
  // bgcolor: 'background.paper',
  // border: '2px solid #000',
  // boxShadow: 24,
  // p: 4,
  textAlign: 'center',
  zIndex: 1000,
};
const modalstyle = {
}
export default function GXModal({ ...props}) {
  const { G, playerID, ctx, moves, matchData, playerName, settings, onClose, component, blur="10",backgroundImage=""} = props;
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    if(onClose){onClose();}
  }
  const ease={"enter":"cubic-bezier(0.175, 10.885, 0.32, 1.275)",/*"exit":"cubic-bezier(0.175, 1.885, 0.32, 1.275)"*/} 

  return (
    <div>
      {
      /* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
            sx:{
              backdropFilter: `brightness(70%) blur(${blur}px)`,
            }
          },
        }}
        style={{display:'flex',alignItems:'center',justifyContent:'center', backgroundImage: backgroundImage,backgroundSize:"contain"}}
      >
        <Zoom in={open} timeout={200} easing={ease}>
          <Box onClick={()=>handleClose()} sx={style}>
            {component}
          </Box>
        </Zoom>
      </Modal>
    </div>
  );
}
