/**
 * Financial Classの結果をChart.jsを利用して描画する
 */
import React from 'react';
import { Line, Bar } from 'react-chartjs-2';
import { Financial } from '../data/Financial';
import { Typography, Button, Drawer } from '@mui/material';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import 'chart.js/auto'
import { GXDrawer } from './GXDrawer';
import { getPlayerColor, getPlayerName } from '../util';


/**
 * BSのグラフ表示する
 * @param {*} props
 * @returns
 */
const BSChart = ({ financials, labels, player,...props }) => {
    const { G, playerID, ctx, moves, matchData, playerName } = props;

    const data = {
        labels: labels,
        datasets: [
            {
                type: 'bar',
                label: '流動資産',
                data: financials.map((f) => {
                    return f.money;
                }),
                fill: false,
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
                stack: 'Stack1',
                order: 2,
            },
            {//固定資産
                type: 'bar',
                label: '固定資産',
                data: financials.map((f) => {
                    return f.netFixedAssets;
                }),
                fill: false,
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 0.2)',
                stack: 'Stack1',
                order: 1,
            },
            // {
            //     type: 'bar',
            //     label: '総資産',
            //     data: financials.map((f) => {
            //         return f.totalAssets;
            //     }),
            //     fill: false,
            //     backgroundColor: 'rgba( 192, 75, 192, 0.2)',
            //     borderColor: 'rgba( 192, 75, 192, 0.2)',
            // },
            {
                type: 'bar',
                label: '負債',
                data: financials.map((f) => {
                    return f.totalLiabilities;
                }),
                fill: false,
                backgroundColor: 'rgba(153, 102, 255, 0.2)',
                borderColor: 'rgba(153, 102, 255, 0.2)',
                stack: 'Stack2',
                order: 4,
            },
            {//純資産
                type: 'bar',
                label: '純資産',
                data: financials.map((f) => {
                    return f.netAssets;
                }),
                fill: false,
                backgroundColor: 'rgba(255, 159, 64, 0.2)',
                borderColor: 'rgba(255, 159, 64, 0.2)',
                stack: 'Stack2',
                order: 3,
            },
        ],
    };
    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };
    return (
        <Box>
            <Typography variant="h6" component="div" gutterBottom>
                資産状況
            </Typography>
            <Bar data={data} options={options}/>
        </Box>
    );
}
/**
 * PLのグラフ表示する
 * @param {*} props 
 * @returns 
 */
const PLChart = ({ financials, labels, player,...props }) => {
    const { G, playerID, ctx, moves, matchData, playerName } = props;

    const data = {
        labels: labels,
        datasets: [
            {//売上
                type: 'bar',
                label: '売上',
                data: financials.map((f) => {
                    return f.revenue;
                }),
                fill: false,
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
            },
            {//営業利益
                type: 'line',
                label: '営業利益',
                data: financials.map((f) => {
                    return f.operatingIncome;
                }),
                fill: false,
                backgroundColor: 'rgba(143, 255, 99, 0.2)',
                borderColor: 'rgba(143, 255, 99, 0.2)',
            },
            {//経常利益
                type: 'line',
                label: '経常利益',
                data: financials.map((f) => {
                    return f.ordinaryIncome;
                }),
                fill: false,
                backgroundColor: 'rgba(99, 143, 255, 0.2)',
                borderColor: 'rgba(99, 143, 255, 0.2)',
            },
            {//純利益
                type: 'line',
                label: '純利益',
                data: financials.map((f) => {
                    return f.netIncome;
                }),
                fill: false,
                backgroundColor: 'rgba(255, 159, 64, 0.2)',
                borderColor: 'rgba(255, 159, 64, 0.2)',
            },

        ],
    };
    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };
    return (
        <Box>
            <Typography variant="h6" component="div" gutterBottom>
                利益状況
            </Typography>
            <Bar data={data} options={options}/>
        </Box>
    );
}
/**
 * CFのグラフ表示する
 */
const CFChart = ({ financials, labels, player,...props }) => {
    const { G, playerID, ctx, moves, matchData, playerName } = props;

    const data = {
        labels: labels,
        datasets: [
            {//営業CF
                type: 'bar',
                label: '営業CF',
                data: financials.map((f) => {
                    return f.operatingCF;
                }),
                fill: false,
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
            },
            {//投資CF
                type: 'bar',
                label: '投資CF',
                data: financials.map((f) => {
                    return f.investmentCF;
                }),
                fill: false,
                backgroundColor: 'rgba(143, 255, 99, 0.2)',
                borderColor: 'rgba(143, 255, 99, 0.2)',
            },
            {//財務CF
                type: 'bar',
                label: '財務CF',
                data: financials.map((f) => {
                    return f.financialCF;
                }),
                fill: false,
                backgroundColor: 'rgba(99, 143, 255, 0.2)',
                borderColor: 'rgba(99, 143, 255, 0.2)',
            },
            {//キャッシュ
                type: 'bar',
                label: 'CF',
                data: financials.map((f) => {
                    return f.CF;
                }),
                fill: false,
                backgroundColor: 'rgba(255, 159, 64, 0.2)',
                borderColor: 'rgba(255, 159, 64, 0.2)',
            },
            {//フリーCF
                type: 'line',
                label: 'フリーCF',
                data: financials.map((f) => {
                    return f.freeCF;
                }),
                fill: false,
                backgroundColor: 'rgba(153, 102, 255, 0.3)',
                borderColor: 'rgba(153, 102, 255, 0.3)',
            },

        ],
    };
    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };
    return (
        <Box>
            <Typography variant="h6" component="div" gutterBottom>
                キャッシュ状況
            </Typography>
            <Bar data={data} options={options}/>
        </Box>
    );
}


/**
 * 安全性指標のグラフ
 */
function EquityRatio({financials, labels, player, ...props}) {
    const { G, playerID, ctx, moves, matchData, playerName } = props;
    const data = {
        labels: labels,
        datasets: [
            {
                label: '自己資本比率',
                data: financials.map((f) => {
                    return (f.equityRatio*100).toFixed(2).toString();
                }),
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
                borderWidth: 1,
            },
            {
                label: '固定比率',
                data: financials.map((f) => {
                    return (f.fixedCostRatio*100).toFixed(2).toString();
                }),
                backgroundColor: 'rgba(143, 255, 99, 0.2)',
                borderColor: 'rgba(143, 255, 99, 0.2)',
                borderWidth: 1,
            },
            {//インタレストカバレッジレシオ
                label: 'インタレストカバレッジレシオ',
                data: financials.map((f) => {
                    return f.interestCoverageRatio.toFixed(2).toString();
                }),
                backgroundColor: 'rgba(99, 143, 255, 0.2)',
                borderColor: 'rgba(99, 143, 255, 0.2)',
                borderWidth: 1,

            }
        ],
    };
    const options = {
        scales: {
            y: {
                beginAtZero: true,
                max: 100,
                ticks: {
                    callback: function(value, index, values){
                        return  value +  '%'
                    }
                }
            },
        },
    };
    return (
        <Box>
            <Typography variant="h6" component="div" gutterBottom>
                安全性分析
            </Typography>
            <Bar data={data} options={options} />
        </Box>
    );

}
/**
 * 変動費、固定費、損益分岐点売上のグラフ
 */
function VariableCost({financials, labels, player, ...props}) {
    const { G, playerID, ctx, moves, matchData, playerName } = props;
    const data = {
        labels: labels,
        datasets: [
            {//売上
                label: '売上',
                type: 'bar',
                data: financials.map((f) => {
                    return f.revenue;
                }),
                backgroundColor: 'rgba(255, 159, 64, 0.2)',
                borderColor: 'rgba(255, 159, 64, 0.2)',
                borderWidth: 1,
                yAxisID: "y1",
                stack: 'Stack0',
                order: 1,
                categoryPercentage: 0.49,
            },
            {
                label: '変動費',
                type: 'bar',
                data: financials.map((f) => {
                    return f.variableCost;
                }),
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
                borderWidth: 1,
                stack: 'Stack1',
                yAxisID: "y1",
                order: 3,
                categoryPercentage: 0.6,
            },
            {
                label: '固定費',
                type: 'bar',
                data: financials.map((f) => {
                    return f.fixedCost;
                }),
                backgroundColor: 'rgba(143, 255, 99, 0.2)',
                borderColor: 'rgba(143, 255, 99, 0.2)',
                borderWidth: 1,
                stack: 'Stack1',
                yAxisID: "y1",
                order: 2,
                categoryPercentage: 0.6,
            },
            // {
            //     label: '変動比率',
            //     type: 'line',
            //     data: financials.map((f) => {
            //         return (f.variableCostRatio*100).toFixed(2).toString();
            //     }),
            //     backgroundColor: 'rgba(99, 143, 255, 0.2)',
            //     borderColor: 'rgba(99, 143, 255, 0.2)',
            //     borderWidth: 1,
            //     yAxisID: "y2",

            // },
            {//営業利益
                label: '営業利益',
                type: 'bar',
                data: financials.map((f) => {
                    return f.operatingIncome;
                }),
                backgroundColor: 'rgba(99, 143, 255, 0.2)',
                borderColor: 'rgba(99, 143, 255, 0.2)',
                borderWidth: 1,
                yAxisID: "y1",
                stack: 'Stack1',
                order: 4,
                categoryPercentage: 0.6,
            },
            {//損益分岐点売上
                label: '損益分岐点売上',
                type: 'bar',
                data: financials.map((f) => {
                    return f.breakEvenPoint.toFixed(0).toString();
                }),
                backgroundColor: 'rgb(99, 143, 255,0.5)',
                borderColor: 'rgb(99, 143, 255,0.5)',
                borderWidth: 1,
                stack: 'Stack3',
                yAxisID: "y1",
                order: 5,
            },
        ],
    };
    const options = {
        scales: {
            y1:{
                beginAtZero: true,
                position: "left",
                ticks: {
                    callback: function(value, index, values){
                        return  value +  '万円'
                    }
                },
                grid: {
                    drawOnChartArea: false, // only want the grid lines for one axis to show up
                },
            },
            // y2:{
            //     beginAtZero: true,
            //     position: "right",
            //     max: 100,
            //     ticks: {
            //         callback: function(value, index, values){
            //             return  value +  '%'
            //         }
            //     }
            // },
        },
    };
    return (
        <Box>
            <Typography variant="h6" component="div" gutterBottom>
                変動費、固定費、損益分岐点売上
            </Typography>
            <Bar data={data} options={options} />
        </Box>
    );
}
/**
 * 営業利益率の推移をバーチャートで表示する
 */
function OperatingProfitRatio({financials, labels, player, ...props}) {
    const { G, playerID, ctx, moves, matchData, playerName } = props;
    const data = {
        labels: labels,
        datasets: [
            {
                label: '売上高総利益率',
                data: financials.map((f) => {
                    return (f.grossProfitRatio*100).toFixed(2).toString();
                }),
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
                borderWidth: 1,
            },
            {
                label: '営業利益率',
                data: financials.map((f) => {
                    return (f.operatingProfitRatio*100).toFixed(2).toString();
                }),
                backgroundColor: 'rgba(143, 255, 99, 0.2)',
                borderColor: 'rgba(143, 255, 99, 0.2)',
                borderWidth: 1,
            },
            {//経常利益率
                label: '経常利益率',
                data: financials.map((f) => {
                    return (f.ordinaryProfitRatio*100).toFixed(2).toString();
                }),
                backgroundColor: 'rgba(99, 143, 255, 0.2)',
                borderColor: 'rgba(99, 143, 255, 0.2)',
                borderWidth: 1,
            },
        ],
    };
    const options = {
        scales: {
            y: {
                beginAtZero: true,
                max: 100,
                ticks: {
                    callback: function(value, index, values){
                        return  value +  '%'
                    }
                }
            },
        },
    };
    return (
        <Box>
            <Typography variant="h6" component="div" gutterBottom>
                収益性分析
            </Typography>
            <Bar data={data} options={options} />
        </Box>
    );
}
/**
 * 効率性分析のグラフ
 */
function EfficiencyRatio({financials, labels, player, ...props}) {
    const { G, playerID, ctx, moves, matchData, playerName } = props;
    const data = {
        labels: labels,
        datasets: [
            {
                label: '総資産回転率',
                data: financials.map((f) => {
                    return f.totalAssetTurnover.toFixed(2).toString();
                }),
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
                borderWidth: 1,
            },
            {
                label: '固定資産回転率',
                data: financials.map((f) => {
                    return f.fixedAssetTurnover.toFixed(2).toString();
                }),
                backgroundColor: 'rgba(143, 255, 99, 0.2)',
                borderColor: 'rgba(143, 255, 99, 0.2)',
                borderWidth: 1,
            },
        ],
    };
    const options = {
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    callback: function(value, index, values){
                        return  value +  '回'
                    }
                }
            },
        },
    };
    return (
        <Box>
            <Typography variant="h6" component="div" gutterBottom>
                効率性分析
            </Typography>
            <Bar data={data} options={options} />
        </Box>
    );
}

/**
 * 各チャートをまとめたコンポーネント
 */
function FinancialAnalyze({...props}) {
    const { G, playerID, ctx, moves, matchData, playerName } = props;
    const player = G.players[playerID];
    
    var financials = player.financial.map((f) => {
        return new Financial(f);
    });

    // グラフのデータを作成
    const labels = financials.map((f, index) => {
        return index + 1 + "期";
    });

    // 最新のデータはターン途中の為除外
    financials.pop();
    labels.pop();

    const chartsx = {
    }
    const chartparentsx = {
    }

    return (
    <div>
        <div style={chartparentsx}>
            <BSChart { ...props}
                player={player}
                financials={financials}
                labels={labels}
            />
        </div>
    {playerName.startsWith('DEMO_')?
        <div>デモプレイの場合は財務分析は表示されません。<br/>
        フルバージョンでは収益の推移やキャッシュの推移の他、<br/>
        財務分析である、財務の安全性、効率性、収益性の各種指標が表示されます。</div>
    :
    <React.Fragment>
        <div style={chartparentsx}>
            <PLChart { ...props}
                player={player}
                financials={financials}
                labels={labels}
            />
        </div>
        <div style={chartparentsx}>
            <CFChart { ...props}
                player={player}
                financials={financials}
                labels={labels}
            />
        </div>
        <div style={chartparentsx}>
            <VariableCost { ...props}
                player={player}
                financials={financials}
                labels={labels}
                style={chartsx}
                />
        </div>
        <div style={chartparentsx}>
            <EquityRatio { ...props}
                player={player}
                financials={financials}
                labels={labels}
                style={chartsx}
                />
        </div>
        <div style={chartparentsx}>
            <OperatingProfitRatio { ...props}
                player={player}
                financials={financials}
                labels={labels}
                style={chartsx}
                />
        </div>
        <div style={chartparentsx}>
            <EfficiencyRatio { ...props}
                player={player}
                financials={financials}
                labels={labels}
                style={chartsx}
                />
        </div>
    </React.Fragment>
    }
    </div>
    )
}
/**
 * 今期の全プレイヤーの純資産を高い順に表示する
 */
function NetAssetsRanking({...props}) {
    const { G, playerID, ctx, moves, matchData, playerName } = props;
    if(G.gamedata.turn <= 1){return (<div></div>)}
    //全プレイヤーの前期の純資産と純利益を取得して、純資産の降順にソートする
    var players = G.players.map((player) => {
        var financials = player.financial.map((f) => {
            return new Financial(f);
        });
        // 最新のデータはターン途中の為除外
        financials.pop();
        return {
            id: player.id,
            name: getPlayerName(player.id,matchData),
            netAssets: financials[financials.length - 1].netAssets,
            netIncome: financials[financials.length - 1].netIncome,
        };
    });
    players.sort((a, b) => {
        return b.netAssets - a.netAssets;
    });

    const textsx={
        color:"white",
        textAlign:"center",
        fontSize:"3rem",
        fontWeight:"bold",
        //上下中央
        margin:"auto 0",

    }

    return (
        <Box sx={{


            }}>
            {players.map((player, index) => (
            <Grid container style={{
                backgroundImage:"url(image/gx/result_player_bg_noblack.png)",
                backgroundSize:"contain",
                backgroundRepeat:"no-repeat",
                backgroundPosition:"center",
                width: "85%",
                margin: "0.2rem auto",
                display: "inline-flex",
                }}>
                <Grid item xs={1.5} >
                    <img src={`/image/gx/medal_0${index + 1}.png`} style={{width:"100%"}}/>
                </Grid>
                <Grid item xs={2.5} sx={textsx}>
                {player.name}
                </Grid>
                <Grid item xs={8} sx={textsx}>
                    {player.netAssets}
                    <span style={{color:player.netIncome>0?"green":"red"}}>
                    ({player.netIncome>0 && "+"}{player.netIncome})
                    </span>
                </Grid>
            </Grid>
            ))}
        </Box>
    );
}



/**
 * 全プレイヤー分の純資産グラフの推移を折れ線グラフで表示する
 */
function AllPlayerNetAssetsChart({...props}) {
    const { G, playerID, ctx, moves, matchData, playerName } = props;
    const labels = G.players[0].financial.map((f, index) => {
        return index + 1 + "期";
    });
    // 0期目を決め打ちして追加
    labels.unshift("");

    const datasets = G.players.map((player) => {
        var financials = player.financial.map((f) => {
            return new Financial(f);
        });
        // グラフのデータを作成
        const data = financials.map((f, index) => {
            return f.netAssets;
        });
        //0期目を1000で決め打ちして追加
        data.unshift(1000);

        // 最新のデータはターン途中の為除外
        data.pop();
        return {
            label: getPlayerName(player.id,matchData),
            data: data,
            fill: false,
            // backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: getPlayerColor(player.id),
        }
    });
    const data = {
        labels: labels,
        datasets: datasets,
    };
    const options = {
        maintainAspectRatio: false,
        color: 'white',
        plugins: {
            datalabels: {
              color: 'white',
            },
          },
        scales: {
            x: {
                ticks: {
                    color: 'white',
                },
            },
            y: {
                // beginAtZero: true,
                ticks: {
                    color: 'rgb,(255,255,255,0)',
                },
            },
        },
    };


    return (
        <Grid container sx={{
            aspectRatio:"16/7",
            width:"100%",
        }}>
            {/* <Grid item xs={4}>
                    全プレイヤーの純資産推移
            </Grid>
            <Grid item xs={8}> */}
        <Line data={data} options={options}/>
        {/* </Grid> */}
        </Grid>
    );

}



/**
 * Drawerとボタンを表示する
 */
function AnalyzeDrawer({...props}) {
    return (
      <GXDrawer
          {...props}
          key="analyze"
          title="財務分析"
          component={<FinancialAnalyze {...props}/>}
      />
    );
}


export {AnalyzeDrawer, FinancialAnalyze, AllPlayerNetAssetsChart, NetAssetsRanking}