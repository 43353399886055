import { useEffect, useState } from 'react';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import React from 'react';
import './css/slot.css';

interface Props {
  textData: string[];
  slotclass: string;
  random?: boolean;
  stopIndex?: number;
}

const MIN_ARR_LEN = 15;

const SlotMachine = ({ textData:inputData , slotclass, random = false, stopIndex=0 }: Props) => {
  // 最終的に表示する文字
  const lastText = inputData[stopIndex];
  // console.log("SlotMachine lastText: ", lastText, "stopIndex: ", stopIndex, "inputData: ", inputData);
  // inputDataをコピー　同じ要素があると回転が不自然になるので配列を丸める
  var textData = inputData.slice();
  textData = [...new Set(textData)];

  if (random) textData = shuffle(textData);

  const dataCount =
    textData.length < MIN_ARR_LEN
      ? Math.round(MIN_ARR_LEN / textData.length) * textData.length
      : textData.length;
  const textArr =
    textData.length < MIN_ARR_LEN
      ? Array(Math.round(MIN_ARR_LEN / textData.length))
          .fill(textData)
          .flat()
      : textData;

  const [data, setData] = useState(textArr);
  const [currentIndex, setCurrentIndex] = useState(0);
  const lastIndex = dataCount - 1;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prev) => {
        return prev < lastIndex ? prev + 1 : prev;
      });
    }, getDuration(15, currentIndex) + 64) as unknown as number;//+64は適当。バグ対策
  
    return () => clearInterval(interval);
  }, [currentIndex, lastIndex]);

  const getDuration = (base: number, index: number) => {
    return (
      base * (((index + 1) / MIN_ARR_LEN) * 10) * (MIN_ARR_LEN / dataCount)
    );
  };

  const variants: Variants = {
    initial: { scaleY: 0.3, y: '-50%', opacity: 0 },
    animate: ({ isLast }: { isLast: boolean }) => {
      let props: { scaleY: number; y: number; opacity: number; filter?: string } = { scaleY: 1, y: 0, opacity: 1 };
      if (!isLast) props['filter'] = 'blur(8px)';

      return props;
    },
    exit: { scaleY: 0.3, y: '50%', opacity: 0 },
  };

  function shuffle(array: string[]) {
    const shuffled = [...array];

    for (let i = shuffled.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }

    return shuffled;
  }
  //
  return (
    <div>
      <AnimatePresence mode='popLayout'>
        {data.map((text, i) => {
          const isLast = i === lastIndex;
          return (
            i === currentIndex && (
              <motion.p
                className={slotclass}
                key={text}
                custom={{ isLast }}
                variants={variants}
                initial='initial'
                animate='animate'
                exit='exit'
                transition={{
                  duration: isLast ? 1 : getDuration(0.015, i),
                  ease: isLast ? 'easeInOut' : 'linear',
                }}
                // onClick={handleClick}
                whileHover={{ opacity: 0.5, transition: { duration: 0.2 } }}
                whileTap={{
                  scaleY: 0.7,
                  y: '-30%',
                  transition: { duration: 0.2 },
                }}
                style={{
                  overflow: 'hidden',
                  margin: 0,
                }}
              >
                <img src={"/image/gx/"+(isLast ? lastText : text)} 
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    objectPosition: 'center',                  
                  }}/>
              </motion.p>
            )
          );
        })}
      </AnimatePresence>
    </div>
  );
};

export {SlotMachine};