// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { collection, getDoc, doc } from "firebase/firestore"; 

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCbYpO_hJaSP19GfHzkPQpYEy2zflWLVog",
  authDomain: "game1-sirkus.firebaseapp.com",
  projectId: "game1-sirkus",
  storageBucket: "game1-sirkus.appspot.com",
  messagingSenderId: "849394847801",
  appId: "1:849394847801:web:a8de58f599ce30382a7de8",
  measurementId: "G-N2283HYVQ5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const getJoinCode = async (joinkey) => {
    if(joinkey === ""){
        return null;
    }
    const docRef = doc(db, "joinGr", joinkey);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        return docSnap.data();
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        return null;
    }
}

export { getJoinCode };