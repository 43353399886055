function getPlayerName(id,matchData){
  for( const m of matchData){
    if(m.id == id){
      return m.name;
    }
  }
  return "Player"+(id+1);
}

function getPlayerColor(id){
  const colors = [
    'rgb(255, 99, 132)',
    'rgb(53, 162, 235)',
    'rgb(75, 192, 192)',
    'rgb(252, 206, 100)'
  ];
  return colors[id];
}

export {getPlayerName, getPlayerColor};