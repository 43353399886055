import * as React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography,
    TableContainer, Table, TableRow, TableCell, Paper, Grid
 } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    priceAttraction, qualityAttraction, greenAttraction
} from '../data/AttractionTable';
import { GXDrawer } from './GXDrawer';
import { diceCost,diceQuality,diceGreen,diceText } from '../data/DiceTable';
/**
 * マニュアル
 * @param {} param0 
 * @returns 
 */
function Manual({...props}) {
    const { G, playerID, ctx, moves, matchData, playerName } = props;
    const tablecontainersx = {
         border: '1px solid #e0e0e0', 
         overflow: 'hidden' 
    }
    const tablesx ={
        borderCollapse: 'collapse',
    }

    const tablecellsx = {
        padding: '0.5rem',
        borderRight: '1px solid #e0e0e0',
    }

    const tablecellcolorsx = {
        ...tablecellsx,
        backgroundColor: '#f5f5f5'
    }
    const subtitlesx={
        textDecoration: 'underline'
    }

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                マーケティングファクトリールール説明
            </Typography>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant="h5">ゲームの目的</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        マーケティングファクトリーは投資・製造・販売を繰り返し利益の最大化を目指すゲームです<br/>
                        市場が求める価値の変化を考えながらニーズに即した企業の魅力度を高めていきましょう。<br/>
                        8期経過した時点で純資産の最も多いプレイヤーが勝利となります。<br/>
                        <br/>
                        財務諸表や財務分析を確認し、利益の最大化だけでなく資金繰りにも注意して経営を行ってください。
                    </Typography>
                    フェーズの流れは以下の通りです。
                    <ol>
                        <li>アクション選択フェーズ</li>
                        <li>製造数決定フェーズ</li>
                        <li>価格決定フェーズ</li>
                        <li>需要変化・販売結果フェーズ</li>
                    </ol>
                    これらのフェーズを行った後、1期(1ターン)が終了し次の期に移ります。
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant="h5">フェーズの流れ</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="h6" sx={subtitlesx} gutterBottom>
                        アクション選択フェーズ
                    </Typography>
                    <Typography paragraph>
                    毎期アクションを最大<strong>二つ</strong>行うことができます。<br/>
                    それぞれのアクションには以下のような効果があります。
                        <ul>
                            {[
                                "製品生産設備: 製品を生産する設備を購入する",
                                "原材料・エネルギー生産: 原材料を生産する設備を増やす",
                                "研究開発 / 環境活動 / 広告活動: それぞれの活動を行う",
                                "工場拡張: スペースを増やす",
                                "借り入れ: 資金を借りる"].map((item, index) => (
                                    <li key={index}>{item.trim()}</li>
                                ))}
                        </ul>
                    </Typography>
                    <Typography paragraph>
                        製品生産設備および原材料・エネルギー生産設備は工場のスペースを必要とします。<br/>
                        初期は4つの空きスペースがあります。
                    </Typography>
                    <Typography paragraph>
                        製品生産設備は製品を生産するために必要です。<br/>
                        イナヅマのマークは製品設備に必要な燃料の数を表しています。ターン終了時に燃料分の費用がかかります。<br/>
                        箱のマークは設備が生産可能な製品の数を表しています。製品精算時に原材料を購入して生産を行います。<br/>
                        各設備の減価償却は5年で残存価値が0、定額法で計算されます。<br/>
                    </Typography>
                    <Typography paragraph>
                        原材料・エネルギー生産設備は製品生産設備に必要な原材料やエネルギーを自社で賄うことができます。<br/>
                        カードに記載されている数値が自社で生産可能な原材料およびエネルギーで毎期生産を行います。<br/>
                        自社で賄った分の原材料およびエネルギーは製品生産設備の稼働時の購入が不要になります。<br/>
                    </Typography>
                    <Typography paragraph>
                        研究開発は自社の品質値を上げることができます。<br/>
                        環境活動は自社の環境値を上げることができます。<br/>
                        広告宣伝活動は対応した市場の需要を一つ増やすことができます。<br/>
                        これらのアクションは工場のスペースは必要ありません。<br/>
                    </Typography>
                    <Typography paragraph>
                        工場拡張は工場のスペースを増やすことができます。<br/>
                        借り入れは資金を借りることができます。<br/>
                    </Typography>
                  
                    <Typography variant="h6" sx={subtitlesx} gutterBottom>
                        製造数決定フェーズ
                    </Typography>
                    <Typography paragraph>
                        製品を製造する数を決定します。製造には原材料の購入が必要です。<br/>
                        自社生産を行った分の原材料には費用はかかりません。<br/>
                    </Typography>

                    <Typography variant="h6" sx={subtitlesx}  gutterBottom>
                        販売価格決定フェーズ
                    </Typography>
                    <Typography paragraph>
                        販売価格の増減を行います。<br/>
                        価格を上げると利益は増えますが、需要は減少します。<br/>
                        価格を下げると利益は減りますが、需要は増加します。<br/>
                        市場の状況をみて適切な価格を設定しましょう。<br/>
                    </Typography>

                    <Typography variant="h6"  sx={subtitlesx} gutterBottom>
                        需要変化・販売結果フェーズ
                    </Typography>
                    <Typography paragraph>
                        ルーレットの結果によって対応した市場の需要が変化します。<br/>
                        その後、販売の結果を確認します。<br/>
                        どのプレイヤーがどれだけ売上られるかは以下の順番で判断されます。<br/>
                        <ol>
                            <li>価格市場について、最も価格魅力度が高いプレイヤーが需要の分だけ販売を行える。</li>
                            <li>需要がまだ残っている場合は次に価格魅力度の高いプレイヤーに販売権が移る</li>
                            <li>価格市場の需要がゼロになったらば品質市場の判定に移る</li>
                            <li>同様に品質魅力度の高いプレイヤー順に販売権を得ることができる</li>
                            <li>品質市場の需要がゼロになったらば環境市場の判定に移り、同様の処理が行われる</li>
                        </ol>
                        魅力度が同率のプレイヤーがいる場合には、同率プレイヤー同士で売り上げを割ります。<br/>
                        割り算して余りが出た場合は以下の処理によって優先度が決定します。<br/>
                        <ol>
                            <li>価格魅力度で同率だった場合は品質魅力度を参照する</li>
                            <li>品質魅力度で同率だった場合は環境魅力度を参照する</li>
                            <li>環境魅力度で同率だった場合は総資産を参照する</li>
                            <li>それでも同率だった場合は販売が不成立となる</li>
                        </ol>
                    </Typography>
                    <Typography paragraph>
                        最後に借入金の返済や燃料費を支払い次の期に移ります
                    </Typography>
                </AccordionDetails>
            </Accordion>
            {/* 品質魅力度を簡便のため比例関係にしたのでコメントアウト */}
            {/* <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant="h5">魅力度について</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography paragraph>
                        魅力度は以下のテーブルに従って決定されます。
                        品質値をあげても魅力度が比例して上がるわけではないので注意してください。
                    </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TableContainer sx={tablecontainersx}>
                            <Table sx={tablesx} aria-label="pricetable">
                                <TableRow>
                                    <TableCell sx={tablecellcolorsx}>価格</TableCell>
                                    <TableCell sx={tablecellcolorsx}>魅力度</TableCell>
                                </TableRow>
                                {
                                    Object.entries(priceAttraction).map(([key, val]) => (
                                    <TableRow>
                                        <TableCell sx={tablecellsx}>{key}</TableCell>
                                        <TableCell sx={tablecellsx}>{val}</TableCell>
                                    </TableRow>
                                    ))
                                }
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={4}>
                        <TableContainer sx={tablecontainersx}>
                            <Table sx={tablesx} aria-label="qualitytable">
                                <TableRow>
                                    <TableCell sx={tablecellcolorsx}>品質</TableCell>
                                    <TableCell sx={tablecellcolorsx}>魅力度</TableCell>
                                </TableRow>
                                {
                                    Object.entries(qualityAttraction).map(([key, val]) => (
                                    <TableRow>
                                        <TableCell sx={tablecellsx}>{key}</TableCell>
                                        <TableCell sx={tablecellsx}>{val}</TableCell>
                                    </TableRow>
                                    ))
                                }
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={4}>
                        <TableContainer sx={tablecontainersx}>
                            <Table sx={tablesx} aria-label="greentable">
                                <TableRow>
                                    <TableCell sx={tablecellcolorsx}>環境</TableCell>
                                    <TableCell sx={tablecellcolorsx}>魅力度</TableCell>
                                </TableRow>
                                {
                                    Object.entries(greenAttraction).map(([key, val]) => (
                                    <TableRow>
                                        <TableCell sx={tablecellsx}>{key}</TableCell>
                                        <TableCell sx={tablecellsx}>{val}</TableCell>
                                    </TableRow>
                                    ))
                                }
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                </AccordionDetails>
            </Accordion> */}

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant="h5">市場需要の変化について</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography paragraph>
                        市場需要はルーレットによって変化します。<br/>
                        ルーレット内のテーブルは以下のようになっています。
                    </Typography>
                    <TableContainer sx={tablecontainersx}>
                        <Table sx={tablesx} aria-label="demandtable">
                            <TableRow>
                                <TableCell sx={tablecellcolorsx}>価格需要</TableCell>

                                {
                                    diceText(diceCost(G)).map((val) => (
                                        <TableCell >{val}</TableCell>
                                    ))
                                }
                            </TableRow>
                            <TableRow>
                            <TableCell sx={tablecellcolorsx}>品質需要</TableCell>

                                {
                                    diceText(diceQuality(G)).map((val) => (
                                        <TableCell >{val}</TableCell>
                                    ))
                                }
                            </TableRow>
                            <TableRow>
                            <TableCell sx={tablecellcolorsx}>環境需要</TableCell>

                                {
                                    diceText(diceGreen(G)).map((val) => (
                                        <TableCell >{val}</TableCell>
                                    ))
                                }
                            </TableRow>
                        </Table>
                    </TableContainer>
                    <Typography paragraph>
                        価格を求める需要はゲーム開始時は多いですが変化が少なく、品質を求める需要は緩やかに上昇していきます。<br/>
                        また、環境を求める需要はゲーム開始時は少ないものの市場が成熟するにつれ上昇します。
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant="h5">財務諸表について</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography paragraph>
                        財務諸表は以下の通りです。
                    </Typography>
                    <Typography paragraph>
                        <ul>
                            <li>損益計算書</li>
                            <li>貸借対照表</li>
                            <li>キャッシュフロー計算書</li>
                        </ul>
                    </Typography>
                    <Typography paragraph>
                        損益計算書は期間内の売上高、販売費、原材料費、研究開発費、減価償却費、営業利益、純利益を表しています。
                    </Typography>
                    <Typography paragraph>
                        貸借対照表は期間内の資産、負債、純資産を表しています。
                    </Typography>
                    <Typography paragraph>
                        キャッシュフロー計算書は期間内の営業活動によるキャッシュフロー、投資活動によるキャッシュフロー、財務活動によるキャッシュフローを表しています。
                    </Typography>
                    <Typography paragraph>
                        これらの財務諸表を確認し、利益の最大化だけでなく資金繰りや資産状況にも注意して経営を行ってください。
                    </Typography>
                    <Typography paragraph>
                        またこれらの値を分析した「財務分析」にも目を通してください。
                    </Typography>
                </AccordionDetails>
            </Accordion>

        </div>
    );
}

/**
 * Drawerとボタンを表示する
 */
function ManualDrawer({...props}) {
    return (
      <GXDrawer
          {...props}
          key="manual"
          title="説明書"
          component={<Manual {...props}/>}
      />
    );
}

export {ManualDrawer, Manual}