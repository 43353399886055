/**
 * 
 *   id:1,
 *   summary:"ああ",
 *   desc:"あああああ",
 *   x:11,
 *   y:100,
 *   eff_kind:1,  //1:ポイント増減 2:
 *   eff_val:4,   //効果量
 */

function loadmasu() {
    //TODO:CSVからデータを読み込むように要修正
    return [
        {
            id:1,
            summary:"ああ",
            desc:"あああああ",
            x:11,
            y:100,
            eff_kind:1,
            eff_val:4,
        },
        {
            id:2,
            summary:"いい",
            desc:"いいいいい",
            x:22,
            y:200,
            eff_kind:2,
            eff_val:3,
        },
        {
            id:3,
            summary:"うう",
            desc:"ううううう",
            x:33,
            y:300,
            eff_kind:3,
            eff_val:2,
        },
        {
            id:4,
            summary:"ええ",
            desc:"えええええ",
            x:44,
            y:400,
            eff_kind:4,
            eff_val:1,
        },
        {
            id:5,
            summary:"おお",
            desc:"おおおおお",
            x:55,
            y:500,
            eff_kind:1,
            eff_val:1,
        },
        {
            id:6,
            summary:"かか",
            desc:"かかかかか",
            x:66,
            y:600,
            eff_kind:2,
            eff_val:2,
        },
        {
            id:7,
            summary:"きき",
            desc:"ききききき",
            x:77,
            y:700,
            eff_kind:3,
            eff_val:3,
        },
        {
            id:8,
            summary:"くく",
            desc:"くくくくく",
            x:88,
            y:800,
            eff_kind:4,
            eff_val:4,
        },
    ]

            

    
}

export {loadmasu};