import * as React from 'react';
import { Button, Drawer } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Financial } from '../data/Financial';
import { GXDrawer } from './GXDrawer';
import './css/finance-table.css';

/**
 * P/Lを表示する
 * @param {} param0 
 * @returns 
 */
function CF({...props}) {
    const { G, playerID, ctx, moves, matchData, playerName } = props;
    const player = G.players[playerID];
    // 財務データを取得
    var financials = player.financial.map((f) => {
        return new Financial(f);
    });
    // 配列の最後は現在進行形のターンなので削除する
    financials.pop();
    
    const gyousx = {

    }
    const cellsx = {
        textAlign:"right",
    }

    
    return (
    <div>
        {player.hasan !=0 && (
            <div class="hanko-style">破 産</div>
        )}
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
            <TableRow>
                <TableCell align="right"></TableCell>
                {/*各ターン数表示(配列のインデックス)*/
                financials.map((f,index) => (
                    <TableCell align="right">{index+1}期</TableCell>
                ))}
            </TableRow>
            </TableHead>
            <TableBody>
                <TableRow
                    className="gyou">
                    <TableCell component="th" scope="row">当期純利益</TableCell>
                    {financials.map((f,index) => (
                        <TableCell sx={cellsx}>{f.netIncome }</TableCell>
                    ))}
                </TableRow>
                <TableRow
                    className="gyou">
                    <TableCell component="th" scope="row">減価償却費</TableCell>
                    {financials.map((f,index) => (
                        <TableCell sx={cellsx}>{f.depreciation}</TableCell>
                    ))}
                </TableRow>
                <TableRow
                    className={`color_1 subtotal`}>
                    <TableCell component="th" scope="row">営業キャッシュフロー</TableCell>
                    {financials.map((f,index) => (
                        <TableCell sx={cellsx}>{f.operatingCF}</TableCell>
                    ))}
                </TableRow>
                <TableRow
                    className="gyou">
                    <TableCell component="th" scope="row">設備投資額</TableCell>
                    {financials.map((f,index) => (
                        <TableCell sx={cellsx}>{f.setsubi}</TableCell>
                    ))}
                </TableRow>
                <TableRow
                    className={`color_2 subtotal`}>
                    <TableCell component="th" scope="row">投資キャッシュフロー</TableCell>
                    {financials.map((f,index) => (
                        <TableCell sx={cellsx}>{f.investmentCF}</TableCell>
                    ))}
                </TableRow>
                <TableRow
                    className="gyou">
                    <TableCell component="th" scope="row">借入額</TableCell>
                    {financials.map((f,index) => (
                        <TableCell sx={cellsx}>{f.kariire}</TableCell>
                    ))}
                </TableRow>
                <TableRow
                    className="gyou">
                    <TableCell component="th" scope="row">返済額</TableCell>
                    {financials.map((f,index) => (
                        <TableCell sx={cellsx}>{f.hensai}</TableCell>
                    ))}
                </TableRow>
                <TableRow
                    className={`color_3 subtotal`}>
                    <TableCell component="th" scope="row">財務キャッシュフロー</TableCell>
                    {financials.map((f,index) => (
                        <TableCell sx={cellsx}>{f.financialCF}</TableCell>
                    ))}
                </TableRow>
                <TableRow
                    className={`color_1s total`}>
                    <TableCell component="th" scope="row">キャッシュフロー</TableCell>
                    {financials.map((f,index) => (
                        <TableCell sx={cellsx}>{f.CF}</TableCell>
                    ))}
                </TableRow>
            </TableBody>
            </Table>
        </TableContainer>
    </div>
    );
}

/**
 * Drawerとボタンを表示する
 */

function CFDrawer({...props}) {
    return (
      <GXDrawer
          {...props}
          key="cf"
          title="キャッシュフロー"
          component={<CF {...props}/>}
      />
    );
  }

export {CFDrawer, CF}