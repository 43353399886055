import { TurnOrder } from 'boardgame.io/core';
import { action_kind, parseActionInfo, sumMachineInfo } from './data/ActionKind'
import { INVALID_MOVE } from 'boardgame.io/core';
import { diceCost,diceQuality,diceGreen, diceEvent1Proc, diceEvent2Proc, diceEvent3Proc, diceEvent1,diceEvent2,diceEvent3 } from './data/DiceTable';
import { calcTradePriority, calcTradeAmountP, calcTradeAmountQ,calcTradeAmountG } from './data/AttractionTable';
import { Financial, FinancialData, calcDepreciation,calcInterest } from './data/Financial';
import {MAX_PRICE, MIN_PRICE, SHOBUN_PRICE, MAX_Q, MIN_Q, MAX_GX, MIN_GX, GAME_TURN, DEMO_GAME_TURN} from './GXSettings';
/******************************** サブ関数 ***************************************/

/**
 * プレイヤー情報生成
 * @param {*} playerID 
 * @returns 
 */
function createPlayer(playerID) {
  return {
        id: playerID,
        money: 1000,
        price:300,
        quality:5,
        green:5,
        space:4, //設備キャパシティ
        product:0, //生産可能数
        energy:0, //必要エネルギー
        genPow:0, //自社発電量
        genMat:0, //自社原材料生産量
        machine:[],//保有設備
        actions:[], //画面表示用現ターンの行動
        stock:0,    //在庫数
        tmp:{},//一次領域
        financial:[],//財務諸表
        hasan:0, //破産フラグ +の場合破産したターンが入る
    }
}

/**
 * ゲームデータ初期化
 */
function initialGamedata(){
    return {
        //ターン数
        turn:0,
        //生産費用
        matPrice:80,
        //電力費用
        powPrice:40,
        //価格需要数
        demandCost:4,
        //品質需要数
        demandQuality:2,
        //環境需要数
        demandGreen:1,
        //サイコロ
        dice:{
            demandCost:0,
            demandQuality:0,
            demandGreen:0,
            events1:0,
            events2:0,
            events3:0,
        },
        //処理用
        gameover:false,//ctx.gameoverの反映タイミングが遅いので手動用
        demo:false,//デモモード
    }
}

const doneOnly = ({G, ctx, playerID, events}, result ) => {
    ctx.onStage =true; //onBeginタイミングではまだActivePlayerが作成されていないのでこのタイミングで代替。
    events.endStage();
}

/**
 * 配列から0,1,2の要素の組み合わせを作る
 */
function getCombinations(arr) {
    let result = [[]];
    for(let i = 0; i < arr.length; i++) {
        result.push([arr[i]]);
        for(let j = i + 1; j < arr.length; j++) {
            result.push([arr[i], arr[j]]);
        }
    }
    return result;
}
/******************************** ゲーム定義 ***************************************/
const GX = {
    name: 'GXGame',
    minPlayers: 1,
    maxPlayers: 4,
    setup: ({ctx}, setupData) => {
        console.log("Game setup");
        console.log(setupData);
        console.log(ctx);

        const players = Array(ctx.numPlayers).fill().map((_, i) => createPlayer(i));

        const gamedata = initialGamedata();
        //プレイヤー人数による初期の価格、品質、環境の重要数調整(4人で4,2,1)
        if(ctx.numPlayers == 2){
            gamedata.demandCost = 2;
            gamedata.demandQuality = 1;
            gamedata.demandGreen = 0;
        }else if(ctx.numPlayers == 3){
            gamedata.demandCost = 3;
            gamedata.demandQuality = 1;
            gamedata.demandGreen = 1;
        }
        const log = [];

        //デモ
        if(setupData && setupData.grName == "demo"){
            gamedata.demo = true;
        }

        //ゲーム環境用
        const env={
            volume:0.1,
        }

        return { players, gamedata, log, env};
    },
    phases: {
        /**
         * 行動選択フェーズ
         */
        selectAction:{
            start:true,
            next:'production',
            endIf: ({ G,ctx }) => {
                if(ctx.onStage && ctx.activePlayers===null){
                    ctx.onStage = false;
                    return true;
                }else{
                    return false;
                }
            },
            turn:{
                order: TurnOrder.RESET,
                stages: {
                    selectActionStage: {
                        moves: {
                            /**
                             * アクション決定
                             */
                            done: ({G, ctx, events, playerID, }, selection, result) => {
                                ctx.onStage =true; //onBeginタイミングではまだActivePlayerが作成されていないのでこのタイミングで代替。
                                const player = G.players[playerID];

                                //一次領域初期化
                                player.tmp={}

                                //selection分析
                                var machines, total, quality, green, names;
                                [machines, total, quality, green, names] = parseActionInfo(selection);
                                
                                // 行動は二つまで
                                if( selection.length > 2){
                                    result["message"] = "行動は二つまでです！";
                                    return INVALID_MOVE;
                                }

                                // 空き土地チェック
                                if( player.machine.length + machines.length > player.space){
                                    result["message"] = "設備を置けるスペースがありません！";
                                    return INVALID_MOVE;
                                }

                                // お金チェック
                                if( player.money < total ){
                                    result["message"] = "資金が不足しています！";
                                    return INVALID_MOVE;
                                }
                                // 一次領域に格納。ターン終了時にプレイヤー情報に反映
                                player.tmp["selection"]=selection;
                                player.tmp["money"] = total;
                                player.tmp["machine"] = machines;
                                player.tmp["quality"] = quality;
                                player.tmp["green"] = green;
                                player.tmp["action"] = [];
                                player.tmp["action"].push(...names);

                                // 状態更新
                                result["complete"] = true;
                                result["message"] = "他のプレイヤーの決定待ち・・・";
                                events.endStage();
                            }
                        },
                    },
                },
                onBegin: ({G, ctx,events}) => {
                    ctx.onStage =false;
                    events.setActivePlayers({ all: 'selectActionStage',});//同時進行の為ステージ以降
                },

            },
            onBegin: ({ G, ctx,events}) => {
                G.gamedata.turn += 1;
                // ターンの財務情報初期化
                // 破産プレイヤーがいる場合はhasanフラグを立てて現金1000円付与
                for(const player of G.players){
                    player.financial[G.gamedata.turn-1]=JSON.parse(JSON.stringify(FinancialData));
                    if(player.money <= 0){
                        player.hasan = G.gamedata.turn-1;
                        player.money = 1000;
                        player.financial[G.gamedata.turn-1]["kariire"] += 1000;
                        player.financial[G.gamedata.turn-1]["fusai"].push(
                            {kari:1000, hensai:0, zan:1000, interest:0}
                        )

                        //ログに破産記録
                        G.log.push({id:player.id, turn:G.gamedata.turn ,action:"破産！！", kind:"破産！！"});
                    }
                }
            },
            onEnd: ({G, ctx, events, playerID, }) => {
                if(G.gamedata.turn > GAME_TURN || (G.gamedata.demo && G.gamedata.turn > DEMO_GAME_TURN)){
                    //ゲーム終了時のエラー回避
                    return;
                }

                for(const player of G.players){
                    // アクションログに記録
                    if(player.tmp["action"]==[]){
                        G.log.push({id:player.id, turn:G.gamedata.turn ,action:"行動なし", kind:"行動選択"});
                    }else{
                        if(player.tmp["action"] != undefined){ 
                            player.tmp["action"].forEach((a)=>{
                                G.log.push({id:player.id, turn:G.gamedata.turn,action:a, kind:"行動選択"});
                            });
                        }
                    }

                    // 一次情報をプレイヤー情報に反映
                    player.money -= player.tmp["money"];
                    player.machine.push(...player.tmp["machine"]);
                    var sumMachine = sumMachineInfo(player.machine);
                    player.product = sumMachine.prod;
                    player.energy = sumMachine.energy;
                    player.genPow = sumMachine.genP;
                    player.genMat = sumMachine.genM;
                    player.quality += player.tmp["quality"];
                    if(player.quality > MAX_Q) player.quality = MAX_Q;
                    if(player.quality < MIN_Q) player.quality = MIN_Q;
                    player.green += player.tmp["green"];
                    if(player.green > MAX_GX) player.green = MAX_GX;
                    if(player.green < MIN_GX) player.green = MIN_GX;
                    player.actions.push(player.tmp["action"]);

                    player.tmp["selection"].forEach((m)=>{
                        //借入の場合
                        if(action_kind[m].name == "借り入れ300"){ //TODO:Magic Number!!!
                            player.money += 300;
                            player.financial[G.gamedata.turn-1]["kariire"] = 300;
                            player.financial[G.gamedata.turn-1]["fusai"].push(
                                {kari:300, hensai:75, zan:300, interest:25}
                            )
                        }
                        //工場拡張の場合
                        if(action_kind[m].name == "工場拡張"){ //TODO:Magic Number!!!
                            player.space += 1;
                        }


                        // 財務情報更新
                        //設備の場合
                        if(action_kind[m].machine ||
                            action_kind[m].name == "工場拡張"){ //工場拡張も設備扱いで減価償却する  
                            //BS更新
                            player.financial[G.gamedata.turn-1]["kotei"].push(
                                {
                                    "name":action_kind[m].name,
                                    "kounyuu":action_kind[m].price,
                                    "genka":action_kind[m].price/5,
                                    "genzai":action_kind[m].price,
                                }
                            )
                        }

                        // 品質研究および環境投資を行った場合
                        if(action_kind[m].name == "品質改善"){ //TODO:Magic Number!!!
                            player.financial[G.gamedata.turn-1]["research"] += action_kind[m].price;
                        }
                        if(action_kind[m].name == "環境投資"){ //TODO:Magic Number!!!
                            player.financial[G.gamedata.turn-1]["donate"] += action_kind[m].price;
                        }

                        // 広告を行った場合
                        if(action_kind[m].name == "広告-品質市場"){ //TODO:Magic Number!!!
                            G.gamedata.demandQuality += 1;
                            player.financial[G.gamedata.turn-1]["ad"] += action_kind[m].price;
                        }
                        if(action_kind[m].name == "広告-環境市場"){ //TODO:Magic Number!!!
                            G.gamedata.demandGreen += 1;
                            player.financial[G.gamedata.turn-1]["ad"] += action_kind[m].price;
                        }
                    });
                    
                    // 燃料費用計上
                    var fuelnum = player.energy - player.genPow;
                    if(fuelnum < 0) fuelnum = 0;
                    player.financial[G.gamedata.turn-1]["fuel"] = G.gamedata.powPrice;
                    player.financial[G.gamedata.turn-1]["fuelnum"] = fuelnum;

                    // 設備情報
                    player.tmp = {}; //tmp初期化
                }
            },
        },
        /**
         * 値段選択フェーズ
         */
        selectPrice:{
            next:'trade',
            endIf: ({ G,ctx }) => {
                if(ctx.onStage && ctx.activePlayers===null){
                    ctx.onStage = false;
                    return true;
                }else{
                    return false;
                }
            },
            turn:{
                order: TurnOrder.RESET,
                stages: {
                    selectPriceStage: {
                        moves: {
                            /**
                             * 価格決定
                             * @param {*} param0 
                             * @param {*} selection 
                             * @param {*} result 
                             */
                            done: ({G, ctx, playerID, events}, selection, result) => {
                                ctx.onStage =true; //onBeginタイミングではまだActivePlayerが作成されていないのでこのタイミングで代替。
                                const player = G.players[playerID];
                                //selectionが空
                                if(selection == null || selection == undefined || selection == ""){
                                    result["message"] = "価格を選択してください！";
                                    return INVALID_MOVE;
                                }

                                //selection分析(価格の変動値が格納されている)
                                var changePrice = parseInt(selection,10);
                                if( selection < -40  || selection > 40){
                                    result["message"] = "価格変動幅が不正です！";
                                    return INVALID_MOVE;
                                }

                                var price = player.price + changePrice;
                                // console.log(price,changePrice,player.price);
                                if(price > MAX_PRICE) price = MAX_PRICE;
                                if(price < MIN_PRICE) price = MIN_PRICE;
                                
                                //一次領域に格納。ターン終了時にプレイヤー情報に反映
                                player.tmp["price"] = price;

                                // 状態更新
                                result["complete"] = true;
                                result["message"] = "他のプレイヤーの決定待ち・・・";
                                events.endStage();

                            }
                        },
                    },
                },
                onBegin: ({G, ctx,events}) => {
                    ctx.onStage =false;
                    events.setActivePlayers({ all: 'selectPriceStage',}); //同時進行の為ステージ以降
                },
            },
            onEnd: ({ G, ctx }) => {
                for(const player of G.players){
                    // アクションログに記録
                    const diff = player.tmp["price"] - player.price
                    G.log.push({id:player.id, turn:G.gamedata.turn ,action:(diff>0?"+":"")+diff, kind:"価格決定"});

                    // 一次情報をプレイヤー情報に反映
                    player.price = player.tmp["price"];
                    player.tmp = {}; //tmp初期化

                }
            },
        },
        /**
         * 生産フェーズ
         */
        production:{
            next:'selectPrice',
            endIf: ({ G,ctx }) => {
                if(ctx.onStage && ctx.activePlayers===null){
                    ctx.onStage = false;
                    return true;
                }else{
                    return false;
                }
            },
            turn:{
                order: TurnOrder.RESET,
                stages: {
                    productionStage: {
                        moves: {
                            done: ({G, ctx, playerID, events}, numProduction, result ) => {
                                ctx.onStage =true; //onBeginタイミングではまだActivePlayerが作成されていないのでこのタイミングで代替。
                                const player = G.players[playerID];
                                //在庫初期化
                                player.stock = 0;

                                //生産可能数チェック(UI上で制限されているがAIが指定してくる場合がある)
                                if( numProduction > player.product){
                                    result["message"] = "生産可能数を超えています！";
                                    return INVALID_MOVE;
                                }

                                // 生産費用がプレイヤー資金を超えていないかチェック
                                var total = G.gamedata.matPrice * (numProduction - player.genMat);
                                if(total < 0) total = 0;
                                if( player.money < total ){
                                    result["message"] = "資金が不足しています！";
                                    return INVALID_MOVE;
                                }
                                //プレイヤー情報に在庫を反映
                                player.stock += parseInt( numProduction ,10);
                                //プレイヤー情報に生産費用を反映
                                player.money -= total;

                                // 財務諸表に生産費用を反映
                                player.financial[G.gamedata.turn-1]["material"] = G.gamedata.matPrice;
                                player.financial[G.gamedata.turn-1]["materialnum"] = (numProduction - player.genMat) > 0? (numProduction - player.genMat):0;

                                //状態更新
                                result["complete"] = true;
                                result["message"] = "他のプレイヤーの決定待ち・・・";
                                events.endStage();
                            }
                        },
                    },
                },
                onBegin: ({G, ctx,events}) => {
                    ctx.onStage = false;
                    events.setActivePlayers({ all: 'productionStage',}); //同時進行の為ステージ移行
                },
            },
            onEnd: ({ G, ctx }) => {
                for(const player of G.players){
                    // アクションログに記録
                    G.log.push({id:player.id, turn:G.gamedata.turn ,action:player.stock, kind:"生産"});
                }
            },
        },
        /**
         * 販売フェーズ
         */
        trade:{
            next:'event',
            endIf: ({ G,ctx }) => {
                if(ctx.step==="end"){
                    ctx.step="initial";
                    ctx.nowProfit = [];
                    return true;
                }
            },
            turn:{
                order: TurnOrder.RESET,
                endIf: ({ G,ctx }) => {
                    if(ctx.onStage && ctx.activePlayers===null){
                        ctx.onStage = false;
                        return true;
                    }else{
                        return false;
                    }
                },
                stages: {
                    initialStage: { moves: { done: doneOnly }, },
                    trade: { moves: { done: doneOnly }, },
                    eventStage: { moves: { done: doneOnly }, },
                },
                onBegin: ({G, ctx, events, random}) => {

                    if(ctx.step==="dice"){
                        var dice = random.D6(3); // diceRoll 3つのサイコロを振る
                        G.gamedata.dice.demandCost = dice[0];
                        G.gamedata.dice.demandQuality = dice[1];
                        G.gamedata.dice.demandGreen = dice[2];
                        ctx.onStage = false;
                        events.setActivePlayers({ all: 'initialStage',}); //同時進行の為ステージ移行
                    }else if(ctx.step==="trade"){
                        ctx.onStage = false;
                        const tradeP = calcTradePriority( G.players );
                        // 価格
                        var result = calcTradeAmountP( G.players, tradeP.cost, G.gamedata.demandCost);
                        const costAmount = result.sell;
                        const costUnsold = result.unsold;
                        // PQ
                        result = calcTradeAmountQ( G.players, tradeP.pq, G.gamedata.demandQuality+costUnsold);
                        const pqAmount = result.sell;
                        const pqUnsold = result.unsold;
                       
                        // PQG
                        result = calcTradeAmountG( G.players, tradeP.pqg, G.gamedata.demandGreen+pqUnsold);
                        const pqgAmount = result.sell;
                    
                        ctx.nowProfit = [costAmount, pqAmount, pqgAmount];
                        events.setActivePlayers({ all: 'trade',}); //同時進行の為ステージ移行
                    }
                },
                onEnd: ({ G, ctx, events }) => {
                    if(ctx.step==="dice"){

                        ctx.step="trade";
                        // G.gamedataに反映
                        G.gamedata.demandCost += diceCost(G)[G.gamedata.dice.demandCost-1];
                        G.gamedata.demandQuality += diceQuality(G)[G.gamedata.dice.demandQuality-1];
                        G.gamedata.demandGreen += diceGreen(G)[G.gamedata.dice.demandGreen-1];
                        //マイナスの場合0に補正
                        if(G.gamedata.demandCost < 0) G.gamedata.demandCost = 0;
                        if(G.gamedata.demandQuality < 0) G.gamedata.demandQuality = 0;
                        if(G.gamedata.demandGreen < 0) G.gamedata.demandGreen = 0;


                        ctx.onStage = false;
                    }else if(ctx.step==="trade"){
                        
                        for(const player of G.players){
                            // 売上計上
                            var sellnum=0;
                            for(const a of ctx.nowProfit){
                                sellnum += a[player.id];
                            }
                            player.money += sellnum * player.price;
                            // 在庫処分価格(SHOBUN_PRICE)で販売
                            var zaiko = player.stock;
                            player.money += zaiko * SHOBUN_PRICE;
                            player.stock = 0;

                            // 燃料費用計上
                            player.money -= player.financial[G.gamedata.turn-1]["fuelnum"] * G.gamedata.powPrice;

                            //負債の計算
                            const {interest, hensai} = calcInterest( player.financial, G.gamedata.turn,true );
                            //利子含めて今期分返済
                            player.money -= ( interest + hensai);

                            //財務諸表に売上個数、売り上げ単価を記録
                            player.financial[G.gamedata.turn-1]["sell"] = sellnum;
                            player.financial[G.gamedata.turn-1]["price"] = player.price;
                            player.financial[G.gamedata.turn-1]["nebiki_price"] = SHOBUN_PRICE;
                            player.financial[G.gamedata.turn-1]["nebiki_num"] = zaiko;
                            //減価償却を計算
                            calcDepreciation( player.financial, G.gamedata.turn );
                            
                            // BS更新
                            player.financial[G.gamedata.turn-1]["money"] = player.money;
                        }

                        ctx.step="end";
                    }
                },
            },
            onBegin: ({ G, ctx, events}) => {
                ctx.step="dice";
            },
            onEnd: ({ G, ctx }) => {
                console.log("カード開示");
            },
        },//販売フェーズ
        /**
         * イベントフェーズ
         */
        event:{
            next:'selectAction',
            endIf: ({ G,ctx }) => {
                if(ctx.onStage && ctx.activePlayers===null){
                    ctx.onStage = false;
                    return true;
                }else{
                    return false;
                }
            },
            turn:{
                order: TurnOrder.RESET,
                stages: {
                    eventStage: {
                        moves: {
                            done: doneOnly,
                        },
                    },
                },
                onBegin: ({G, ctx,events, random}) => {
                    console.log("イベントフェーズ開始");
                    var dice = random.D6(3); // diceRoll 3つのサイコロを振る
                    G.gamedata.dice.events1 = dice[0];
                    G.gamedata.dice.events2 = dice[1];
                    G.gamedata.dice.events3 = dice[2];
                    ctx.onStage = false;
                    events.setActivePlayers({ all: 'eventStage',}); //同時進行の為ステージ移行
                },
                onEnd: ({ G, ctx }) => {
                    console.log("イベントフェーズ終了");
                    diceEvent1Proc(G,diceEvent1[G.gamedata.dice.events1-1]);
                    diceEvent2Proc(G,diceEvent2[G.gamedata.dice.events2-1]);
                    diceEvent3Proc(G,diceEvent3[G.gamedata.dice.events3-1]);
                    ctx.onStage = false;
                }
            },
        },//イベントフェーズ
    },//phase

    /**
     * ゲーム終了
     * @param {*} param0 
     * @returns 
     */
    endIf: ({G, ctx}) => {
        // // 行動選択フェーズの時点で持ち金が0以下になったらゲーム終了
        // if(ctx.phase==="selectAction"){
        //     const p = G.players.find(p => p.money <= 0);
        //     if(p){
        //         console.log("破産プレイヤーによりゲーム終了");
        //         return { winner: p.id,reason:"破産" };
        //     }
        // }
        //GAME_TURNターン経過したらゲーム終了
        if(G.gamedata.turn > GAME_TURN || (G.gamedata.demo && G.gamedata.turn > DEMO_GAME_TURN)){
            // 純資産(player.financial[G.gamedata.turn-1]["netAssets"])の最も高いプレイヤーが勝利
            var max = 0;
            var winner = -1;
            for(const player of G.players){
                //破産したプレイヤーは除外
                if(player.hasan > 0) continue;
                const f = new Financial(player.financial[G.gamedata.turn-2]);

                if(max < f.netAssets){
                    max = f.netAssets;
                    winner = player.id;
                }
            }

            console.log("ターン経過によりゲーム終了");
            return { winner: winner, reason:"ターン終了"};
        }
    },

    //BOTの定義
    ai: {
        enumerate: (G, ctx) => {
            let moves = [];
            // const player = G.players[ctx.currentPlayer];//currentPlayerだと次のプレイヤーの情報になってしまう
            const player = G.players[1];//TODO:AIプレイヤーをとってくるのが大変なのでハードコーディング

            if(ctx.phase==="selectAction"){
                //1ターン目
                if(G.gamedata.turn <= 4){
                    // 1,2ターン目はGX機械＆借入
                    if(G.gamedata.turn <= 2){
                        moves.push({ move: 'done', args:[[3,14], {message:"",complete:false} ]});
                    }

                    // 3,4ターン目は大型生産か水素発電か大型リサイクル
                    if(G.gamedata.turn >= 3){
                        if(player.money >= 900){
                            moves.push({ move: 'done', args:[[4,14], {message:"",complete:false} ]});
                        }
                        if(player.money >= 700){
                            moves.push({ move: 'done', args:[[8,14], {message:"",complete:false} ]});
                        }else{
                            moves.push({ move: 'done', args:[[7,14], {message:"",complete:false} ]});
                        }
                        if(player.money >= 500){
                            moves.push({ move: 'done', args:[[6,14], {message:"",complete:false} ]});
                        }
                    }
                    //GX機械が購入できない場合
                    if(player.money < 600){
                        //通常機械かリサイクル装置と借入
                        moves.push({ move: 'done', args:[[1,14], {message:"",complete:false} ]});
                        moves.push({ move: 'done', args:[[7,14], {message:"",complete:false} ]});
                        moves.push({ move: 'done', args:[[6,14], {message:"",complete:false} ]});
                        moves.push({ move: 'done', args:[[5,14], {message:"",complete:false} ]});
                        if(player.money < 400){
                            //それも変えない時借入のみ
                            moves.push({ move: 'done', args:[[14], {message:"",complete:false} ]});
                        }
                    }
                }else{
                    if(player.money <= 300){//資金がない場合は借入のみ
                        moves.push({ move: 'done', args:[[14], {message:"",complete:false} ]});
                    }else{
                        //全パターン
                        const k = Object.keys(action_kind)
                            getCombinations(k).forEach((v)=>{
                            moves.push({ move: 'done', args:[v, {message:"",complete:false} ]});
                        })
                    }
                }

            }else if( ctx.phase==="production"){

                //AIが買える最大数
                const moneymax = Math.floor(player.money / G.gamedata.matPrice);
                const max = Math.min(player.product, moneymax);

                moves.push({ move: 'done', args:[max, {message:"",complete:false} ]});
                if(max-1 > 0){
                    moves.push({ move: 'done', args:[max-1, {message:"",complete:false} ]});
                }
                // const selection = [...Array(player.product)].map((_, i) => i);
                // if(selection.length > 0){
                //     for(const a of selection ){
                //         moves.push({ move: 'done', args:[a, {message:"",complete:false} ]});
                //     }
                // }else{
                //     moves.push({ move: 'done', args:[0, {message:"",complete:false} ]});
                // }
            }else if( ctx.phase==="selectPrice"){
                // moves.push({ move: 'done', args:[ "0", {message:"",complete:false} ]});
                for(const a of  ["-40","-20","0","20","40"]){
                    moves.push({ move: 'done', args:[a, {message:"",complete:false} ]});
                }
            }else if( ctx.phase==="trade"){
                moves.push({ move: 'done', args:[ {message:"",complete:false} ]});
            }else if( ctx.phase==="event"){
                moves.push({ move: 'done', args:[ {message:"",complete:false} ]});
            }
        //     if(player.step=="event") moves.push({ move: 'doEvent' });
        //     if(player.step=="confirm") moves.push({ move: 'confirm' });

            return moves;
        },
    },


};

export {GX};